import React, { useEffect, useState } from "react";
import NavigationBar from "../../../../../utils/navigation_bar/navigationBar";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import placeholderImg from "../../../../../../assets/media/placeholder.png";
import locationIcon from "../../../../../../assets/media/locationIcon.svg";
import mailIcon from "../../../../../../assets/media/mail.svg";
import { imagesUrl } from "../../../../../../config";
import CustomBackgroundButton from "../../../../../button/customBackgroundButton";
import instagramIcon from "../../../../../../assets/media/instagram.svg";
import facebookIcon from "../../../../../../assets/media/facebook.svg";
import youtubeIcon from "../../../../../../assets/media/youtube.svg";
import twitterIcon from "../../../../../../assets/media/x-twitter.svg";
import tiktokIcon from "../../../../../../assets/media/tiktok.svg";
import CustomTabButton from "../../../../../button/customTabButton";
import SotierAnalytics from "./analytics/sotierAnalytics";
import DynamicPopupWithChildren from "../../../../../popup/dynamicPopupWithChildren";
import SingleTextInput from "../../../../../utils/singleTextInput";
import SingleTextTextarea from "../../../../../utils/singleTextTextarea";
import SingleNumberInput from "../../../../../utils/singleNumberInput";
import { toast } from "react-toastify";

const SotierProfile = () => {
  const { t } = useTranslation("sotierList");
  const location = useLocation();
  const [sotier, setSotier] = useState(null);
  const [activeTab, setActiveTab] = useState("Analytics"); // State for active tab
  const [giftCoinPopup, setGiftCoinPopup] = useState(false);
  const [giftCoinTitle, setGiftCoinTitle] = useState();
  const [giftCoinDescription, setGiftCoinDescription] = useState();
  const [giftCoinValue, setGiftCoinValue] = useState();
  useEffect(() => {
    if (location.state && location.state.sotier) {
      setSotier(location.state.sotier);
      console.log(location.state.sotier); // To show state in the console
    }
  }, [location.state]);

  // Destructure sotier to use individual properties
  const {
    user_name: userName,
    first_name: firstName,
    last_name: lastName,
    sotier_id: sotierId,
    logo: logo,
  } = sotier || {};

  const locationArray = [
    [t("sotierList"), "/brand/srm/sotier/list"],
    [userName ? userName : t("profile"), "/brand/srm/sotier/profile"],
  ];

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    console.log(`Selected Tab: ${tab}`);
  };

  const tabData = [
    { key: "Analytics", label: "Analitik" },
    { key: "Challenges", label: "Challenges" },
    { key: "Campaigns", label: "Campaigns" },
    { key: "Followers", label: "Takipciler (2k)" },
    { key: "Complaints", label: "Sikayetler" },
    { key: "Activities", label: "Aktiviteler" },
  ];

  const cardsData = [
    {
      topTitle: "Total",
      title: "Shopping Amount",
      valueColor: "#2F3397",
      short: "TSA",
      value: "13.000 ₺",
      tableHeaders: ["Order ID", "Soty Coin", "Date"],
      tableData: [
        ["1", "300", "25.09.2023"],
        ["2", "350", "26.09.2023"],
      ],
    },
    {
      topTitle: "Total",
      title: "Earned Soty Coin",
      valueColor: "#319151",
      short: "TESC",
      value: "5000",
      tableHeaders: ["Order ID", "Soty Coin", "Date"],
      tableData: [
        ["3", "550", "27.09.2023"],
        ["4", "450", "28.09.2023"],
      ],
    },
    // Add additional card objects with their own `tableHeaders` and `tableData`
  ];

  const handleGiftCoin = () => {
    if (giftCoinTitle === undefined || giftCoinTitle === "") {
      toast.error(t("giftCoinTitleCannotBeEmpty"));
      return;
    } else if (
      giftCoinDescription === undefined ||
      giftCoinDescription === ""
    ) {
      toast.error(t("giftCoinDescriptionCannotBeEmpty"));
      return;
    } else if (giftCoinValue === undefined || giftCoinValue === "") {
      toast.error(t("giftCoinValueCannotBeEmpty"));
      return;
    } else if (giftCoinValue <= 0) {
      toast.error(t("giftCoinValueMustBeHigherThanZero"));
      return;
    }

    alert("COIN GIFTED TO " + userName);

    resetGiftCoinPopup();
  };

  const resetGiftCoinPopup = () => {
    setGiftCoinTitle();
    setGiftCoinDescription();
    setGiftCoinValue();
    setGiftCoinPopup(false);
  };

  return (
    <div className="dashboard-container profile-dashboard-background">
      <NavigationBar locationArray={locationArray} />
      {giftCoinPopup && (
        <DynamicPopupWithChildren
          onCancel={() => resetGiftCoinPopup()}
          title={t("giftSotyCoin")}
          isCondition
          buttons
          acceptText={t("sendSotyCoin")}
          onAccept={() => handleGiftCoin()}
          cancelText={t("cancel")}
          maxWidth="100vw"
          Width="50vw"
        >
          <div className="flex flex-column gap-50">
            <SingleTextInput
              title={t("giftCoinTitle")}
              isMandatory={true}
              titleDescription={t("giftCoinTitleDescription")}
              inputPlaceholder={t("giftCoinTitlePlaceholder")}
              value={giftCoinTitle}
              onChange={(e) => setGiftCoinTitle(e.target.value)}
              onBlur={(e) => setGiftCoinTitle(e.target.value)}
            />
            <SingleTextTextarea
              title={t("giftCoinDesc")}
              isMandatory={true}
              titleDescription={t("giftCoinDescDescription")}
              inputPlaceholder={t("giftCoinDescPlaceholder")}
              value={giftCoinDescription}
              onChange={(e) => setGiftCoinDescription(e.target.value)}
              onBlur={(e) => setGiftCoinDescription(e.target.value)}
            />
            <SingleNumberInput
              title={t("giftCoinValue")}
              isMandatory={true}
              inputPlaceholder={300}
              titleDescription={t("giftCoinValueDesc")}
              value={giftCoinValue}
              onChange={(e) => setGiftCoinValue(e.target.value)}
              onBlur={(e) => setGiftCoinValue(e.target.value)}
            />
          </div>
        </DynamicPopupWithChildren>
      )}

      <div className="profile-dashboard">
        <div className="sotier-profile-row-container profile-card">
          <div className="sotier-profile-row-top">
            <div className="sotier-profile-row-left">
              <div className="sotier-profile-credentials-container">
                <img
                  className="sotier-profile-picture"
                  src={logo ? imagesUrl + "/" + logo : placeholderImg}
                  alt="Profile"
                />
                <div className="sotier-profile-credentials">
                  <div className="sotier-profile-name-container">
                    <div>{userName}</div>
                    <div>{firstName}</div>
                    <div>{lastName}</div>
                  </div>
                  <div className="sotier-profile-contact-container">
                    <div>Sotier ID: {sotierId}</div>
                    <div>
                      <img src={locationIcon} alt="Location" />
                      Ankara
                    </div>
                    <div>
                      <img src={mailIcon} alt="Mail" />
                      loremIpsum@gmail.com
                    </div>
                  </div>
                  <div className="sotier-profile-credential-container">
                    <div className="sotier-profile-credential-title">
                      Dogum Tarihi:
                    </div>
                    <div className="sotier-profile-credential">27.07.1989</div>
                  </div>
                  <div className="sotier-profile-credential-container">
                    <div className="sotier-profile-credential-title">
                      Uyelik Tarihi:
                    </div>
                    <div className="sotier-profile-credential">27.07.2024</div>
                  </div>
                  <div className="sotier-profile-credential-container">
                    <CustomBackgroundButton
                      title={"Hediye Soty Coin"}
                      onClick={() => setGiftCoinPopup(true)}
                      backgroundColor={"#45C7D1"}
                    />
                    <CustomBackgroundButton
                      title={"Abonelikten Çıkar"}
                      onClick={() => console.log("hello")}
                      backgroundColor={"#DC6767"}
                    />
                    <CustomBackgroundButton
                      title={"Challenge Ban"}
                      onClick={() => console.log("hello")}
                      backgroundColor={"#DC6D97"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="vertical-line sotier-profile-vertical-line-padding" />
            <div className="sotier-profile-row-right">
              <div className="sotier-profile-row-right-row">
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell">
                  <img src={instagramIcon} />
                </div>
                <div className="sotier-profile-row-right-row-cell">
                  <img src={youtubeIcon} />
                </div>
                <div className="sotier-profile-row-right-row-cell">
                  <img src={facebookIcon} />
                </div>
                <div className="sotier-profile-row-right-row-cell">
                  <img src={twitterIcon} />
                </div>
                <div className="sotier-profile-row-right-row-cell">
                  <img src={tiktokIcon} />
                </div>
              </div>
              <div className="sotier-profile-row-right-row">
                <div className="sotier-profile-row-right-row-cell sotier-profile-row-right-row-cell-title">
                  Etkilesim Orani:
                </div>
                <div className="sotier-profile-row-right-row-cell">%5</div>
                <div className="sotier-profile-row-right-row-cell">%2</div>
                <div className="sotier-profile-row-right-row-cell">%1</div>
                <div className="sotier-profile-row-right-row-cell">%3</div>
                <div className="sotier-profile-row-right-row-cell">%10</div>
              </div>
              <div className="sotier-profile-row-right-row">
                <div className="sotier-profile-row-right-row-cell sotier-profile-row-right-row-cell-title">
                  Sotier Seviyeleri:
                </div>
                <div className="sotier-profile-row-right-row-cell">3</div>
                <div className="sotier-profile-row-right-row-cell">4</div>
                <div className="sotier-profile-row-right-row-cell">1</div>
                <div className="sotier-profile-row-right-row-cell">5</div>
                <div className="sotier-profile-row-right-row-cell">3</div>
              </div>
              <div className="sotier-profile-row-right-row">
                <div className="sotier-profile-row-right-row-cell sotier-profile-row-right-row-cell-title">
                  Segmentler:
                </div>
                <div className="sotier-profile-row-right-row-cell">
                  <div className="sotier-profile-row-right-segment-wrapper">
                    segment a
                  </div>
                </div>
                <div className="sotier-profile-row-right-row-cell">
                  <div className="sotier-profile-row-right-segment-wrapper">
                    segment b
                  </div>
                </div>
                <div className="sotier-profile-row-right-row-cell">
                  <div className="sotier-profile-row-right-segment-wrapper">
                    segment c
                  </div>
                </div>
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell" />
              </div>
              <div className="sotier-profile-row-right-row">
                <div className="sotier-profile-row-right-row-cell sotier-profile-row-right-row-cell-title">
                  Abone Marka:
                </div>
                <div className="sotier-profile-row-right-row-cell">
                  <div className="sotier-profile-row-right-subscribe-wrapper">
                    100
                  </div>
                </div>
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell" />
                <div className="sotier-profile-row-right-row-cell" />
              </div>
            </div>
          </div>
          <div className="sotier-profile-row-bottom">
            {tabData.map(({ key, label }) => (
              <CustomTabButton
                key={key}
                borderBottomColor={
                  activeTab === key ? "#04C8C8" : "transparent"
                }
                onClick={() => handleTabChange(key)}
                title={label}
              />
            ))}
          </div>
        </div>

        <SotierAnalytics cardsData={cardsData} />
      </div>
    </div>
  );
};

export default SotierProfile;
