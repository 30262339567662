// src/routesConfig.js

import BrandApplicationList from "./components/pages/realm_admin/brand_applications/app_list/BrandApplicationList";
import BrandApplicationDetail from "./components/pages/realm_admin/brand_applications/app_detail/BrandApplicationDetail";
import BrandAppRequest from "./components/pages/realm_admin/brand_applications/app_request/BrandAppRequest";
import BrandAdminProfile from "./components/pages/brand_admin/BrandAdminProfile";
import BrandAdminRoleList from "./components/pages/realm_admin/brand_admin_roles/brand_admin_role_list/BrandAdminRoleList";
import ErrorPage from "./components/pages/ErrorPage";
import RealmLogin from "./components/pages/auth/realmLogin";
import BrandLogin from "./components/pages/auth/brandLogin";
import SignUp from "./components/pages/auth/register.js";
import ChallengeCreation from "./components/pages/brand_admin/challenges/challenge_creation/ChallengeCreation.js";
import InteractionCreation2 from "./components/pages/brand_admin/challenges/interaction_creation/InteractionCreation.js";
import ContentCreation from "./components/pages/brand_admin/challenges/content_creation/ContentCreation.js";
import ChallengeList from "./components/pages/brand_admin/challenges/challenge_list/ChallengeList.js";
import ChallengePreview from "./components/pages/brand_admin/challenges/challenge_preview/ChallengePreview.js";
import BrandAnalysis from "./components/pages/brand_admin/srm/analysis/Analysis.js";
import Analysis from "./components/pages/realm_admin/srm/analysis/Analysis.js";
import Approved from "./components/pages/brand_admin/popup_page/PopupPage.js";
import AdminChallengeList from "./components/pages/realm_admin/challenges/ChallengeList.js";
import AdminChallengeDetail from "./components/pages/realm_admin/challenges/challenge_detail/challengeDetail.js";
import ChallengeDetail from "./components/pages/brand_admin/challenges/challenge_detail/challengeDetail.js";
import SurveyCreation from "./components/pages/brand_admin/challenges/survey_creation/SurveyCreation.js";
import ContentList from "./components/pages/brand_admin/srm/contents/content_list/ContentList.js";
import ContentLibrary from "./components/pages/brand_admin/srm/contents/content_library/ContentLibrary.js";
import ChallengeDashboard from "./components/pages/brand_admin/challenges/challenge_dashboard/ChallengeDashboard.js";
import CampaignDashboard from "./components/pages/brand_admin/srm/campaigns/campaign_dashboard/CampaignDashboard.js";
import AdminChallengeDashboard from "./components/pages/realm_admin/challenges/challenge_dashboard/ChallengeDashboard.js";
import CampaignCreation from "./components/pages/brand_admin/srm/campaigns/campaign_creation/CampaignCreation.js";
import CampaignPreview from "./components/pages/brand_admin/srm/campaigns/campaign_preview/CampaignPreview.js";
import CampaignCreationSpecialDays from "./components/pages/brand_admin/srm/campaigns_for_special_days/campaign_creation/campaignCreationSpecialDays.js";
import CampaignList from "./components/pages/realm_admin/srm/campaigns/campaign_list/campaignList.js";
import CampaignListBrand from "./components/pages/brand_admin/srm/campaigns/campaign_list/campaignList.js";
import CampaignCreationCorporations from "./components/pages/brand_admin/srm/campaigns_for_corporations/campaign_creation/campaignCreationCorporations.js";
import SegmentCreation from "./components/pages/brand_admin/srm/segment/creation/segmentCreation.js";
import CorporationCampaignDashboard from "./components/pages/brand_admin/srm/campaigns_for_corporations/campaign_dashboard/corporationCampaignDashboard.js";
import CorporationCampaignDetail from "./components/pages/brand_admin/srm/campaigns_for_corporations/campaign_detail/corporationCampaignDetail.js";
import SotierList from "./components/pages/brand_admin/srm/sotier/list/sotierList.js";
import SegmentDashboard from "./components/pages/brand_admin/srm/segment/dashboard/segmentDashboard.js";
import SegmentUserList from "./components/pages/brand_admin/srm/segment/user_list/segmentUserList.js";
import ContentBasket from "./components/pages/brand_admin/srm/contents/content_basket/contentBasket.js";
import Reports from "./components/pages/realm_admin/reports/reports.js";
import ReportsDetail from "./components/pages/realm_admin/reports/details/reportsDetail.js";
import PostReports from "./components/pages/brand_admin/reports/reports.js";
import PostReportsDetail from "./components/pages/brand_admin/reports/details/reportsDetail.js";
import BrandFaq from "./components/pages/brand_admin/faq/faq.js";
import PushNotificationCreation from "./components/pages/brand_admin/inbox/notification/pushNotificationCreation.js";
import PushNotificationList from "./components/pages/brand_admin/inbox/notification/pushNotificationList.js";
import BrandUserList from "./components/pages/brand_admin/settings/users/userList.js";
import SotierProfile from "./components/pages/brand_admin/srm/sotier/profile/sotierProfile.js";
import FaqList from "./components/pages/realm_admin/faq/FaqList.js";
import UserList from "./components/pages/realm_admin/settings/UserList.js";
import BrandRoleList from "./components/pages/brand_admin/settings/roles/brand_role_list/BrandRoleList.js";
import Logs from "./components/pages/brand_admin/settings/logs/Logs.js";
import BrandList from "./components/pages/realm_admin/brand_list/BrandList.js";
import Packages from "./components/pages/brand_admin/settings/packages/Packages.js";
import MainPage from "./components/pages/brand_admin/MainPage.js";
// Define routes added back main added
export const RoutesConfig = [
  { path: "*", element: <BrandLogin /> },
  { path: "/*", element: <BrandLogin /> },
  { path: "/analysis", element: <Analysis /> },
  { path: "/approved", element: <Approved /> },
  { path: "/brands", element: <BrandList /> },
  { path: "/challenges", element: <AdminChallengeDashboard /> },
  { path: "/challenges/detail", element: <AdminChallengeDetail /> },
  { path: "/challenges/list", element: <AdminChallengeList /> },
  { path: "/faq", element: <FaqList /> },
  { path: "/register", element: <SignUp /> },
  { path: "/reports", element: <Reports /> },
  { path: "/reports/detail", element: <ReportsDetail /> },
  { path: "/settings/roles", element: <BrandAdminRoleList /> },
  { path: "/settings/users", element: <UserList /> },
  { path: "/sign-in-brand", element: <BrandLogin /> },
  { path: "/sign-in-realm", element: <RealmLogin /> },
  { path: "/srm/campaign/list", element: <CampaignList /> },

  { path: "/brand-application-detail", element: <BrandApplicationDetail /> },
  { path: "/brand-application-list", element: <BrandApplicationList /> },
  { path: "/brand-app-request/:ID", element: <BrandAppRequest /> },
  { path: "/brand/analysis", element: <BrandAnalysis /> },
  { path: "/brand/challenges", element: <ChallengeDashboard /> },
  { path: "/brand/challenges/challenge-creation", element: <ChallengeCreation /> },
  { path: "/brand/challenges/challenge-detail", element: <ChallengeDetail /> },
  { path: "/brand/challenges/content-creation", element: <ContentCreation /> },
  { path: "/brand/challenges/interaction-creation", element: <InteractionCreation2 /> },
  { path: "/brand/challenges/list", element: <ChallengeList /> },
  { path: "/brand/challenges/preview", element: <ChallengePreview /> },
  { path: "/brand/challenges/survey-creation", element: <SurveyCreation /> },
  { path: "/brand/dashboard", element: <MainPage /> },
  { path: "/brand/faq", element: <BrandFaq /> },
  { path: "/brand/inbox/push", element: <PushNotificationList /> },
  { path: "/brand/inbox/push/create", element: <PushNotificationCreation /> },
  { path: "/brand/reports", element: <PostReports /> },
  { path: "/brand/reports/detail", element: <PostReportsDetail /> },
  { path: "/brand/settings/logs", element: <Logs /> },
  { path: "/brand/settings/packages", element: <Packages /> },
  { path: "/brand/settings/profile", element: <BrandAdminProfile /> },
  { path: "/brand/settings/roles", element: <BrandRoleList /> },
  { path: "/brand/settings/users", element: <BrandUserList /> },
  { path: "/brand/srm/campaign", element: <CampaignDashboard /> },
  { path: "/brand/srm/campaign/create", element: <CampaignCreation /> },
  { path: "/brand/srm/campaign/list", element: <CampaignListBrand /> },
  { path: "/brand/srm/campaign/preview", element: <CampaignPreview /> },
  { path: "/brand/srm/campaign/special-campaign/create", element: <CampaignCreationSpecialDays /> },
  { path: "/brand/srm/content", element: <ContentList /> },
  { path: "/brand/srm/content/basket", element: <ContentBasket /> },
  { path: "/brand/srm/content/library", element: <ContentLibrary /> },
  { path: "/brand/srm/corporate-campaign", element: <CorporationCampaignDashboard /> },
  { path: "/brand/srm/corporate-campaign/create", element: <CampaignCreationCorporations /> },
  { path: "/brand/srm/corporate-campaign/detail", element: <CorporationCampaignDetail /> },
  { path: "/brand/srm/segment", element: <SegmentDashboard /> },
  { path: "/brand/srm/segment/create", element: <SegmentCreation /> },
  { path: "/brand/srm/segment/list", element: <SegmentUserList /> },
  { path: "/brand/srm/sotier/list", element: <SotierList /> },
  { path: "/brand/srm/sotier/profile", element: <SotierProfile /> },

];

