import React, { useState, useEffect } from "react";
import arrow from "../../../../../assets/media/arrow.png";
import downArrow from "../../../../../assets/media/down-arrow.png";
import infoIconEmpty from "../../../../../assets/media/infoIconEmpty.svg";
import ChallengeImageDropzone from "../../../../dropzone/ChallengeImageDropzone";
import "../../../../../assets/css/InteractionCreation.css";
import { getCookie } from "../../../auth/tokenUtils";
import { useNavigate, useLocation } from "react-router-dom";
import { isMyTokenExpired } from "../../../auth/tokenUtils";
import instagramSVG from "../../../../../assets/media/instagram.svg";
import youtubeSVG from "../../../../../assets/media/youtube.svg";
import facebookSVG from "../../../../../assets/media/facebook.svg";
import twitterSVG from "../../../../../assets/media/x-twitter.svg";
import tiktokSVG from "../../../../../assets/media/tiktok.svg";
import tick from "../../../../../assets/media/tickInteraction.svg";
import editSVG from "../../../../../assets/media/Icon feather-edit.svg";
import deleteSVG from "../../../../../assets/media/Icon material-delete.svg";
import saveSVG from "../../../../../assets/media/Icon feather-save.svg";
import returnSVG from "../../../../../assets/media/Icon feather-return.svg";
import noImageToShow from "../../../../../assets/media/placeholder.png";
import PhoneViewJoin from "../../../../phone/phoneViewJoin";
import axios from "axios";
import { toast } from "react-toastify";
import { apiUrl, imagesUrl } from "../../../../../config";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Loader from "../../../../popup/loader";
import QrOption from "../../../../utils/qrOption";
import QrOptionReadOnly from "../../../../utils/qrOptionReadOnly";
import SingleNumberInput from "../../../../utils/singleNumberInput";
import { Tooltip } from "react-tippy";
import Pagination from "../../../../tables/Pagination";
import CallAPI from "../../../../api/callApi";
import ItemsPerPageButton from "../../../../button/itemsPerPageButton";
import ApprovalPopup from "../../../../popup/approvalPopup";
const InteractionCreation = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation("challengeCreation");
  const [loaderState, setLoaderState] = useState(false);

  const { returnedChallenge } = location.state || {};
  const navigate = useNavigate();
  const [
    currentQuestionImageResponseString,
    setCurrentQuestionImageResponseString,
  ] = useState(null);

  const [modifiedActions, setModifiedActions] = useState([]);

  //validations
  const [isNameValid, setIsNameValid] = useState(true);
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);
  const [isImageValid, setIsImageValid] = useState(true);
  const [isStartDateValid, setIsStartDateValid] = useState(true);
  const [isEndDateValid, setIsEndDateValid] = useState(true);
  const [isCoinValid, setIsCoinValid] = useState(true);
  const [qrChecked, setQrChecked] = useState(false);

  //datas to send
  const [imageResponseString, setImageResponseString] = useState("");
  const [challengeName, setChallengeName] = useState("");
  const [challengeDescription, setChallengeDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [coin, setCoin] = useState();
  const [qrString, setQrString] = useState();

  const [checkedProvider, setCheckedProvider] = useState("");

  const [challengeImage, setChallengeImage] = useState(null);


   
  const [popupSegment, setPopupSegment] = useState();
  const [popup, setPopup] = useState();

  const [segments, setSegments] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(false);
  const [segmentPage, setSegmentPage] = useState(0);
  const [segmentTotalPage, setSegmentTotalPage] = useState(false);
  const [segmentPerPage, setSegmentPerPage] = useState(20);
  const [segmentTotalCount, setSegmentTotalCount] = useState(false);



  const challengeNameChange = (e) => {
    setChallengeName(e.target.value);
  };


  

  const handleSegmentPopup = (segment) => {
    setPopupSegment(segment);
    setPopup(true);
  };

  const handleFetchSegments = async () => {
    setLoaderState(true);
    const segmentResponse = await CallAPI({
      method: "GET", // HTTP isteği türü
      endPoint:
        apiUrl +
        `/api/v1/brand-admin/brand/segment?page=${segmentPage}&size=${segmentPerPage}`, // API URL'si
      body: undefined, // İstek gövdesi
      successMessage: undefined, // Başarılı mesaj (isteğe bağlı)
      errorMessage: t("errorMessage"), // Hata mesajı
      navigate: navigate, // Yönlendirme fonksiyonu
      t: t, // Çeviri işlevi
    });
    setLoaderState(false);
    console.log(segmentResponse);
    setSegmentTotalPage(segmentResponse.data.pageMetadata.totalPage);
    setSegmentPage(segmentResponse.data.pageMetadata.page);
    setSegmentTotalCount(segmentResponse.data.pageMetadata.count);
    console.log(segmentResponse.data.responseData);

    // allSotiers'ı en başa ekleyelim
    const updatedSegments = [
      {
        id: 0,
        name: t("allSotiers"),
        description: t("segmentForAllUsers"),
        expressionText: `=${t("allSotiers")}`,
      },
      ...segmentResponse.data.responseData,
    ];

    setSegments(updatedSegments);
  };



  const handleInputChange = (value, setter) => {
    setter(value);
  };

  const challengeDescriptionChange = (e) => {
    setChallengeDescription(e.target.value);
  };

  useEffect(() => {
    if (returnedChallenge) {
      const responseData = returnedChallenge.responseData || {};
      setImageResponseString(responseData.logo || "");
      setChallengeName(responseData.name || "");
      setChallengeDescription(responseData.description || "");
      setStartDate(
        moment.utc(responseData.startDate).format("YYYY-MM-DDTHH:mm:ss") || ""
      );
      setEndDate(
        moment.utc(responseData.endDate).format("YYYY-MM-DDTHH:mm:ss") || ""
      );
      setCoin(responseData.prize || null);
      setSelectedSegment(responseData.segment || "");
      setCheckedProvider(responseData.socialProvider);
      handleActionCheckboxChange();

      if (savedInputUrlArray.length === 0) {
        setSavedInputUrlArray([returnedChallenge.responseData.target]);
        setUserInputUrl(undefined);
        setInputUrl("");
      }

      if (responseData.qr) {
        setQrChecked(true);
        setQrString(responseData.qr);
      }
    }
  }, [returnedChallenge]);
  useEffect(() => {
    const token = getCookie("access_token");

    if (!token) {
      console.error(t("error.missingAccessToken"));
      toast.error(t("error.missingAccessToken"));
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error(t("error.sessionExpired"));
      navigate("/sign-in-brand");
    }
    handleFetchSegments();
  }, []);
  useEffect(() => {
    handleFetchSegments();
  }, [segmentPage, segmentPerPage]);
  useEffect(() => {
    if (!qrChecked) setQrString();
  }, [qrChecked]);

  useEffect(() => {
    const token = getCookie("access_token");
    if (!token) {
      console.error(t("error.missingAccessToken"));
      toast.error(t("error.missingAccessToken"));
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error(t("error.sessionExpired"));
      navigate("/sign-in-brand");
    }
  }, [getCookie("access_token")]);

  const startDateChange = (e) => {
    const startDate = e.target.value;
    if (startDate === "") {
      toast.error(t("error.missingStartDate"));
      return;
    }

    // Tarihten "Z" harfini kaldırın
    const formattedStartDate = new Date(startDate).toISOString().slice(0, 19);
    const selectedStartDate = new Date(startDate);
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    if (selectedStartDate < now) {
      toast.error(t("error.invalidStartDate"));
      e.target.value = "";
      handleInputChange("", setStartDate, "startDate");
      return;
    } else {
      setStartDate(formattedStartDate);
      handleInputChange(formattedStartDate, setStartDate, "startDate");
    }
  };

  const endDateChange = (e) => {
    const newEndDate = e.target.value;
    if (newEndDate === "") {
      toast.error(t("error.missingEndDate"));
      handleInputChange("", setEndDate, "endDate");
      return;
    }

    // Tarihten "Z" harfini kaldırın
    const formattedEndDate = new Date(newEndDate).toISOString().slice(0, 19);
    if (new Date(formattedEndDate) < new Date(startDate)) {
      toast.error(t("error.invalidEndDate"));
      e.target.value = "";
      handleInputChange("", setEndDate, "endDate");
    } else {
      setEndDate(formattedEndDate);
      handleInputChange(formattedEndDate, setEndDate, "endDate");
    }
  };

  const [
    isChallengeStartingTypeOnlineChecked,
    setIsChallengeStartingTypeOnlineChecked,
  ] = useState(false);
  const [
    isChallengeStartingTypeStoreChecked,
    setIsChallengeStartingTypeStoreChecked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineDeliveryDateChecked,
    setisChallengeStartingTypeOnlineDeliveryDateChecked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineShoppingDateChecked,
    setIsChallengeStartingTypeOnlineShoppingDateChecked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineDeliveryDateDelay0Checked,
    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineDeliveryDateDelay1Checked,
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineDeliveryDateDelay2Checked,
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineDeliveryDateDelay3Checked,
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked,
  ] = useState(false);

  const challengeStartingTypeOnlineChecked = () => {
    setIsChallengeStartingTypeOnlineChecked(true);
    setIsChallengeStartingTypeStoreChecked(false);

    showOnlineShoppingSection();
    hideStoreShoppingSection();
  };
  const challengeStartingTypeStoreChecked = () => {
    setIsChallengeStartingTypeOnlineChecked(false);
    setIsChallengeStartingTypeStoreChecked(true);

    resetOnlineDeliveryOptions();
    hideOnlineShoppingSection();
    showStoreShoppingSection();
  };

  const showOnlineShoppingSection = () => {
    const onlineShoppingSection = document.querySelector(
      ".challenge-starting-instructions-delivery-date-checkbox-container"
    );
    const onlineShoppingSection2 = document.querySelector(
      ".challenge-strating-instructions-delivery-date-delay-selection-container"
    );
    const onlineShoppingSection3 = document.querySelector(
      ".challenge-starting-instructions-shopping-date-checkbox-container"
    );
    const onlineShoppingSection4 = document.querySelector(
      ".challenge-starting-instructions-shopping-date-info-row"
    );
    const onlineShoppingSection5 = document.querySelector(
      ".challenge-starting-instructions-advice-row"
    );

    if (onlineShoppingSection) {
      onlineShoppingSection.style.display = "flex";
      onlineShoppingSection2.style.display = "block";
      onlineShoppingSection3.style.display = "flex";
      onlineShoppingSection4.style.display = "flex";
      onlineShoppingSection5.style.display = "flex";
    }
  };

  const hideStoreShoppingSection = () => {
    const storeShoppingSection = document.querySelector(
      ".challenge-starting-instructions-store-info-row"
    );
    if (storeShoppingSection) {
      storeShoppingSection.style.display = "none";
    }
  };
  const showStoreShoppingSection = () => {
    const storeShoppingSection = document.querySelector(
      ".challenge-starting-instructions-store-info-row"
    );
    if (storeShoppingSection) {
      storeShoppingSection.style.display = "flex";
    }
  };

  const resetOnlineDeliveryOptions = () => {
    setIsChallengeStartingTypeOnlineShoppingDateChecked(false);
    setisChallengeStartingTypeOnlineDeliveryDateChecked(false);

    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(false);
  };
  const challengeStartingTypeOnlineDeliveryDateChecked = () => {
    setisChallengeStartingTypeOnlineDeliveryDateChecked(true);
    setIsChallengeStartingTypeOnlineShoppingDateChecked(false);
  };
  const challengeStartingTypeOnlineShoppingDateChecked = () => {
    setIsChallengeStartingTypeOnlineShoppingDateChecked(true);
    setisChallengeStartingTypeOnlineDeliveryDateChecked(false);

    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(false);
  };
  const challengeStartingTypeOnlineDeliveryDateDelay0Checked = () => {
    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(true);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(false);
  };
  const challengeStartingTypeOnlineDeliveryDateDelay1Checked = () => {
    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(true);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(false);
  };
  const challengeStartingTypeOnlineDeliveryDateDelay2Checked = () => {
    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(true);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(false);
  };
  const challengeStartingTypeOnlineDeliveryDateDelay3Checked = () => {
    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(true);
  };
  const hideOnlineShoppingSection = () => {
    const onlineShoppingSection = document.querySelector(
      ".challenge-starting-instructions-delivery-date-checkbox-container"
    );
    const onlineShoppingSection2 = document.querySelector(
      ".challenge-strating-instructions-delivery-date-delay-selection-container"
    );
    const onlineShoppingSection3 = document.querySelector(
      ".challenge-starting-instructions-shopping-date-checkbox-container"
    );
    const onlineShoppingSection4 = document.querySelector(
      ".challenge-starting-instructions-shopping-date-info-row"
    );
    const onlineShoppingSection5 = document.querySelector(
      ".challenge-starting-instructions-advice-row"
    );

    if (onlineShoppingSection) {
      onlineShoppingSection.style.display = "none";
      onlineShoppingSection2.style.display = "none";
      onlineShoppingSection3.style.display = "none";
      onlineShoppingSection4.style.display = "none";
      onlineShoppingSection5.style.display = "none";
    }
  };

  const coinChange = (e) => {
    const value = e.target.value;
    const intValue = parseInt(value, 10); // Parse the input as an integer

    if (!isNaN(intValue) && intValue >= 1 && intValue <= 100000) {
      // Geçerli bir tamsayı ve 1 ile 100000 arasında mı kontrol edin
      setCoin(intValue); // Geçerli değeri kullanabilirsiniz
    } else {
      toast(t("error.invalidCoinValue"));
      e.target.value = "";
    }
  };

  const isValidURL = (url) => {
    // Basit bir URL format kontrolü
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    return urlRegex.test(url);
  };

  const handleInteractionUpload = async (previewBool) => {
    if (!checkedProvider) {
      toast.error(t("error.missingSocialMediaType"));
      return;
    }
    if (!savedInputUrlArray[0]) {
      toast.error(t("error.emptyURL"));
      return;
    }
    if (modifiedActions.every((action) => !action[1])) {
      toast.error(t("error.missingInteractionAction"));
      return;
    }
    const actionsWithMissingInstruction = modifiedActions.filter(
      (action) => action[1] === true && !action[2]
    );

    if (actionsWithMissingInstruction.length > 0) {
      const missingInstructions = actionsWithMissingInstruction
        .map((action) => action[0])
        .join(", ");

      toast.error(t("error.missingInteractionInstructions"));
      return;
    }

    const token = getCookie("access_token");
    if (!token) {
      console.error(t("error.missingAccessToken"));
      navigate("/sign-in-brand");
      toast.error(t("error.missingAccessToken"));
      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error(t("error.sessionExpired"));
      navigate("/sign-in-brand");
    }

    if (!imageResponseString) {
      setIsImageValid(false);
      toast.error(t("error.missingChallengeImage"));
      return;
    }
    setIsImageValid(true);
    if (!challengeName) {
      setIsNameValid(false);
      toast.error(t("error.missingChallengeName"));
      return;
    } else if (challengeName.length < 10) {
      setIsNameValid(false);
      toast.error(t("error.shortChallengeName"));
      return;
    }
    setIsNameValid(true);

    if (!challengeDescription) {
      setIsDescriptionValid(false);
      toast.error(t("error.missingChallengeDescription"));
      return;
    } else if (challengeDescription.length < 20) {
      setIsDescriptionValid(false);
      toast.error(t("error.shortChallengeDescription"));
      setIsDescriptionValid(true);
      return;
    }
    if (!startDate) {
      setIsStartDateValid(false);
      toast.error(t("error.missingStartDate"));
      return;
    }
    setIsStartDateValid(true);

    if (!endDate) {
      setIsEndDateValid(false);
      toast.error(t("error.missingEndDate"));
      return;
    }
    setIsEndDateValid(true);

    const startTime = new Date(startDate);
    const endTime = new Date(endDate);
    const timeDifference = endTime - startTime;

    if (timeDifference < 60000) {
      toast.error(t("error.invalidTimeRange"));
      return;
    }

    if (coin === undefined) {
      setIsCoinValid(false);
      toast.error(t("error.missingCoinValue"));
      return;
    }

    if (coin < 1 || coin > 100000) {
      setIsCoinValid(false);
      toast.error(t("error.invalidCoinValue"));
      return;
    }

    setIsCoinValid(true);

    // Continue with creating the challenge

    // Get the access token from the cookie (You should have a function to get cookies)
    const authToken = getCookie("access_token");
    if (!authToken) {
      console.error("Access token is missing.");
      navigate("/sign-in-brand");
      toast.error(t("error.missingAccessToken"));
      return;
    }
    const readyToSendCheckedProvider = checkedProvider.toUpperCase();
    // Define the API endpoint URL

    const challengeData = {
      logo: imageResponseString,
      description: challengeDescription,
      name: challengeName,
      socialChallengeType: "INTERACTION",
      socialProvider: readyToSendCheckedProvider,
      target: savedInputUrlArray[0], // Gerekirse bu satırı ekleyebilirsiniz
      startDate: startDate,
      endDate: endDate,
      prize: coin,
       ...(selectedSegment && { segment: selectedSegment }),
      interactionChallengeRules: modifiedActions
        .filter((action) => action[1] === true)
        .map((action, index) => ({
          sort: index,
          instruction: action[2],
          info: action[3],
          socialProviderCommandId: parseInt(action[4], 10),
        })),
    };
    if (qrString !== undefined) {
      challengeData.qr = qrString;
    }

    // Kullanıcının oturum açmış olduğunu varsayalım ve bu oturum açma bilgisini auth token olarak alalım
    console.log(challengeData);
    // Axios ile POST isteği gönderme
    setLoaderState(true);

    if (returnedChallenge) {
      const endPoint =
        apiUrl +
        "/api/v1/brand-admin/brand/challenge/social/interaction/" +
        returnedChallenge.responseData.id;
      axios
        .put(endPoint, challengeData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            toast.success(t("success.updateChallenge")); // Başarı mesajı göster
            console.log(response.data);
            setLoaderState(false);

            if (previewBool) {
              navigate("/brand/challenges/preview", {
                state: { returnedApiData: response.data },
              });
            } else {
              navigate("/brand/challenges");
            }

            // Başarılı sonuçları işlemek için burada kod ekleyebilirsiniz.
          }
        })
        .catch((error) => {
          setLoaderState(false);

          toast.error(
            t("error.updateChallenge") +
              "(" +
              error.response.data.metadata.message +
              ")"
          );
          console.log("ERROR: ", error);
          // Hataları işleyin (örneğin, kullanıcıya bir hata mesajı gösterin)
        });
    } else {
      const endPoint =
        apiUrl + "/api/v1/brand-admin/brand/challenge/social/interaction";
      axios
        .post(endPoint, challengeData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setLoaderState(false);

            toast.success(t("success.taskCreation")); // Başarı mesajı göster
            console.log(response.data);
            if (previewBool) {
              setLoaderState(false);

              navigate("/brand/challenges/preview", {
                state: { returnedApiData: response.data },
              });
            } else {
              setLoaderState(false);

              navigate("/brand/challenges");
            }

            // Başarılı sonuçları işlemek için burada kod ekleyebilirsiniz.
          }
        })
        .catch((error) => {
          setLoaderState(false);

          toast.error(
            t("error.ChallengeCreation") +
              "(" +
              error.response.data.metadata.message +
              ")"
          );
          console.log("ERROR: ", error);
          // Hataları işleyin (örneğin, kullanıcıya bir hata mesajı gösterin)
        });
    }
  };

  const handleImageUpload = async (file, isChallengeImage) => {
    setLoaderState(true);

    try {
      // Get the access token from the cookie
      const authToken = getCookie("access_token");

      // Create a FormData object to send the file
      const formData = new FormData();

      // Convert Blob URL to Blob data
      const response = await fetch(file);
      const blobData = await response.blob();

      // Append Blob data to FormData
      formData.append("file", blobData);

      // Determine the URL based on whether it's a challenge or a question image
      const uploadUrl = isChallengeImage
        ? apiUrl + "/api/v1/brand-admin/brand/challenge/logo"
        : apiUrl + "/api/v1/brand-admin/brand/challenge/logo"; // Replace with the actual URL

      // Determine the state and toast message based on whether it's a challenge or a question image
      const stateToUpdate = isChallengeImage
        ? setImageResponseString
        : setCurrentQuestionImageResponseString;
      const successMessage = isChallengeImage
        ? t("success.uploadChallengeImage")
        : t("success.uploadQuestionImage");

      // Use Axios to send the POST request to upload the file
      const uploadResponse = await axios.post(uploadUrl, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      stateToUpdate(uploadResponse.data.responseData);

      console.log("Dosya başarıyla yüklendi:", uploadResponse.data);

      // İşlem başarıyla tamamlandığında state'i güncelleyebilirsiniz
      setChallengeImage(file);
      setLoaderState(false);

      toast.success(successMessage, "aaaa");
    } catch (error) {
      setLoaderState(false);

      console.error(t("error.fileUpload"), error);
      setChallengeImage("");
      toast.error(t("error.fileUpload") + "(" + error.message + ")");
    }
  };

  const [commandsData, setCommandsData] = useState([]);
  useEffect(() => {
    fetchCommands();
  }, []);

  // Axios ile GET isteği gönderme
  const fetchCommands = () => {
    setLoaderState(true);

    const authToken = getCookie("access_token");
    axios
      .get(apiUrl + "/api/v1/brand-admin/brand/challenge/social/command", {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data.responseData;
          if (responseData && responseData.length > 0) {
            // Initialize an empty array to store actions
            const actionsArray = [];

            // Iterate over each item in responseData
            responseData.forEach((item) => {
              const socialProvider = item.socialProvider;
              const interactionChallengeActions =
                item.interactionChallengeActions;

              // Check if the required properties exist
              if (socialProvider && interactionChallengeActions) {
                // Iterate over the numeric properties of the object
                const providerActionsArray = Object.keys(
                  interactionChallengeActions
                ).map((key) => ({
                  counter: key,
                  action: interactionChallengeActions[key],
                }));

                // Push actions into the main array
                actionsArray.push(
                  ...providerActionsArray.map((providerAction) => [
                    providerAction.action.charAt(0).toUpperCase() +
                      providerAction.action.slice(1).toLowerCase(),
                    false,
                    "",
                    "",
                    providerAction.counter,
                    socialProvider, // Add the social provider to the array
                  ])
                );
              }
            });
            setModifiedActions(actionsArray);
            // Modify the actions and set them in the state

            if (returnedChallenge) {
              // modifiedActions dizisini güncellemek için yeni bir dizi oluştur
              let updatedModifiedActions = [...actionsArray];
              console.log("MODIFIED ACTIONS ", updatedModifiedActions);
              console.log(
                "RETURNED CHALLENGE RULES ",
                returnedChallenge?.responseData.interactionChallengeRules
              );

              returnedChallenge?.responseData.interactionChallengeRules.forEach(
                (rule) => {
                  console.log(rule.socialProviderCommand);
                  updatedModifiedActions.forEach((action, index) => {
                    if (rule.socialProviderCommandId) {
                      if (
                        action[4] === rule.socialProviderCommandId?.toString()
                      ) {
                        updatedModifiedActions[index] = [
                          action[0],
                          true,
                          rule.instruction,
                          rule.info,
                          action[4],
                          action[5],
                        ];
                      }
                    } else {
                      console.log(
                        action[0].toUpperCase(),
                        " / ",
                        rule.socialProviderCommand.interactionChallengeAction
                      );
                      if (
                        action[5] ===
                          rule.socialProviderCommand.socialProvider &&
                        action[0].toUpperCase() ===
                          rule.socialProviderCommand.interactionChallengeAction
                      ) {
                        updatedModifiedActions[index] = [
                          action[0],
                          true,
                          rule.instruction,
                          rule.info,
                          action[4],
                          action[5],
                        ];
                        console.log("IFIN ICINDEYIM");
                      }
                    }
                  });
                }
              );
              // setModifiedActions ile güncellenmiş diziyi state'e setle
              console.log(
                "UPDATED MODIFIED ACTIOOOOOOOONS",
                updatedModifiedActions
              );
              setModifiedActions(updatedModifiedActions);
            }

            setCommandsData(responseData);
            console.log("last modified actions = ", modifiedActions);
            setLoaderState(false);
          }
        }
      })
      .catch((error) => {
        setLoaderState(false);

        console.log(error);
        toast.error(t("error.fetchChallengeData"));
      });
  };

  const deleteTheSavedUrls = () => {
    setSavedInputUrlArray([]);
    setEditUrl("");
    setSavedUrl("");
  };

  const handleCheckboxChange = (socialProvider) => {
    deleteTheSavedUrls();
    setModifiedActions((prevActions) => {
      return prevActions.map((action) => [
        action[0],
        false,
        "",
        "",
        action[4],
        action[5],
      ]);
    });
    setCheckedProvider(socialProvider);
  };

  const getProviderIcon = (socialProvider) => {
    switch (socialProvider) {
      case "INSTAGRAM":
        return instagramSVG;
      case "FACEBOOK":
        return facebookSVG;
      case "YOUTUBE":
        return youtubeSVG;
      case "TIKTOK":
        return tiktokSVG;
      case "TWITTER":
        return twitterSVG;
      default:
        return;
    }
  };

  // Bu fonksiyon, bir öğenin (item) checked durumunu tersine çevirir ve bu değişikliği interactionChallengeActions dizisine uygular.
  const handleActionCheckboxChange = (item) => {
    const updatedActions = modifiedActions.map((action) => {
      if (action[4] === item[4]) {
        return [
          action[0],
          !item[1],
          action[2],
          action[3],
          action[4],
          action[5],
        ];
      }
      return action;
    });
    setModifiedActions(updatedActions);

    console.log("MODIFIED SON HALI", modifiedActions);
  };
  const checked = (command) => {};

  const [inputUrl, setInputUrl] = useState("");
  const [savedInputUrlArray, setSavedInputUrlArray] = useState([]);
  const [urlEditAreaClicked, setUrlEditAreaClicked] = useState(false);
  const [editUrl, setEditUrl] = useState("");
  const [savedUrl, setSavedUrl] = useState("");

  const handleInputUrlChange = (e) => {
    setInputUrl(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSaveUrl();
    }
  };
  const [userInputUrl, setUserInputUrl] = useState();
  const handleSaveUrl = () => {
    if (savedInputUrlArray.length >= 1) {
      toast.error(t("error.tooManyLinks"));
      setInputUrl("");
      return;
    }
    if (!isValidURL(inputUrl)) {
      toast.error(t("error.invalidLink"));
      return;
    }

    setSavedInputUrlArray((prevSavedInputUrlArray) => [
      ...prevSavedInputUrlArray,
      inputUrl,
    ]);
    setUserInputUrl(undefined);
    setInputUrl("");
  };

  const deleteTheSavedUrl = (urlToDelete) => {
    const updatedUrls = savedInputUrlArray.filter(
      (savedUrl) => savedUrl !== urlToDelete
    );
    setSavedInputUrlArray(updatedUrls);
  };

  const handleEditUrlChange = (e) => {
    setEditUrl(e.target.value);
  };

  const handleEditUrlSave = () => {
    if (editUrl === "") {
      setEditUrl(savedUrl);
      setUrlEditAreaClicked(false);
      return;
    }

    if (!isValidURL(editUrl)) {
      toast.error(t("error.invalidLink"));
      setEditUrl("");
      return;
    }

    setSavedInputUrlArray([editUrl]);
    setInputUrl("");
    setUrlEditAreaClicked(false);
  };

  const SocialInputChange = (e, item, counter) => {
    const InstructionText = e.target.value;

    // Item'in 2. öğesini güncel talimat metni (InstructionText) olarak ayarla
    item[counter] = InstructionText;

    // ModifiedActions'u güncellenmiş veriyle ayarla
    const updatedActions = modifiedActions.map((action) => {
      if (action[4] === item[4]) {
        if (counter === 2)
          return [
            action[0],
            action[1],
            InstructionText,
            action[3],
            action[4],
            action[5],
          ];
        else if (counter === 3)
          return [
            action[0],
            action[1],
            action[2],
            InstructionText,
            action[4],
            action[5],
          ];
      }
      return action;
    });
    setModifiedActions(updatedActions);
  };

  const ListProviders = (socialProvider) => {
    if (socialProvider) {
      const filteredActions = modifiedActions.filter(
        (item) => item[5] === socialProvider
      );

      return (
        <div className="interaction-creation-social-media-interaction-type-container">
          <div className="interaction-creation-social-media-interaction-type-row">
            <div className="interaction-creation-url-container">
              <div className="interaction-creation-input-container-header">
                <div className="challenge-creation-input-container-header-title">
                  {t("url")}:<span>*</span>
                </div>
                <div className="challenge-creation-input-container-header-subtitle">
                  {t("addUrlToContent")}
                </div>
              </div>
              <div className="interaction-creation-url-wrapper">
                <input
                  className="interaction-creation-link-input"
                  type="text"
                  placeholder="https://"
                  onKeyPress={handleKeyPress}
                  value={inputUrl}
                  onChange={(e) => {
                    handleInputUrlChange(e);
                  }}
                />
                <button
                  className="interaction-creation-link-input-button"
                  onClick={handleSaveUrl}
                >
                  <img src={saveSVG} alt="save" />
                </button>
                <label className="challenge-title-info-container">
                  <img
                    className="challenge-title-info-icon"
                    src={infoIconEmpty}
                    alt="icon"
                  />
                  {t("oneUrlOnly")}
                </label>
              </div>
              <div className="interaction-creation-url-demo-container">
                {savedInputUrlArray.map((savedUrl, index) => (
                  <div key={index}>
                    {!urlEditAreaClicked ? (
                      <div className="interaction-creation-url-demo-wrapper">
                        <input
                          type="text"
                          className="interaction-creation-url-demo"
                          value={savedUrl}
                          readOnly={true}
                        />
                        <div
                          className="interaction-creation-url-demo-edit"
                          onClick={() => setUrlEditAreaClicked(true)}
                        >
                          <img
                            className="interaction-creation-url-demo-edit-img"
                            src={editSVG}
                            alt="edit"
                          />
                        </div>
                        <div
                          className="interaction-creation-url-demo-delete"
                          onClick={() => deleteTheSavedUrl(savedUrl)}
                        >
                          <img
                            className="interaction-creation-url-demo-delete-img"
                            src={deleteSVG}
                            alt="delete"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="interaction-creation-url-demo-wrapper">
                        <div className="interaction-creation-url-demo">
                          <input
                            type="text"
                            defaultValue={savedUrl}
                            onChange={(e) => {
                              handleEditUrlChange(e);
                            }}
                          />
                        </div>
                        <div
                          className="interaction-creation-url-demo-edit"
                          onClick={() => handleEditUrlSave()}
                        >
                          <img
                            className="interaction-creation-url-demo-edit-img"
                            src={saveSVG}
                            alt="edit"
                          />
                        </div>
                        <div
                          className="interaction-creation-url-demo-delete"
                          onClick={() => setUrlEditAreaClicked(false)}
                        >
                          <img
                            className="interaction-creation-url-demo-delete-img"
                            src={returnSVG}
                            alt="delete"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <hr />
            <div className="interaction-type-container">
              <div className="interaction-type-title-container">
                <div className="challenge-creation-input-container-header-title">
                  {t("interactionType")}:<span>*</span>
                </div>
                <div className="challenge-creation-input-container-header-subtitle">
                  {t("selectInteractionFeature")}
                </div>
              </div>
              {filteredActions.map((item) => {
                return (
                  <>
                    <hr style={{ borderTop: "1px dashed #dbdbdb" }} />

                    <div
                      key={item}
                      className="interaction-creation-social-media-interaction-type-input-container"
                    >
                      <div className="interaction-creation-social-media-interaction-type-input-container-left">
                        <label className="interaction-creation-social-media-interaction-type-input-checkbox">
                          <input
                            type="checkbox"
                            checked={item[1]}
                            onChange={() => {
                              handleActionCheckboxChange(item);
                            }}
                          />
                          <span className="interaction-creation-social-media-interaction-type-input-checkmark">
                            <img src={tick} alt="tick" />
                          </span>
                        </label>
                      </div>

                      {returnedChallenge ? (
                        <div className="interaction-creation-social-media-interaction-type-input-container-right">
                          <div className="challenge-creation-input-container-header">
                            <div className="challenge-creation-input-container-header-title">
                              {item[0]}
                            </div>
                            <div className="challenge-creation-input-container-header-subtitle">
                              {item[0]} {t("theInteraction")}
                            </div>
                          </div>
                        </div>
                      ) : (
                        // If the conditions are not met, you might want to return something else or simply continue the loop
                        // return somethingElse;

                        <div className="interaction-creation-social-media-interaction-type-input-container-right">
                          <div className="challenge-creation-input-container-header">
                            <div className="challenge-creation-input-container-header-title">
                              {item[0]}
                            </div>
                            <div className="challenge-creation-input-container-header-subtitle">
                              {item[0]} {t("theInteraction")}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {item[1] && (
                      <div
                        className="interaction-creation-social-media-interaction-input-field-container"
                        key={item[0]}
                      >
                        <div className="interaction-creation-social-media-interaction-input-wrapper">
                          <div className="challenge-creation-input-container-header-title">
                            {t("instructions")}:<span>*</span>
                          </div>
                          <label className="challenge-creation-input-container-header-subtitle">
                            {t("please")} {item[0]}{" "}
                            {t("writeInstructionsForInteraction")}
                          </label>
                          <input
                            type="text"
                            className="interaction-creation-social-media-interaction-step-input"
                            placeholder={t("instructions")}
                            defaultValue={item[2]}
                            onChange={(e) => SocialInputChange(e, item, 2)}
                            maxLength={40}
                          />
                          <span className="challenge-title-info-container">
                            <img
                              className="challenge-title-info-icon"
                              src={infoIconEmpty}
                              alt="icon"
                            />
                            {t("max")} 40 {t("character")}
                          </span>
                        </div>
                        <hr style={{ borderTop: "1px dotted #dbdbdb" }} />
                        <div className="interaction-creation-social-media-interaction-input-wrapper">
                          <div className="challenge-creation-input-container-header-title">
                            {t("info")}:
                          </div>
                          <label className="challenge-creation-input-container-header-subtitle">
                            {t("please")} {item[0]}{" "}
                            {t("writeInstructionsForInteraction")}
                          </label>
                          <input
                            type="text"
                            className="interaction-creation-social-media-interaction-step-input"
                            placeholder={t("description")}
                            defaultValue={item[3]}
                            onChange={(e) => SocialInputChange(e, item, 3)}
                            maxLength={80}
                          />
                          <span className="challenge-title-info-container">
                            <img
                              className="challenge-title-info-icon"
                              src={infoIconEmpty}
                              alt="icon"
                            />
                            {t("max")} 80 {t("character")}
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="dashboard-container  dashboard-row">
      {loaderState && <Loader />}
      {popup && (
        <ApprovalPopup
          questionText={t("areYouSureToDeleteSegment")}
          cancelText={t("cancel")}
          approveText={t("delete")}
          onClose={() => {
            setPopup(false);
          }}
          isDeleteApprove={false}
          onCancelButtonClick={() => setPopup(false)}
          approvalObject={popupSegment}
        />
      )}
      <div className="challenge-creation-content-container">
        {/* KAMPANYA GORSELI */}
        <div className="challenge-creation-challenge-img-container">
          <div className="challenge-creation-challenge-segment-title">
            {t("challengeImage")}
          </div>

          <ChallengeImageDropzone
            onImageUpload={(file) => handleImageUpload(file, true)}
            defaultImageUrl={
              returnedChallenge
                ? imagesUrl + "/" + returnedChallenge.responseData.logo
                : ""
            }
            style={{ border: isImageValid ? "" : "2px solid red" }}
          />
          <label>{t("allowedFileTypes")} png, jpg</label>
        </div>
        {/* KAMPANYA ADI */}
        <div className="challenge-creation-input-container">
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
              {t("challengeName")}
              <span>*</span>:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("enterChallengeTitle")}
            </div>
          </div>
          <hr />
          <div className="challenge-creation-input-container-content-wrapper">
            <div className="challenge-title-container">
              <input
                className="challenge-title"
                placeholder={t("enterChallengeName")}
                defaultValue={challengeName}
                maxLength="60"
                onBlur={challengeNameChange}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value,
                    setChallengeName,
                    "challengeName"
                  )
                }
                style={{ border: isNameValid ? "" : "2px solid red" }}
              />
              <div className="challenge-title-info-container">
                <img
                  className="challenge-title-info-icon"
                  src={infoIconEmpty}
                  alt="info:"
                />
                <div className="challenge-title-info-text">
                  {t("min")} 10, {t("max")} 60 {t("character")}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* KAMPANYA ACIKLAMA */}
        <div className="challenge-creation-input-container">
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
              {t("challengeDescription")}
              <span>*</span>:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("enterChallengeDescription")}
            </div>
          </div>
          <hr />
          <div className="challenge-creation-input-container-content-wrapper">
            <div className="challenge-title-container">
              <textarea
                className="challenge-title"
                placeholder={t("enterChallengeDescriptionPlaceholder")}
                maxLength="511"
                defaultValue={challengeDescription}
                onBlur={challengeDescriptionChange}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value,
                    setChallengeDescription,
                    "challengeDescription"
                  )
                }
                style={{ border: isDescriptionValid ? "" : "2px solid red" }}
              />
              <div className="challenge-title-info-container">
                <img
                  className="challenge-title-info-icon"
                  src={infoIconEmpty}
                  alt="info:"
                />
                <div className="challenge-title-info-text">
                  {t("min")} 20, {t("max")} 512 {t("character")}
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* SEGMENT LISTESI */}
      <div className="challenge-creation-input-container">
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
               {t("SegmentList")}:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("SegmentListSubtitle")}
            </div>
          </div>
          <hr />
          <div className="challenge-creation-input-container-content-wrapper">
            <div className="segment-list-container">
              <div className="segment-list-inner">
                {segments &&
                  segments?.map((segment, index) => (
                    <div key={index} className="challenge-creation-segment-row">
                      <div
                        className={
                          selectedSegment === segment.id
                            ? "qr-checkbox checked"
                            : "qr-checkbox"
                        }
                        onClick={() => {
                          setSelectedSegment(segment.id);
                        }}
                      >
                        <img src={tick} alt="Tick" />
                      </div>

                      <div className="challenge-creation-segment-content-container">
                        <Tooltip
                          title={t("showDetails")}
                          position="bottom-start"
                          theme="light"
                          size="small"
                          delay="1000"
                        >
                          <div
                            className="challenge-creation-segment-title segment-list-segment-title outline"
                            onClick={() => {
                              handleSegmentPopup(segment);
                              console.log("SEGMENT = ", segment);
                            }}
                          >
                            {segment.name}
                          </div>
                        </Tooltip>
                        <div className="challenge-creation-segment-description">
                          {segment.description}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="segment-list-footer">
              <Pagination
                currentPage={segmentPage + 1 || null}
                totalPages={segmentTotalPage}
                onPageChange={(page) => {
                  setSegmentPage(page - 1);
                }}
              />
              <ItemsPerPageButton
                onSelectItem={(e) => {
                  handleInputChange(e, setSegmentPerPage);
                }}
                title={t("segmentPerPage")}
              />
            </div>
          </div>
        </div>
        {/* SOSYAL MEDYA KANALLARI */}
        <div className="challenge-creation-input-container">
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
              {t("selectSocialMediaChannel")}
              <span>*</span>:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("createSocialMediaChannel")}{" "}
            </div>
          </div>
          <hr />
          <div className="interaction-creation-input-container-content-wrapper">
            {commandsData.map((command) => (
              <div
                id={command.socialProvider}
                className="interaction-creation-social-media-row"
                key={command.socialProvider}
              >
                {(!returnedChallenge ||
                  returnedChallenge?.responseData?.socialProvider ===
                    command.socialProvider) && (
                  <div className="interaction-creation-social-media-header">
                    <div className="interaction-creation-social-media-header-left">
                      <div className="survey-type-checkbox-wrapper">
                        <input
                          type="radio"
                          style={{ display: "none" }}
                          id={`radio-provider-${command.socialProvider}`}
                          name="survey-type"
                          checked={
                            returnedChallenge?.responseData.socialProvider ===
                              command.socialProvider ||
                            checkedProvider === command.socialProvider
                          }
                          value={`${command.socialProvider}-checked`}
                          onChange={() => {
                            if (!returnedChallenge) {
                              handleCheckboxChange(command.socialProvider);
                            }
                          }}
                        />
                        <div
                          className={`survey-type-checkbox${
                            checkedProvider === command.socialProvider
                              ? " checked"
                              : ""
                          }`}
                          onClick={() => {
                            if (!returnedChallenge) {
                              handleCheckboxChange(command.socialProvider);
                            }
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="interaction-creation-social-media-header-right">
                      <img
                        className="interaction-creation-social-media-header-right-icon"
                        src={getProviderIcon(command.socialProvider)}
                        alt="Social icon"
                      />
                      <div className="interaction-creation-social-media-header-right-text-wrapper">
                        <div>{command.socialProvider}</div>
                        <div className="challenge-creation-input-container-header-subtitle"></div>
                      </div>
                    </div>
                  </div>
                )}

                {checkedProvider === command.socialProvider
                  ? ListProviders(command.socialProvider)
                  : null}
              </div>
            ))}
          </div>
        </div>
        {/* ANKET TARIHI */}
        <div className="challenge-creation-input-container">
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
              {t("challengeDate")}
              <span>*</span>:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("setCampaignDateTime")}
            </div>
          </div>
          <hr />
          <div className="challenge-creation-input-container-content-wrapper">
            <div className="challenge-creation-date-column-container">
              <div className="challenge-creation-date-column">
                <div className="challenge-creation-date-title">
                  {t("startDate")}
                  <span>*</span>
                </div>
                <input
                  type="datetime-local"
                  className="challenge-creation-date-picker"
                  defaultValue={
                    returnedChallenge
                      ? moment
                          .utc(returnedChallenge.responseData.startDate)
                          .local()
                          .format("YYYY-MM-DDTHH:mm:ss")
                      : startDate
                  }
                  onBlur={startDateChange}
                  style={{ border: isStartDateValid ? "" : "2px solid red" }}
                />
              </div>

              <div className="challenge-creation-date-column">
                <div className="challenge-creation-date-title">
                  {t("endDate")}
                  <span>*</span>
                </div>
                <input
                  type="datetime-local"
                  className="challenge-creation-date-picker"
                  defaultValue={
                    returnedChallenge
                      ? moment
                          .utc(returnedChallenge.responseData.endDate)
                          .local()
                          .format("YYYY-MM-DDTHH:mm:ss")
                      : endDate
                  }
                  onBlur={endDateChange}
                  style={{ border: isEndDateValid ? "" : "2px solid red" }}
                />
              </div>
            </div>

            <div className="challenge-creation-date-description-row">
              <img
                className="challenge-creation-date-description-icon"
                src={infoIconEmpty}
                alt="icon"
              />
              <div className="challenge-creation-date-description">
                {t("setChallengeStartDate")}
              </div>
            </div>
            <div className="challenge-creation-date-description-row">
              <img
                className="challenge-creation-date-description-icon"
                src={infoIconEmpty}
                alt="icon"
              />
              <div className="challenge-creation-date-description">
                {t("noEndDateInfo")}
              </div>
            </div>
          </div>
        </div>
        {/* KAMPANYA BASLAMA TALIMATI */}
        <div
          className="challenge-creation-input-container"
          style={{ display: "none" }}
        >
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
              {t("challengeStartInstructions")}
              <span>*</span>:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("challengeProcessInstructions")}{" "}
            </div>
          </div>
          <hr />
          <div className="challenge-creation-input-container-content-wrapper">
            <div className="challenge-starting-instructions-column-container">
              <div className="challenge-starting-instructions-column">
                <div className="challenge-starting-instructions-checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="resimli-anket"
                    name="survey-method"
                    checked={isChallengeStartingTypeOnlineChecked}
                    onChange={challengeStartingTypeOnlineChecked}
                  />
                  <div
                    className={`challenge-starting-instructions-checkbox${
                      isChallengeStartingTypeOnlineChecked ? " checked" : ""
                    }`}
                    onClick={challengeStartingTypeOnlineChecked}
                  ></div>
                  <div className="challenge-starting-instructions-checkbox-title-wrapper">
                    <label
                      htmlFor="resimli-anket"
                      className="challenge-starting-instructions-checkbox-title"
                    >
                      {t("online")}
                    </label>
                    <div className="challenge-starting-instructions-checkbox-subtitle"></div>
                  </div>
                </div>

                <div className="challenge-starting-instructions-delivery-date-checkbox-container">
                  <div className="challenge-starting-instructions-delivery-date-checkbox-wrapper">
                    <input
                      type="checkbox"
                      id="resimli-anket"
                      name="survey-method"
                      checked={isChallengeStartingTypeOnlineDeliveryDateChecked}
                      onChange={challengeStartingTypeOnlineDeliveryDateChecked}
                    />
                    <div
                      className={`challenge-starting-instructions-delivery-date-checkbox${
                        isChallengeStartingTypeOnlineDeliveryDateChecked
                          ? " checked"
                          : ""
                      }`}
                      onClick={challengeStartingTypeOnlineDeliveryDateChecked}
                    ></div>
                    <div className="challenge-starting-instructions-delivery-date-checkbox-title-wrapper">
                      <label
                        htmlFor="resimli-anket"
                        className="challenge-starting-instructions-delivery-date-checkbox-title"
                      >
                        {t("deliveryDay")}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="challenge-strating-instructions-delivery-date-delay-selection-container">
                  {isChallengeStartingTypeOnlineDeliveryDateChecked && (
                    <div className="challenge-starting-instructions-delivery-date-delay-selection-row">
                      <input
                        type="checkbox"
                        id="resimli-anket"
                        name="survey-method"
                        checked={
                          isChallengeStartingTypeOnlineDeliveryDateDelay0Checked
                        }
                        onChange={
                          challengeStartingTypeOnlineDeliveryDateDelay0Checked
                        }
                      />
                      <div
                        className={`challenge-starting-instructions-delivery-date-delay-selection-checkbox${
                          isChallengeStartingTypeOnlineDeliveryDateDelay0Checked
                            ? " checked"
                            : ""
                        }`}
                        onClick={
                          challengeStartingTypeOnlineDeliveryDateDelay0Checked
                        }
                      >
                        0 {t("day")}
                      </div>
                      <input
                        type="checkbox"
                        id="resimli-anket"
                        name="survey-method"
                        checked={
                          isChallengeStartingTypeOnlineDeliveryDateDelay1Checked
                        }
                        onChange={
                          challengeStartingTypeOnlineDeliveryDateDelay1Checked
                        }
                      />
                      <div
                        className={`challenge-starting-instructions-delivery-date-delay-selection-checkbox${
                          isChallengeStartingTypeOnlineDeliveryDateDelay1Checked
                            ? " checked"
                            : ""
                        }`}
                        onClick={
                          challengeStartingTypeOnlineDeliveryDateDelay1Checked
                        }
                      >
                        5 {t("day")}
                      </div>
                      <input
                        type="checkbox"
                        id="resimli-anket"
                        name="survey-method"
                        checked={
                          isChallengeStartingTypeOnlineDeliveryDateDelay2Checked
                        }
                        onChange={
                          challengeStartingTypeOnlineDeliveryDateDelay2Checked
                        }
                      />
                      <div
                        className={`challenge-starting-instructions-delivery-date-delay-selection-checkbox${
                          isChallengeStartingTypeOnlineDeliveryDateDelay2Checked
                            ? " checked"
                            : ""
                        }`}
                        onClick={
                          challengeStartingTypeOnlineDeliveryDateDelay2Checked
                        }
                      >
                        15 {t("day")}
                      </div>
                      <input
                        type="checkbox"
                        id="resimli-anket"
                        name="survey-method"
                        checked={
                          isChallengeStartingTypeOnlineDeliveryDateDelay3Checked
                        }
                        onChange={
                          challengeStartingTypeOnlineDeliveryDateDelay3Checked
                        }
                      />
                      <div
                        className={`challenge-starting-instructions-delivery-date-delay-selection-checkbox${
                          isChallengeStartingTypeOnlineDeliveryDateDelay3Checked
                            ? " checked"
                            : ""
                        }`}
                        onClick={
                          challengeStartingTypeOnlineDeliveryDateDelay3Checked
                        }
                      >
                        30 {t("day")}
                      </div>
                    </div>
                  )}
                  <div className="challenge-starting-instructions-info-row">
                    <img
                      className="challenge-starting-instructions-info-icon"
                      src={infoIconEmpty}
                      alt="icon"
                    />
                    <div className="challenge-starting-instructions-info">
                      {t("onlineShoppingProcess")}
                    </div>
                  </div>
                </div>
                <div className="challenge-starting-instructions-shopping-date-checkbox-container">
                  <div className="challenge-starting-instructions-delivery-date-checkbox-wrapper">
                    <input
                      type="checkbox"
                      id="resimli-anket"
                      name="survey-method"
                      checked={isChallengeStartingTypeOnlineShoppingDateChecked}
                      onChange={challengeStartingTypeOnlineShoppingDateChecked}
                    />
                    <div
                      className={`challenge-starting-instructions-delivery-date-checkbox${
                        isChallengeStartingTypeOnlineShoppingDateChecked
                          ? " checked"
                          : ""
                      }`}
                      onClick={challengeStartingTypeOnlineShoppingDateChecked}
                    ></div>
                    <div className="challenge-starting-instructions-delivery-date-checkbox-title-wrapper">
                      <label
                        htmlFor="resimli-anket"
                        className="challenge-starting-instructions-delivery-date-checkbox-title"
                      >
                        {t("purchaseDay")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="challenge-starting-instructions-shopping-date-info-row">
                  <img
                    className="challenge-starting-instructions-info-icon"
                    src={infoIconEmpty}
                    alt="icon"
                  />
                  <div className="challenge-starting-instructions-info">
                    {t("onlineShoppingStartDate")}
                  </div>
                </div>
                <div className="challenge-starting-instructions-advice-row">
                  <img
                    className="challenge-starting-instructions-info-icon"
                    src={infoIconEmpty}
                    alt="icon"
                  />
                  <div className="challenge-starting-instructions-info">
                    {t("deliveryDaySOTY")}
                  </div>
                </div>
              </div>
              <hr />
              <div className="challenge-starting-instructions-column">
                <div className="challenge-starting-instructions-checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="resimli-anket"
                    name="survey-method"
                    checked={isChallengeStartingTypeStoreChecked}
                    onChange={challengeStartingTypeStoreChecked}
                  />
                  <div
                    className={`challenge-starting-instructions-checkbox${
                      isChallengeStartingTypeStoreChecked ? " checked" : ""
                    }`}
                    onClick={challengeStartingTypeStoreChecked}
                  ></div>
                  <div className="challenge-starting-instructions-checkbox-title-wrapper">
                    <label
                      htmlFor="resimli-anket"
                      className="challenge-starting-instructions-checkbox-title"
                    >
                      {t("store")}
                    </label>
                    <div className="challenge-starting-instructions-checkbox-subtitle"></div>
                  </div>
                </div>
                <div className="challenge-starting-instructions-store-info-row">
                  <img
                    className="challenge-starting-instructions-info-icon"
                    src={infoIconEmpty}
                    alt="icon"
                  />
                  <div className="challenge-starting-instructions-info">
                    {t("inStoreShoppingStartDate")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {returnedChallenge && returnedChallenge.responseData.qr && (
          <QrOptionReadOnly qrCode={returnedChallenge.responseData.qr} />
        )}
        {!returnedChallenge && (
          <QrOption
            qrChecked={(isChecked) => {
              setQrChecked(isChecked);
            }}
            qrString={(theQrString) => {
              setQrString(theQrString);
            }}
          />
        )}
        {/* SOTY COIN */}
        <SingleNumberInput
          title={t("sotyCoin")}
          isMandatory={true}
          titleDescription={t("setSotyCoinAmount")}
          inputPlaceholder={t("enterSotyCoinAmount")}
          infoTextFooter={[
            (coin || 0) +
              " " +
              t("sotyCoin") +
              " " +
              t("equalsTo") +
              " " +
              coin / 10 +
              "TL",
            t("sotyCoinValue"),
          ]}
          value={coin}
          onChange={(e) => {
            handleInputChange(e.target.value, setCoin);
          }}
          onBlur={(e) => {
            coinChange(e);
          }}
        />

        {/* KAMPANYA ONAY DUGMELERI */}
        <div className="challenge-creation-submit-button-container">
          <button
            className="draft"
            onClick={() => {
              handleInteractionUpload(false);
            }}
          >
            {t("saveAsDraft")}
          </button>
          <button
            className="preview"
            onClick={() => {
              handleInteractionUpload(true);
            }}
          >
            {t("preview")}
          </button>
        </div>
      </div>

      <div
        className="challenge-creation-phone-container"
        style={{ position: "sticky", top: "0", right: "30px" }}
      >
        <PhoneViewJoin
          name={challengeName}
          description={challengeDescription}
          prize={coin || 0}
          endDate={endDate || ""}
          logo={challengeImage || noImageToShow}
          imagesUrl={imagesUrl}
          brandLogo={challengeImage || noImageToShow}
          challengeType={t("interaction")}
          socialProvider={checkedProvider}
        />
      </div>
    </div>
  );
};

export default InteractionCreation;
