import React, { useState, useEffect } from "react";
import arrow from "../../../../../assets/media/arrow.png";
import downArrow from "../../../../../assets/media/down-arrow.png";
import infoIconEmpty from "../../../../../assets/media/infoIconEmpty.svg";
import ChallengeImageDropzone from "../../../../dropzone/ChallengeImageDropzone";
import noImageToShow from "../../../../../assets/media/placeholder.png";
import "../../../../../assets/css/ChallengeCreation.css";
import CustomSelect from "../../../../collapsible/customSelect";
import TextTypeSurvey from "./survey_types/multiple_choice_survey/text_type_survey/TextTypeSurvey";
import { getCookie, getUserRolesFromToken } from "../../../auth/tokenUtils";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { isMyTokenExpired } from "../../../auth/tokenUtils";
import NumericMetricTypeSurvey from "./survey_types/evaluation_scale_questions/NumericMetricTypeSurvey";
import LikertTypeSurvey from "./survey_types/evaluation_scale_questions/LikertTypeSurvey";
import { apiUrl } from "../../../../../config";
import { imagesUrl } from "../../../../../config";
import tick from "../../../../../assets/media/tickInteraction.svg";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { useTranslation } from "react-i18next";
import PhoneViewJoin from "../../../../phone/phoneViewJoin";
import Loader from "../../../../popup/loader";
import QrOption from "../../../../utils/qrOption";
import QrOptionReadOnly from "../../../../utils/qrOptionReadOnly";
import SingleNumberInput from "../../../../utils/singleNumberInput";
import { Tooltip } from "react-tippy";
import Pagination from "../../../../tables/Pagination";
import CallAPI from "../../../../api/callApi";
import ItemsPerPageButton from "../../../../button/itemsPerPageButton";
import ApprovalPopup from "../../../../popup/approvalPopup";

const SurveyCreation = () => {
  const { t, i18n } = useTranslation("challengeCreation");
  const location = useLocation();
  const { returnedChallenge } = location.state || {};
  const navigate = useNavigate();
  const [returnedApiData, setReturnedApiData] = useState();
  const [challengeImage, setChallengeImage] = useState(null);
  const [currentQuestionImage, setCurrentQuestionImage] = useState(null);
  const [selectedSurveyType, setSelectedSurveyType] = useState("");
  const [isCurrentQuestionSaved, setIsCurrentQuestionSaved] = useState(false);
  const [clearImage, setClearImage] = useState(0);
  const [
    currentQuestionImageResponseString,
    setCurrentQuestionImageResponseString,
  ] = useState(null);
  const [isSaved, setIsSaved] = useState(false); // Kaydedildiğini belirten bir state
  const [isSurveyVisible, setSurveyVisible] = useState(false);
  const [likertTypeSurveySelected, setLikertTypeSurveySelected] =
    useState(false);
  const [isCurrentQuestionImageChange, setIsCurrentQuestionImageChange] =
    useState(false);

  //validations
  const [isNameValid, setIsNameValid] = useState(true);
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);
  const [isImageValid, setIsImageValid] = useState(true);
  const [isStartDateValid, setIsStartDateValid] = useState(true);
  const [isEndDateValid, setIsEndDateValid] = useState(true);
  const [isCoinValid, setIsCoinValid] = useState(true);
  const [defaultSelectText, setDefaultSelectText] = useState(t("select"));
  const [qrChecked, setQrChecked] = useState(false);



  const [popupSegment, setPopupSegment] = useState();
  const [popup, setPopup] = useState();

  const [segments, setSegments] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(false);
  const [segmentPage, setSegmentPage] = useState(0);
  const [segmentTotalPage, setSegmentTotalPage] = useState(false);
  const [segmentPerPage, setSegmentPerPage] = useState(20);
  const [segmentTotalCount, setSegmentTotalCount] = useState(false);



  //datas to send
  const [imageResponseString, setImageResponseString] = useState(null);
  const [challengeName, setChallengeName] = useState(null);
  const [challengeDescription, setChallengeDescription] = useState(null);
  const [surveyType, setSurveyType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [coin, setCoin] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [likertType, setLikertType] = useState(null);
  const [loaderState, setLoaderState] = useState(false);
  const [qrString, setQrString] = useState();

  function pad(value) {
    return value < 10 ? `0${value}` : `${value}`;
  }
  // returnedChallenge varsa stateleri setle

  useEffect(() => {
    if (returnedChallenge) {
      let responseData = returnedChallenge.responseData || {};
      if (!returnedChallenge.responseData) {
        responseData = returnedChallenge;
      }
      console.log(responseData.startDate);
      console.log(
        moment.utc(responseData.startDate).local().format("YYYY-MM-DDTHH:mm:ss")
      );

      console.log("RETURNED CHALLENGE = ", returnedChallenge);
      setImageResponseString(responseData.logo || "");
      setSelectedSegment(responseData.segment || "");
     
      setChallengeName(responseData.name || "");
      setChallengeDescription(responseData.description || "");
      setSurveyType(responseData.surveyType || "");
      setStartDate(
        moment.utc(responseData.startDate).format("YYYY-MM-DDTHH:mm:ss") || ""
      );
      setEndDate(
        moment.utc(responseData.endDate).format("YYYY-MM-DDTHH:mm:ss") || ""
      );
      setCoin(responseData.prize || null);
      setQuestions(responseData.questions || null);
      setLikertType(null); // Bu kısmı doldurmalısınız, örnek değer belirtmediniz

      if (responseData.qr) {
        setQrChecked(true);
        setQrString(responseData.qr);
      }

      if (
        responseData.surveyType === "SINGLE_CHOICE" ||
        responseData.surveyType === "MULTIPLE_CHOICE"
      ) {
        if (responseData.surveyType === "SINGLE_CHOICE") {
          setDefaultSelectText(optionsList1[0]);
        } else if (responseData.surveyType === "MULTIPLE_CHOICE") {
          setDefaultSelectText(optionsList1[1]);
        }
        multipleChoiceSurveyTypeChecked();
        textSurveyMethodChecked();
      } else if (responseData.surveyType === "IMAGE") {
        imageSurveyMethodChecked();
      } else {
        ratingScaleSurveyTypeChecked();
        if (responseData.surveyType === "SCORE") {
          setDefaultSelectText(optionsList2[0]);
          setSelectedSurveyType(0);
          console.log("SCORE TYPE SURVEY");
        } else {
          setDefaultSelectText(optionsList2[1]);
          setSelectedSurveyType(1);
          if (responseData.surveyType === "LIKERT_AGREEMENT") {
            likertTypeChange(0);
          } else if (responseData.surveyType === "LIKERT_FREQUENCY") {
            likertTypeChange(1);
          } else if (responseData.surveyType === "LIKERT_IMPORTANCE") {
            likertTypeChange(2);
          } else if (responseData.surveyType === "LIKERT_INTEREST") {
            likertTypeChange(3);
          }
        }
      }
    }
  }, [returnedChallenge]);
  const handleInputChange = (value, setter) => {
    setter(value);
  };

  

  const handleSegmentPopup = (segment) => {
    setPopupSegment(segment);
    setPopup(true);
  };

  const handleFetchSegments = async () => {
    setLoaderState(true);
    const segmentResponse = await CallAPI({
      method: "GET", // HTTP isteği türü
      endPoint:
        apiUrl +
        `/api/v1/brand-admin/brand/segment?page=${segmentPage}&size=${segmentPerPage}`, // API URL'si
      body: undefined, // İstek gövdesi
      successMessage: undefined, // Başarılı mesaj (isteğe bağlı)
      errorMessage: t("errorMessage"), // Hata mesajı
      navigate: navigate, // Yönlendirme fonksiyonu
      t: t, // Çeviri işlevi
    });
    setLoaderState(false);
    console.log(segmentResponse);
    setSegmentTotalPage(segmentResponse.data.pageMetadata.totalPage);
    setSegmentPage(segmentResponse.data.pageMetadata.page);
    setSegmentTotalCount(segmentResponse.data.pageMetadata.count);
    console.log(segmentResponse.data.responseData);

    // allSotiers'ı en başa ekleyelim
    const updatedSegments = [
      {
        id: 0,
        name: t("allSotiers"),
        description: t("segmentForAllUsers"),
        expressionText: `=${t("allSotiers")}`,
      },
      ...segmentResponse.data.responseData,
    ];

    setSegments(updatedSegments);
  };



  const handleKeyDown = (e) => {
    // Ctrl+A (select all) should be allowed
    if (e.ctrlKey || e.metaKey) {
      return;
    }

    // Sadece sayı karakterleri kabul edilsin
    const isValidKey = /^[0-9]*$/.test(e.key);
    if (!isValidKey) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (!qrChecked) setQrString();
  }, [qrChecked]);

  useEffect(() => {
    const token = getCookie("access_token");
    if (!token) {
      console.error("Access token is missing.");
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error("Oturumunuzun süresi doldu. Lütfen yeniden giriş yapınız.");
      navigate("/sign-in-brand");
    }
    handleFetchSegments();
  }, []);
  useEffect(() => {
    handleFetchSegments();
  }, [segmentPage, segmentPerPage]);
  const handleQuestionsData = (questionsData) => {
    // 'questions' verisini işleyerek istediğiniz JSON yapısını oluşturun
    const formattedQuestions = {
      questions: questionsData.map((question, index) => {
        const formattedChoices = question.choices.map((choice, choiceIndex) => {
          return {
            order: choiceIndex, // Sıfırdan başlayacak şekilde düzenleme
            text: choice.text,
          };
        });
        return {
          order: question.order || index,
          text: question.text || "Soru metni", // İstediğiniz varsayılan değeri ayarlayabilirsiniz
          choices: formattedChoices,
          image: question.image,
        };
      }),
    };
    // İşlenmiş verileri state'e ayarlayın
    console.log("PARENTTEKI QUESTIONS", formattedQuestions);
    setQuestions(formattedQuestions);
  };

  const multipleChoiceSelectChange = (selectedIndex) => {
    setLikertTypeSurveySelected(false);
    // Log the selected option index for debugging.

    if (selectedIndex === 0) {
      setSurveyType("SINGLE_CHOICE");
    } else if (selectedIndex === 1) {
      setSurveyType("MULTIPLE_CHOICE");
    }
  };
  //custom select i stringine gore degil indexine gore atama yaptirt... bu sekilde duzgun calismiyo

  const endDateChange = (e) => {
    const newEndDate = e.target.value;
    if (newEndDate === "") {
      toast.error("Lütfen Bir Bitiş Tarihi giriniz");
      handleInputChange("", setEndDate, "endDate");
      return;
    }

    // Tarihten "Z" harfini kaldırın
    const formattedEndDate = new Date(newEndDate).toISOString().slice(0, 19);
    if (new Date(formattedEndDate) < new Date(startDate)) {
      toast.error("Bitiş tarihi başlangıç tarihinden önce olamaz");
      e.target.value = "";
      handleInputChange("", setEndDate, "endDate");
    } else {
      setEndDate(formattedEndDate);
      handleInputChange(formattedEndDate, setEndDate, "endDate");
    }
  };

  const startDateChange = (e) => {
    const startDate = e.target.value;
    if (startDate === "") {
      toast.error("Lütfen bir başlangıç tarihi giriniz");
      return;
    }

    // Tarihten "Z" harfini kaldırın
    const formattedStartDate = new Date(startDate).toISOString().slice(0, 19);
    const selectedStartDate = new Date(startDate);
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    if (selectedStartDate < now) {
      toast.error(
        "Başlangıç tarihi en erken şu andan 30 dakika sonra olmalıdır."
      );
      e.target.value = "";
      handleInputChange("", setStartDate, "startDate");
      return;
    } else {
      setStartDate(formattedStartDate);
      handleInputChange(formattedStartDate, setStartDate, "startDate");
    }
  };

  const challengeNameChange = (e) => {
    setChallengeName(e.target.value);
  };
  const challengeDescriptionChange = (e) => {
    setChallengeDescription(e.target.value);
  };
  const coinChange = (e) => {
    const value = e.target.value;
    const intValue = parseInt(value, 10); // Parse the input as an integer

    if (!isNaN(intValue) && intValue >= 1 && intValue <= 100000) {
      // Geçerli bir tamsayı ve 1 ile 100000 arasında mı kontrol edin
      setCoin(intValue); // Geçerli değeri kullanabilirsiniz
    } else {
      toast("Coin adeti 1 ile 100,000 arasında olmalıdır");
      e.target.value = "";
    }
  };

  const questionImageUpload = (file) => {};
  const createChallenge = (previewBool) => {
    const token = getCookie("access_token");
    if (!token) {
      console.error("Access token is missing.");
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error("Oturumunuzun süresi doldu. Lütfen yeniden giriş yapınız.");
      navigate("/sign-in-brand");
    }

    if (!imageResponseString) {
      setIsImageValid(false);
      toast.error("Lütfen kampanya için bir resim yükleyin.");
      return;
    }
    setIsImageValid(true);
    if (!challengeName) {
      setIsNameValid(false);
      toast.error("Lütfen bir yarışma adı girin.");
      return;
    } else if (challengeName.length < 10) {
      setIsNameValid(false);
      toast.error("Kampanya adı en az 10 karakter olmalı.");
      return;
    }
    setIsNameValid(true);

    if (!challengeDescription) {
      setIsDescriptionValid(false);
      toast.error("Lütfen bir yarışma açıklaması girin.");
      return;
    } else if (challengeDescription.length < 20) {
      setIsDescriptionValid(false);
      toast.error("Kampanya açıklaması en az 20 karakter olmalı.");
      setIsDescriptionValid(true);
      return;
    }
    if (surveyType === "") {
      toast.error("Lütfen Anket Türünü belirtin.");
      return;
    }
    if (!startDate) {
      setIsStartDateValid(false);
      toast.error("Lütfen bir başlangıç tarihi belirtin.");
      return;
    }
    setIsStartDateValid(true);

    if (!endDate) {
      setIsEndDateValid(false);
      toast.error("Lütfen bir bitiş tarihi belirtin.");
      return;
    }
    setIsEndDateValid(true);

    const startTime = new Date(startDate);
    const endTime = new Date(endDate);
    const timeDifference = endTime - startTime;

    if (timeDifference < 60000) {
      toast.error(
        "Başlangıç tarihi ile bitiş tarihi arasında en az 1 dakikalık bir süre olmalıdır."
      );
      return;
    }

    if (coin === undefined) {
      setIsCoinValid(false);
      toast.error("Lütfen coin değerini belirtin.");
      return;
    }

    if (coin < 1 || coin > 100000) {
      setIsCoinValid(false);
      toast.error("Coin değeri 1 ile 100000 arasında olmalıdır.");
      return;
    }
    if (!questions.questions || questions.questions.length < 1) {
      toast.error("Lütfen en az bir soru giriniz.");
      console.log("questions Değeri:", questions);
      return;
    }

    // Continue with creating the challenge
    console.log(
      "CHALLENGE NAME: " +
        challengeName +
        "\n" +
        "CHALLENGE DESCRIPTION: " +
        challengeDescription +
        "\n" +
        "CHALLENGE LOGO: " +
        imageResponseString +
        "\n" +
        "SURVEY TYPE: " +
        surveyType +
        "\n" +
        "START DATE: " +
        startDate +
        "\n" +
        "END DATE: " +
        endDate +
        "\n" +
        "COIN: " +
        coin +
        "\n" +
        "QUESTIONS: " +
        questions
    );
    setSurveyVisible(true);
    // Get the access token from the cookie (You should have a function to get cookies)
    const authToken = getCookie("access_token");
    if (!authToken) {
      console.error("Access token is missing.");
      navigate("/sign-in-brand");
      return;
    }
    // Define the API endpoint URL

    // Kullanıcının oturum açmış olduğunu varsayalım ve bu oturum açma bilgisini auth token olarak alalım

    // Axios ile POST isteği gönderme
    if (returnedChallenge) {
      setLoaderState(true);
      const challengeData = {
        id: returnedChallenge.responseData.id,
        logo: imageResponseString,
        description: challengeDescription,
        name: challengeName,
        surveyType: surveyType, // Gerekirse bu satırı ekleyebilirsiniz
        startDate: startDate,
        endDate: endDate,
        prize: coin,
         ...(selectedSegment && { segment: selectedSegment }),
        questions: questions.questions,
      };
      if (qrString !== undefined) {
        challengeData.qr = qrString;
      }
      console.log(challengeData);
      console.log(returnedChallenge.responseData.id);
      console.log("curl data = ", challengeData);
      const endPoint =
        apiUrl +
        "/api/v1/brand-admin/brand/challenge/survey/" +
        returnedChallenge.responseData.id;
      axios
        .put(endPoint, challengeData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            toast.success(t("success.updateChallenge"));
            setReturnedApiData(response.data);
            console.log(response);
            if (previewBool) {
              setLoaderState(false);
              navigate("/brand/challenges/preview", {
                state: { returnedApiData: response.data },
              });
            } else {
              setLoaderState(false);
              navigate("/brand/challenges");
            }
            // Başarılı sonuçları işlemek için burada kod ekleyebilirsiniz.
          }
        })
        .catch((error) => {
          setLoaderState(false);
          toast.error(
            t("error.updateChallenge") +
              "(" +
              error.response.data.metadata.message +
              ")"
          );
          console.log(error);
          // Hataları işleyin (örneğin, kullanıcıya bir hata mesajı gösterin)
        });
    } else {
      setLoaderState(true);
      const challengeData = {
        logo: imageResponseString,
        description: challengeDescription,
        name: challengeName,
        surveyType: surveyType, // Gerekirse bu satırı ekleyebilirsiniz
        startDate: startDate,
        endDate: endDate,
         ...(selectedSegment && { segment: selectedSegment }),
        questions: questions.questions,
        prize: coin,
      };
      if (qrString !== undefined) {
        challengeData.qr = qrString;
      }
      console.log(challengeData);
      const endPoint = apiUrl + "/api/v1/brand-admin/brand/challenge/survey";
      axios
        .post(endPoint, challengeData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          setLoaderState(false);
          if (response.status === 200) {
            toast.success(t("success.ChallengeCreation"));
            setReturnedApiData(response.data);
            console.log(response.data);
            if (previewBool) {
              navigate("/brand/challenges/preview", {
                state: { returnedApiData: response.data },
              });
            } else {
              navigate("/brand/challenges");
            }
            // Başarılı sonuçları işlemek için burada kod ekleyebilirsiniz.
          }
        })
        .catch((error) => {
          setLoaderState(false);
          toast.error(
            t("error.challengeCreation") +
              "(" +
              error.response.data.metadata.message +
              ")"
          );
          // Hataları işleyin (örneğin, kullanıcıya bir hata mesajı gösterin)
        });
    }
  };

  const toggleSurveyContainer = () => {
    setSurveyVisible(!isSurveyVisible);
  };

  const handleIsSavedChange = (isSaved) => {
    setIsSaved(isSaved);
  };
  const onCurrentQuestionImageChange = (isCurrentQuestionImageChange) => {
    setIsCurrentQuestionImageChange(!isCurrentQuestionImageChange);
  };

  useEffect(() => {
    // isSaved true ise currentQuestion'ı temizle
    if (isSaved) {
      setCurrentQuestion("");
    }
  }, [isSaved]);

  const [currentQuestion, setCurrentQuestion] = useState(""); // Şu anki soru

  const [isTextSurveyMethodChecked, setIsTextSurveyMethodChecked] =
    useState(false);
  const [isImageSurveyMethodChecked, setIsImageSurveyMethodChecked] =
    useState(false);
  const [
    isMultipleChoiceSurveyTypeChecked,
    setIsMultipleChoiceSurveyMethodChecked,
  ] = useState(false);
  const [isRatingScaleSurveyTypeChecked, setIsRatingScaleSurveyTypeChecked] =
    useState(false);
  const [isSurveyMethodVisible, setIsSurveyMethodVisible] = useState(false);
  const [
    isChallengeStartingTypeOnlineChecked,
    setIsChallengeStartingTypeOnlineChecked,
  ] = useState(false);
  const [
    isChallengeStartingTypeStoreChecked,
    setIsChallengeStartingTypeStoreChecked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineDeliveryDateChecked,
    setisChallengeStartingTypeOnlineDeliveryDateChecked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineShoppingDateChecked,
    setIsChallengeStartingTypeOnlineShoppingDateChecked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineDeliveryDateDelay0Checked,
    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineDeliveryDateDelay1Checked,
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineDeliveryDateDelay2Checked,
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineDeliveryDateDelay3Checked,
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked,
  ] = useState(false);

  const optionsList1 = [
    t("multipleChoiceSingleAnswer"),
    t("multipleChoiceMultipleAnswer"),
  ];
  const optionsList2 = [t("linearNumericScale"), t("likertScale")];

  const handleImageUpload = async (file, isChallengeImage) => {
    setLoaderState(true);
    try {
      // Get the access token from the cookie
      const authToken = getCookie("access_token");

      // Create a FormData object to send the file
      const formData = new FormData();

      // Convert Blob URL to Blob data
      const response = await fetch(file);
      const blobData = await response.blob();

      // Append Blob data to FormData
      formData.append("file", blobData);

      // Determine the URL based on whether it's a challenge or a question image
      const uploadUrl = isChallengeImage
        ? apiUrl + "/api/v1/brand-admin/brand/challenge/logo"
        : apiUrl + "/api/v1/brand-admin/brand/challenge/logo";

      // Determine the state and toast message based on whether it's a challenge or a question image
      const stateToUpdate = isChallengeImage
        ? setImageResponseString
        : setCurrentQuestionImageResponseString;
      const successMessage = isChallengeImage
        ? "Challenge image uploaded"
        : "Question image uploaded";

      // Use Axios to send the POST request to upload the file
      const uploadResponse = await axios.post(uploadUrl, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      stateToUpdate(uploadResponse.data.responseData);
      console.log("Dosya başarıyla yüklendi:", uploadResponse.data);

      // İşlem başarıyla tamamlandığında state'i güncelleyebilirsiniz
      setChallengeImage(file);
      setLoaderState(false);
      toast.success(successMessage);
    } catch (error) {
      setLoaderState(false);
      console.error("Dosya yüklenirken hata oluştu:", error);
      setChallengeImage("");
      toast.error("Dosya yüklenirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  const resetQuestionImage = () => {
    setCurrentQuestionImage("");
    setCurrentQuestionImageResponseString();
    setIsCurrentQuestionSaved(true);
    console.log("isSavedTrue in parent:", isCurrentQuestionSaved);
  };
  const likertTypeChange = (type) => {
    console.log("gelen likert type ", type);
    setLikertType(type);
    setLikertTypeSurveySelected(true);
    let newSurveyType = "";

    switch (type) {
      case 0:
        newSurveyType = "LIKERT_AGREEMENT";
        break;
      case 1:
        newSurveyType = "LIKERT_FREQUENCY";
        break;
      case 2:
        newSurveyType = "LIKERT_IMPORTANCE";
        break;
      case 3:
        newSurveyType = "LIKERT_INTEREST";
        break;
      default:
        newSurveyType = "";
    }

    setSurveyType(newSurveyType);
  };

  const hideSurveyMethodDiv = () => {
    setIsSurveyMethodVisible(false);
  };

  const showSurveyMethodDiv = () => {
    setIsSurveyMethodVisible(true);
  };

  const multipleChoiceSurveyTypeChecked = () => {
    setIsMultipleChoiceSurveyMethodChecked(true);
    setIsRatingScaleSurveyTypeChecked(false);
    //karsi tarafin diger elemanlarini sifirlat
    setSelectedSurveyType("textTypeSurvey");
    showSurveyMethodDiv();
  };

  const ratingScaleSurveyTypeChecked = () => {
    setIsRatingScaleSurveyTypeChecked(true);
    setIsMultipleChoiceSurveyMethodChecked(false);
    //    karsi tarafin diger elemanlari sifirlatti
    hideSurveyMethodDiv();
  };

  const textSurveyMethodChecked = () => {
    setIsTextSurveyMethodChecked(true);
    setIsImageSurveyMethodChecked(false);
  };

  const imageSurveyMethodChecked = () => {
    setIsTextSurveyMethodChecked(false);
    setIsImageSurveyMethodChecked(true);
  };

  const challengeStartingTypeOnlineChecked = () => {
    setIsChallengeStartingTypeOnlineChecked(true);
    setIsChallengeStartingTypeStoreChecked(false);

    showOnlineShoppingSection();
    hideStoreShoppingSection();
  };
  const challengeStartingTypeStoreChecked = () => {
    setIsChallengeStartingTypeOnlineChecked(false);
    setIsChallengeStartingTypeStoreChecked(true);

    resetOnlineDeliveryOptions();
    hideOnlineShoppingSection();
    showStoreShoppingSection();
  };
  const resetOnlineDeliveryOptions = () => {
    setIsChallengeStartingTypeOnlineShoppingDateChecked(false);
    setisChallengeStartingTypeOnlineDeliveryDateChecked(false);

    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(false);
  };
  const challengeStartingTypeOnlineDeliveryDateChecked = () => {
    setisChallengeStartingTypeOnlineDeliveryDateChecked(true);
    setIsChallengeStartingTypeOnlineShoppingDateChecked(false);
  };
  const challengeStartingTypeOnlineShoppingDateChecked = () => {
    setIsChallengeStartingTypeOnlineShoppingDateChecked(true);
    setisChallengeStartingTypeOnlineDeliveryDateChecked(false);

    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(false);
  };
  const challengeStartingTypeOnlineDeliveryDateDelay0Checked = () => {
    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(true);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(false);
  };
  const challengeStartingTypeOnlineDeliveryDateDelay1Checked = () => {
    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(true);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(false);
  };
  const challengeStartingTypeOnlineDeliveryDateDelay2Checked = () => {
    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(true);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(false);
  };
  const challengeStartingTypeOnlineDeliveryDateDelay3Checked = () => {
    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(true);
  };
  const hideOnlineShoppingSection = () => {
    const onlineShoppingSection = document.querySelector(
      ".challenge-starting-instructions-delivery-date-checkbox-container"
    );
    const onlineShoppingSection2 = document.querySelector(
      ".challenge-strating-instructions-delivery-date-delay-selection-container"
    );
    const onlineShoppingSection3 = document.querySelector(
      ".challenge-starting-instructions-shopping-date-checkbox-container"
    );
    const onlineShoppingSection4 = document.querySelector(
      ".challenge-starting-instructions-shopping-date-info-row"
    );
    const onlineShoppingSection5 = document.querySelector(
      ".challenge-starting-instructions-advice-row"
    );

    if (onlineShoppingSection) {
      onlineShoppingSection.style.display = "none";
      onlineShoppingSection2.style.display = "none";
      onlineShoppingSection3.style.display = "none";
      onlineShoppingSection4.style.display = "none";
      onlineShoppingSection5.style.display = "none";
    }
  };
  const showOnlineShoppingSection = () => {
    const onlineShoppingSection = document.querySelector(
      ".challenge-starting-instructions-delivery-date-checkbox-container"
    );
    const onlineShoppingSection2 = document.querySelector(
      ".challenge-strating-instructions-delivery-date-delay-selection-container"
    );
    const onlineShoppingSection3 = document.querySelector(
      ".challenge-starting-instructions-shopping-date-checkbox-container"
    );
    const onlineShoppingSection4 = document.querySelector(
      ".challenge-starting-instructions-shopping-date-info-row"
    );
    const onlineShoppingSection5 = document.querySelector(
      ".challenge-starting-instructions-advice-row"
    );

    if (onlineShoppingSection) {
      onlineShoppingSection.style.display = "flex";
      onlineShoppingSection2.style.display = "block";
      onlineShoppingSection3.style.display = "flex";
      onlineShoppingSection4.style.display = "flex";
      onlineShoppingSection5.style.display = "flex";
    }
  };
  const hideStoreShoppingSection = () => {
    const storeShoppingSection = document.querySelector(
      ".challenge-starting-instructions-store-info-row"
    );
    if (storeShoppingSection) {
      storeShoppingSection.style.display = "none";
    }
  };
  const showStoreShoppingSection = () => {
    const storeShoppingSection = document.querySelector(
      ".challenge-starting-instructions-store-info-row"
    );
    if (storeShoppingSection) {
      storeShoppingSection.style.display = "flex";
    }
  };

  useEffect(() => {
    hideStoreShoppingSection();
    hideOnlineShoppingSection();
    const token = getCookie("access_token");
    if (!token) {
      console.error("Access token is missing.");
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error("Oturumunuzun süresi doldu. Lütfen yeniden giriş yapınız.");
      navigate("/sign-in-brand");
    }
  }, []);

  return (
    <div className="dashboard-container dashboard-row">
      {loaderState && <Loader />}
      {popup && (
        <ApprovalPopup
          questionText={t("areYouSureToDeleteSegment")}
          cancelText={t("cancel")}
          approveText={t("delete")}
          onClose={() => {
            setPopup(false);
          }}
          isDeleteApprove={false}
          onCancelButtonClick={() => setPopup(false)}
          approvalObject={popupSegment}
        />
      )}
      <div className="challenge-creation-content-container">
        {/* KAMPANYA GORSELI */}
        <div className="challenge-creation-challenge-img-container">
          <div className="challenge-creation-challenge-segment-title">
            {t("challengeImage")}
          </div>

          <ChallengeImageDropzone
            onImageUpload={(file) => handleImageUpload(file, true)}
            defaultImageUrl={
              returnedChallenge
                ? imagesUrl + "/" + returnedChallenge.responseData.logo
                : ""
            }
            style={{ border: isImageValid ? "" : "2px solid red" }}
          />
          <label>{t("allowedFileTypes")} png, jpg</label>
        </div>
        {/* KAMPANYA ADI */}
        <div className="challenge-creation-input-container">
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
              {t("challengeName")}
              <span>*</span>:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("enterChallengeTitle")}
            </div>
          </div>
          <hr />
          <div className="challenge-creation-input-container-content-wrapper">
            <div className="challenge-title-container">
              <input
                className="challenge-title"
                placeholder={t("enterChallengeName")}
                defaultValue={challengeName}
                maxLength="60"
                onBlur={challengeNameChange}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value,
                    setChallengeName,
                    "challengeName"
                  )
                }
                style={{ border: isNameValid ? "" : "2px solid red" }}
              />
              <div className="challenge-title-info-container">
                <img
                  className="challenge-title-info-icon"
                  src={infoIconEmpty}
                  alt="info:"
                />
                <div className="challenge-title-info-text">
                  {t("min")} 10 {t("max")} 60 {t("character")}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* KAMPANYA ACIKLAMA */}
        <div className="challenge-creation-input-container">
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
              {t("challengeDescription")}
              <span>*</span>:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("enterChallengeDescription")}
            </div>
          </div>
          <hr />
          <div className="challenge-creation-input-container-content-wrapper">
            <div className="challenge-title-container">
              <textarea
                className="challenge-title"
                placeholder={t("enterChallengeDescriptionPlaceholder")}
                maxLength="511"
                defaultValue={challengeDescription}
                onBlur={challengeDescriptionChange}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value,
                    setChallengeDescription,
                    "challengeDescription"
                  )
                }
                style={{ border: isDescriptionValid ? "" : "2px solid red" }}
              />
              <div className="challenge-title-info-container">
                <img
                  className="challenge-title-info-icon"
                  src={infoIconEmpty}
                  alt="info:"
                />
                <div className="challenge-title-info-text">
                  {t("min")} 20, {t("max")} 512 {t("character")}
                </div>
              </div>
            </div>
          </div>
        </div>
         {/* SEGMENT LISTESI */}
         <div className="challenge-creation-input-container">
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
               {t("SegmentList")}:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("SegmentListSubtitle")}
            </div>
          </div>
          <hr />
          <div className="challenge-creation-input-container-content-wrapper">
            <div className="segment-list-container">
              <div className="segment-list-inner">
                {segments &&
                  segments?.map((segment, index) => (
                    <div key={index} className="challenge-creation-segment-row">
                      <div
                        className={
                          selectedSegment === segment.id
                            ? "qr-checkbox checked"
                            : "qr-checkbox"
                        }
                        onClick={() => {
                          setSelectedSegment(segment.id);
                        }}
                      >
                        <img src={tick} alt="Tick" />
                      </div>

                      <div className="challenge-creation-segment-content-container">
                        <Tooltip
                          title={t("showDetails")}
                          position="bottom-start"
                          theme="light"
                          size="small"
                          delay="1000"
                        >
                          <div
                            className="challenge-creation-segment-title segment-list-segment-title outline"
                            onClick={() => {
                              handleSegmentPopup(segment);
                              console.log("SEGMENT = ", segment);
                            }}
                          >
                            {segment.name}
                          </div>
                        </Tooltip>
                        <div className="challenge-creation-segment-description">
                          {segment.description}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="segment-list-footer">
              <Pagination
                currentPage={segmentPage + 1 || null}
                totalPages={segmentTotalPage}
                onPageChange={(page) => {
                  setSegmentPage(page - 1);
                }}
              />
              <ItemsPerPageButton
                onSelectItem={(e) => {
                  handleInputChange(e, setSegmentPerPage);
                }}
                title={t("segmentPerPage")}
              />
            </div>
          </div>
        </div>
        {/* ANKET TURU */}
        <div
          className={`challenge-creation-input-container ${
            returnedChallenge ? "challenge-creation-disabled-container" : ""
          }`}
        >
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
              {t("surveyType")}
              <span>*</span>:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("selectSurveyType")}
            </div>
          </div>
          <hr />
          <div
            id="survey-type-content"
            className="challenge-creation-input-container-content-wrapper"
          >
            <div className="survey-type-column-container">
              <div className="survey-type-column">
                <div className="survey-type-checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="multiple-choice-survey"
                    name="survey-type"
                    checked={isMultipleChoiceSurveyTypeChecked}
                    onChange={
                      returnedChallenge ? null : multipleChoiceSurveyTypeChecked
                    }
                  />
                  <div
                    className={`survey-type-checkbox${
                      isMultipleChoiceSurveyTypeChecked ? " checked" : ""
                    }`}
                    onClick={
                      returnedChallenge ? null : multipleChoiceSurveyTypeChecked
                    }
                  ></div>
                </div>
                <div className="survey-type-checkbox-title-wrapper">
                  <label
                    htmlFor="resimli-anket"
                    className="survey-type-checkbox-title"
                  >
                    {t("multipleChoiceQuestions")}
                  </label>
                  <div className="survey-type-checkbox-subtitle"></div>
                  {!returnedChallenge && isMultipleChoiceSurveyTypeChecked && (
                    <CustomSelect
                      id="multiple-choice-select"
                      defaultText={defaultSelectText}
                      optionsList={optionsList1}
                      onChange={(e) => {
                        if (!returnedChallenge) {
                          multipleChoiceSelectChange(e);
                        }
                      }}
                    />
                  )}
                  {returnedChallenge &&
                    isMultipleChoiceSurveyTypeChecked &&
                    defaultSelectText}
                </div>
              </div>
              <hr />
              <div className="survey-type-column">
                <div className="survey-type-checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="multiple-choice-survey"
                    name="survey-type"
                    checked={isRatingScaleSurveyTypeChecked}
                    onChange={
                      returnedChallenge ? null : ratingScaleSurveyTypeChecked
                    }
                  />
                  <div
                    className={`survey-type-checkbox${
                      isRatingScaleSurveyTypeChecked ? " checked" : ""
                    }`}
                    onClick={
                      returnedChallenge ? null : ratingScaleSurveyTypeChecked
                    }
                  ></div>
                </div>
                <div className="survey-type-checkbox-title-wrapper">
                  <label
                    htmlFor="resimli-anket"
                    className="survey-type-checkbox-title"
                  >
                    {t("ratingScaleQuestions")}
                  </label>
                  <div className="survey-type-checkbox-subtitle"></div>
                  {!returnedChallenge && isRatingScaleSurveyTypeChecked && (
                    <CustomSelect
                      id="rating-scale-select"
                      defaultText={defaultSelectText}
                      optionsList={optionsList2}
                      onChange={(selectedValue) => {
                        if (!returnedChallenge) {
                          setSelectedSurveyType(selectedValue);
                          if (selectedValue === 0) {
                            setSurveyType("SCORE");
                          }
                          if (selectedValue !== 1) {
                            setLikertTypeSurveySelected(false);
                          }
                        }
                      }}
                    />
                  )}
                  {returnedChallenge &&
                    isRatingScaleSurveyTypeChecked &&
                    defaultSelectText}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ANKET YONTEMI */}
        {isSurveyMethodVisible && (
          <div
            id="survey-method"
            className={`challenge-creation-input-container ${
              returnedChallenge ? "challenge-creation-disabled-container" : ""
            }`}
          >
            <div className="challenge-creation-input-container-header">
              <div className="challenge-creation-input-container-header-title">
                {t("surveyMethod")}
                <span>*</span>:
              </div>
              <div className="challenge-creation-input-container-header-subtitle">
                {t("selectSurveyMethod")}
              </div>
            </div>
            <hr />
            <div className="challenge-creation-input-container-content-wrapper">
              <div className="survey-method-checkbox-container">
                <div className="survey-method-checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="resimli-anket"
                    name="survey-method"
                    checked={isTextSurveyMethodChecked}
                    onChange={
                      returnedChallenge ? null : textSurveyMethodChecked
                    }
                  />
                  <div
                    className={`survey-method-checkbox${
                      isTextSurveyMethodChecked ? " checked" : ""
                    }`}
                    onClick={returnedChallenge ? null : textSurveyMethodChecked}
                  ></div>
                  <div className="survey-method-checkbox-title-wrapper">
                    <label
                      htmlFor="resimli-anket"
                      className="survey-method-checkbox-title"
                    >
                      {t("textResponses")}
                    </label>
                    <div className="survey-method-checkbox-subtitle"></div>
                  </div>
                </div>

                <div className="survey-method-checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="resimli-anket"
                    name="survey-method"
                    checked={isImageSurveyMethodChecked}
                    onChange={
                      returnedChallenge ? null : imageSurveyMethodChecked
                    }
                  />
                  <div
                    className={`survey-method-checkbox${
                      isImageSurveyMethodChecked ? " checked" : ""
                    }`}
                    onClick={
                      returnedChallenge ? null : imageSurveyMethodChecked
                    }
                  ></div>
                  <div className="survey-method-checkbox-title-wrapper">
                    <label
                      htmlFor="resimli-anket"
                      className="survey-method-checkbox-title"
                    >
                      {t("imageResponses")}
                    </label>
                    <div className="survey-method-checkbox-subtitle"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {selectedSurveyType === 1 && (
          <div
            id="survey-method"
            className="challenge-creation-input-container"
          >
            <div className="challenge-creation-input-container-header">
              <div className="challenge-creation-input-container-header-title">
                {t("surveyMethod")}
                <span>*</span>:
              </div>
              <div className="challenge-creation-input-container-header-subtitle">
                {t("selectSurveyMethod")}
              </div>
            </div>
            <hr />
            <div className="challenge-creation-input-container-content-wrapper likert-type-survey-choice-container">
              <div className="survey-method-checkbox-container">
                <div className="survey-method-checkbox-wrapper">
                  <input
                    type="checkbox"
                    name="survey-method"
                    checked={likertType === 0}
                  />
                  <div
                    className={`survey-method-checkbox${
                      likertType === 0 ? " checked" : ""
                    }`}
                    onClick={() => likertTypeChange(0)}
                  ></div>
                  <div className="survey-method-checkbox-title-wrapper">
                    <label
                      htmlFor="resimli-anket"
                      className="survey-method-checkbox-title"
                    >
                      {t("agreement")}
                    </label>
                    <div className="survey-method-checkbox-subtitle"></div>
                  </div>
                </div>
              </div>
              <div className="survey-method-checkbox-container">
                <div className="survey-method-checkbox-wrapper">
                  <input
                    type="checkbox"
                    name="survey-method"
                    checked={likertType === 1}
                  />
                  <div
                    className={`survey-method-checkbox${
                      likertType === 1 ? " checked" : ""
                    }`}
                    onClick={() => likertTypeChange(1)}
                  ></div>
                  <div className="survey-method-checkbox-title-wrapper">
                    <label
                      htmlFor="resimli-anket"
                      className="survey-method-checkbox-title"
                    >
                      {t("frequency")}
                    </label>
                    <div className="survey-method-checkbox-subtitle"></div>
                  </div>
                </div>
              </div>
              <div className="survey-method-checkbox-container">
                <div className="survey-method-checkbox-wrapper">
                  <input
                    type="checkbox"
                    name="survey-method"
                    checked={likertType === 2}
                  />
                  <div
                    className={`survey-method-checkbox${
                      likertType === 2 ? " checked" : ""
                    }`}
                    onClick={() => likertTypeChange(2)}
                  ></div>
                  <div className="survey-method-checkbox-title-wrapper">
                    <label
                      htmlFor="resimli-anket"
                      className="survey-method-checkbox-title"
                    >
                      {t("importance")}
                    </label>
                    <div className="survey-method-checkbox-subtitle"></div>
                  </div>
                </div>
              </div>
              <div className="survey-method-checkbox-container">
                <div className="survey-method-checkbox-wrapper">
                  <input
                    type="checkbox"
                    name="survey-method"
                    checked={likertType === 3}
                  />
                  <div
                    className={`survey-method-checkbox${
                      likertType === 3 ? " checked" : ""
                    }`}
                    onClick={() => likertTypeChange(3)}
                  ></div>
                  <div className="survey-method-checkbox-title-wrapper">
                    <label
                      htmlFor="resimli-anket"
                      className="survey-method-checkbox-title"
                    >
                      {t("interest")}
                    </label>
                    <div className="survey-method-checkbox-subtitle"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* ANKET SORULARI */}
        <div className={`challenge-creation-input-container`}>
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
              {t("surveyQuestions")}
              <span>*</span>:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("setSurveyQuestions")}
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("questionCountLimit")}
            </div>
          </div>
          <hr />
          <div className="challenge-creation-input-container-content-wrapper">
            <div className="challenge-creation-survey-container">
              <div className="challenge-creation-survey-question-container">
                <div className="challenge-creation-survey-question-text-container">
                  <div className="challenge-creation-survey-question-title">
                    {t("question")}
                    <span className="challenge-creation-input-container-header-subtitle">
                      <img
                        className="challenge-creation-date-description-icon"
                        src={infoIconEmpty}
                        alt="icon"
                      />{" "}
                      {t("min")} 3, {t("max")} 140 {t("character")}
                    </span>
                  </div>
                  <div className="challenge-creation-survey-question-input-wrapper">
                    <input
                      type="text"
                      className="challenge-creation-survey-question-input"
                      placeholder={t("enterQuestion")}
                      defaultValue={currentQuestion}
                      onChange={(e) =>
                        handleInputChange(
                          e.target.value,
                          setCurrentQuestion,
                          "currentQuestion"
                        )
                      }
                      maxLength={140}
                      minLength={3}
                    />
                  </div>
                </div>
                <div className="challenge-creation-survey-question-img-container">
                  <div className="challenge-creation-survey-question-img-title">
                    {t("questionImage")}
                  </div>

                  <ChallengeImageDropzone
                    onImageUpload={(file) => handleImageUpload(file, false)}
                    style={{ border: isImageValid ? "" : "2px solid red" }}
                    isSavedTrue={isCurrentQuestionSaved}
                    clearImage={clearImage}
                  />

                  <label
                    style={{
                      color: "#c8c0c0",
                      fontSize: "14px",
                    }}
                  >
                    {t("allowedFileTypes")} png, jpg
                  </label>
                </div>
              </div>
              <div className="challenge-creation-survey-question-image-info challenge-creation-input-container-header-subtitle">
                <img
                  className="challenge-creation-survey-question-image-info-icon"
                  src={infoIconEmpty}
                  alt="icon"
                />
                <label>
                  {t("imageUploadInstructions")}
                  <ul>
                    <li>{t("idealDimensions")}</li>
                    <li>{t("idealDimensions2")}</li>
                  </ul>
                  {t("fileSizeRecommendation")}
                </label>
              </div>

              <hr />
              {selectedSurveyType === "textTypeSurvey" && (
                <TextTypeSurvey
                  question={currentQuestion}
                  currentQuestionImageResponseString={
                    currentQuestionImageResponseString
                  }
                  returnedQuestions={
                    returnedChallenge
                      ? returnedChallenge.responseData.questions
                      : false
                  }
                  onIsSavedChange={handleIsSavedChange}
                  onQuestionsDataReady={handleQuestionsData}
                  infoIconEmpty={infoIconEmpty}
                  onCurrentQuestionImageChange={onCurrentQuestionImageChange}
                  setCurrentQuestionImageResponseString={
                    setCurrentQuestionImageResponseString
                  }
                  onResetQuestionImage={resetQuestionImage}
                  setClearImage={setClearImage}
                  clearImage={clearImage}
                />
              )}
              {selectedSurveyType === 0 && (
                <NumericMetricTypeSurvey
                  question={currentQuestion}
                  currentQuestionImageResponseString={
                    currentQuestionImageResponseString
                  }
                  returnedQuestions={
                    returnedChallenge
                      ? returnedChallenge.responseData.questions
                      : false
                  }
                  onIsSavedChange={handleIsSavedChange}
                  onQuestionsDataReady={handleQuestionsData}
                  infoIconEmpty={infoIconEmpty}
                  onCurrentQuestionImageChange={onCurrentQuestionImageChange}
                  setCurrentQuestionImageResponseString={
                    setCurrentQuestionImageResponseString
                  }
                  onResetQuestionImage={resetQuestionImage}
                  setClearImage={setClearImage}
                  clearImage={clearImage}
                />
              )}
              {likertTypeSurveySelected && (
                <LikertTypeSurvey
                  question={currentQuestion}
                  currentQuestionImageResponseString={
                    currentQuestionImageResponseString
                  }
                  returnedQuestions={
                    returnedChallenge
                      ? returnedChallenge.responseData.questions
                      : false
                  }
                  onIsSavedChange={handleIsSavedChange}
                  onQuestionsDataReady={handleQuestionsData}
                  infoIconEmpty={infoIconEmpty}
                  onCurrentQuestionImageChange={onCurrentQuestionImageChange}
                  setCurrentQuestionImageResponseString={
                    setCurrentQuestionImageResponseString
                  }
                  onResetQuestionImage={resetQuestionImage}
                  likertType={likertType}
                  setClearImage={setClearImage}
                  clearImage={clearImage}
                />
              )}
            </div>
          </div>
        </div>
        {/* ANKET TARIHI */}
        <div className="challenge-creation-input-container">
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
              {t("challengeDate")}
              <span>*</span>:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("setCampaignDateTime")}
            </div>
          </div>
          <hr />
          <div className="challenge-creation-input-container-content-wrapper">
            <div className="challenge-creation-date-column-container">
              <div className="challenge-creation-date-column">
                <div className="challenge-creation-date-title">
                  {t("startDate")}
                  <span>*</span>
                </div>
                <input
                  type="datetime-local"
                  className="challenge-creation-date-picker"
                  defaultValue={
                    returnedChallenge
                      ? moment
                          .utc(returnedChallenge.responseData.startDate)
                          .local()
                          .format("YYYY-MM-DDTHH:mm:ss")
                      : startDate
                  }
                  onBlur={startDateChange}
                  style={{ border: isStartDateValid ? "" : "2px solid red" }}
                />
              </div>

              <div className="challenge-creation-date-column">
                <div className="challenge-creation-date-title">
                  {t("endDate")}
                  <span>*</span>
                </div>
                <input
                  type="datetime-local"
                  className="challenge-creation-date-picker"
                  defaultValue={
                    returnedChallenge
                      ? moment
                          .utc(returnedChallenge.responseData.endDate)
                          .local()
                          .format("YYYY-MM-DDTHH:mm:ss")
                      : endDate
                  }
                  onBlur={endDateChange}
                  style={{ border: isEndDateValid ? "" : "2px solid red" }}
                />
              </div>
            </div>

            <div className="challenge-creation-date-description-row">
              <img
                className="challenge-creation-date-description-icon"
                src={infoIconEmpty}
                alt="icon"
              />
              <div className="challenge-creation-date-description">
                {t("setChallengeStartDate")}
              </div>
            </div>
            <div className="challenge-creation-date-description-row">
              <img
                className="challenge-creation-date-description-icon"
                src={infoIconEmpty}
                alt="icon"
              />
              <div className="challenge-creation-date-description">
                {t("noEndDateInfo")}
              </div>
            </div>
          </div>
        </div>
        {/* KAMPANYA BASLAMA TALIMATI */}
        <div
          className="challenge-creation-input-container"
          style={{ display: "none" }}
        >
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
              {t("challengeStartInstructions")}
              <span>*</span>:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("challengeProcessInstructions")}{" "}
            </div>
          </div>
          <hr />
          <div className="challenge-creation-input-container-content-wrapper">
            <div className="challenge-starting-instructions-column-container">
              <div className="challenge-starting-instructions-column">
                <div className="challenge-starting-instructions-checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="resimli-anket"
                    name="survey-method"
                    checked={isChallengeStartingTypeOnlineChecked}
                    onChange={challengeStartingTypeOnlineChecked}
                  />
                  <div
                    className={`challenge-starting-instructions-checkbox${
                      isChallengeStartingTypeOnlineChecked ? " checked" : ""
                    }`}
                    onClick={challengeStartingTypeOnlineChecked}
                  ></div>
                  <div className="challenge-starting-instructions-checkbox-title-wrapper">
                    <label
                      htmlFor="resimli-anket"
                      className="challenge-starting-instructions-checkbox-title"
                    >
                      {t("online")}
                    </label>
                    <div className="challenge-starting-instructions-checkbox-subtitle"></div>
                  </div>
                </div>

                <div className="challenge-starting-instructions-delivery-date-checkbox-container">
                  <div className="challenge-starting-instructions-delivery-date-checkbox-wrapper">
                    <input
                      type="checkbox"
                      id="resimli-anket"
                      name="survey-method"
                      checked={isChallengeStartingTypeOnlineDeliveryDateChecked}
                      onChange={challengeStartingTypeOnlineDeliveryDateChecked}
                    />
                    <div
                      className={`challenge-starting-instructions-delivery-date-checkbox${
                        isChallengeStartingTypeOnlineDeliveryDateChecked
                          ? " checked"
                          : ""
                      }`}
                      onClick={challengeStartingTypeOnlineDeliveryDateChecked}
                    ></div>
                    <div className="challenge-starting-instructions-delivery-date-checkbox-title-wrapper">
                      <label
                        htmlFor="resimli-anket"
                        className="challenge-starting-instructions-delivery-date-checkbox-title"
                      >
                        {t("deliveryDay")}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="challenge-strating-instructions-delivery-date-delay-selection-container">
                  {isChallengeStartingTypeOnlineDeliveryDateChecked && (
                    <div className="challenge-starting-instructions-delivery-date-delay-selection-row">
                      <input
                        type="checkbox"
                        id="resimli-anket"
                        name="survey-method"
                        checked={
                          isChallengeStartingTypeOnlineDeliveryDateDelay0Checked
                        }
                        onChange={
                          challengeStartingTypeOnlineDeliveryDateDelay0Checked
                        }
                      />
                      <div
                        className={`challenge-starting-instructions-delivery-date-delay-selection-checkbox${
                          isChallengeStartingTypeOnlineDeliveryDateDelay0Checked
                            ? " checked"
                            : ""
                        }`}
                        onClick={
                          challengeStartingTypeOnlineDeliveryDateDelay0Checked
                        }
                      >
                        0 {t("day")}
                      </div>
                      <input
                        type="checkbox"
                        id="resimli-anket"
                        name="survey-method"
                        checked={
                          isChallengeStartingTypeOnlineDeliveryDateDelay1Checked
                        }
                        onChange={
                          challengeStartingTypeOnlineDeliveryDateDelay1Checked
                        }
                      />
                      <div
                        className={`challenge-starting-instructions-delivery-date-delay-selection-checkbox${
                          isChallengeStartingTypeOnlineDeliveryDateDelay1Checked
                            ? " checked"
                            : ""
                        }`}
                        onClick={
                          challengeStartingTypeOnlineDeliveryDateDelay1Checked
                        }
                      >
                        5 {t("day")}
                      </div>
                      <input
                        type="checkbox"
                        id="resimli-anket"
                        name="survey-method"
                        checked={
                          isChallengeStartingTypeOnlineDeliveryDateDelay2Checked
                        }
                        onChange={
                          challengeStartingTypeOnlineDeliveryDateDelay2Checked
                        }
                      />
                      <div
                        className={`challenge-starting-instructions-delivery-date-delay-selection-checkbox${
                          isChallengeStartingTypeOnlineDeliveryDateDelay2Checked
                            ? " checked"
                            : ""
                        }`}
                        onClick={
                          challengeStartingTypeOnlineDeliveryDateDelay2Checked
                        }
                      >
                        15 {t("day")}
                      </div>
                      <input
                        type="checkbox"
                        id="resimli-anket"
                        name="survey-method"
                        checked={
                          isChallengeStartingTypeOnlineDeliveryDateDelay3Checked
                        }
                        onChange={
                          challengeStartingTypeOnlineDeliveryDateDelay3Checked
                        }
                      />
                      <div
                        className={`challenge-starting-instructions-delivery-date-delay-selection-checkbox${
                          isChallengeStartingTypeOnlineDeliveryDateDelay3Checked
                            ? " checked"
                            : ""
                        }`}
                        onClick={
                          challengeStartingTypeOnlineDeliveryDateDelay3Checked
                        }
                      >
                        30 {t("day")}
                      </div>
                    </div>
                  )}
                  <div className="challenge-starting-instructions-info-row">
                    <img
                      className="challenge-starting-instructions-info-icon"
                      src={infoIconEmpty}
                      alt="icon"
                    />
                    <div className="challenge-starting-instructions-info">
                      {t("onlineShoppingProcess")}
                    </div>
                  </div>
                </div>
                <div className="challenge-starting-instructions-shopping-date-checkbox-container">
                  <div className="challenge-starting-instructions-delivery-date-checkbox-wrapper">
                    <input
                      type="checkbox"
                      id="resimli-anket"
                      name="survey-method"
                      checked={isChallengeStartingTypeOnlineShoppingDateChecked}
                      onChange={challengeStartingTypeOnlineShoppingDateChecked}
                    />
                    <div
                      className={`challenge-starting-instructions-delivery-date-checkbox${
                        isChallengeStartingTypeOnlineShoppingDateChecked
                          ? " checked"
                          : ""
                      }`}
                      onClick={challengeStartingTypeOnlineShoppingDateChecked}
                    ></div>
                    <div className="challenge-starting-instructions-delivery-date-checkbox-title-wrapper">
                      <label
                        htmlFor="resimli-anket"
                        className="challenge-starting-instructions-delivery-date-checkbox-title"
                      >
                        {t("purchaseDay")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="challenge-starting-instructions-shopping-date-info-row">
                  <img
                    className="challenge-starting-instructions-info-icon"
                    src={infoIconEmpty}
                    alt="icon"
                  />
                  <div className="challenge-starting-instructions-info">
                    {t("onlineShoppingStartDate")}
                  </div>
                </div>
                <div className="challenge-starting-instructions-advice-row">
                  <img
                    className="challenge-starting-instructions-info-icon"
                    src={infoIconEmpty}
                    alt="icon"
                  />
                  <div className="challenge-starting-instructions-info">
                    {t("deliveryDaySOTY")}
                  </div>
                </div>
              </div>
              <hr />
              <div className="challenge-starting-instructions-column">
                <div className="challenge-starting-instructions-checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="resimli-anket"
                    name="survey-method"
                    checked={isChallengeStartingTypeStoreChecked}
                    onChange={challengeStartingTypeStoreChecked}
                  />
                  <div
                    className={`challenge-starting-instructions-checkbox${
                      isChallengeStartingTypeStoreChecked ? " checked" : ""
                    }`}
                    onClick={challengeStartingTypeStoreChecked}
                  ></div>
                  <div className="challenge-starting-instructions-checkbox-title-wrapper">
                    <label
                      htmlFor="resimli-anket"
                      className="challenge-starting-instructions-checkbox-title"
                    >
                      {t("store")}
                    </label>
                    <div className="challenge-starting-instructions-checkbox-subtitle"></div>
                  </div>
                </div>
                <div className="challenge-starting-instructions-store-info-row">
                  <img
                    className="challenge-starting-instructions-info-icon"
                    src={infoIconEmpty}
                    alt="icon"
                  />
                  <div className="challenge-starting-instructions-info">
                    {t("inStoreShoppingStartDate")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {returnedChallenge && returnedChallenge.responseData.qr && (
          <QrOptionReadOnly qrCode={returnedChallenge.responseData.qr} />
        )}
        {!returnedChallenge && (
          <QrOption
            qrChecked={(isChecked) => {
              setQrChecked(isChecked);
            }}
            qrString={(theQrString) => {
              setQrString(theQrString);
            }}
          />
        )}
        {/* SOTY COIN */}
        <SingleNumberInput
          title={t("sotyCoin")}
          isMandatory={true}
          titleDescription={t("setSotyCoinAmount")}
          inputPlaceholder={t("enterSotyCoinAmount")}
          infoTextFooter={[
            (coin || 0) +
              " " +
              t("sotyCoin") +
              " " +
              t("equalsTo") +
              " " +
              coin / 10 +
              "TL",
            t("sotyCoinValue"),
          ]}
          value={coin}
          onChange={(e) => {
            handleInputChange(e.target.value, setCoin);
          }}
          onBlur={(e) => {
            coinChange(e);
          }}
        />

        {/* KAMPANYA ONAY DUGMELERI */}
        <div className="challenge-creation-submit-button-container">
          <button
            className="draft"
            onClick={() => {
              createChallenge(false);
            }}
          >
            {t("saveAsDraft")}
          </button>
          <button
            className="preview"
            onClick={() => {
              createChallenge(true);
            }}
          >
            {t("preview")}
          </button>
        </div>
      </div>
      <div
        className="challenge-creation-phone-container"
        style={{ position: "sticky", top: "0", right: "30px" }}
      >
        <PhoneViewJoin
          name={challengeName}
          description={challengeDescription}
          prize={coin || 0}
          endDate={endDate || ""}
          logo={imagesUrl + "/" + imageResponseString || noImageToShow}
          imagesUrl={imagesUrl}
          brandLogo={noImageToShow}
          challengeType={t("fillSurvey")}
          socialProvider={t("fillSurvey")}
        />
      </div>
    </div>
  );
};

export default SurveyCreation;
