import axios from "axios";
import { toast } from "react-toastify";
import {
  getCookie,
  isMyTokenExpired,
  deleteCookie,
  getUserTypeFromToken,
} from "../pages/auth/tokenUtils";

const CallAPI = async ({
  method,
  endPoint,
  endpointParams,
  body = null,
  successMessage,
  errorMessage,
  noAuth = false,
  dontShowError = false,
  isMultipart = false, // New parameter to handle multipart form-data
  t,
}) => {
  const authToken = getCookie("access_token");

  if (!noAuth) {
    // Token check
    if (!authToken) {
      console.error("Access token is missing.");
      window.location.href = "/sign-in-brand";
      return;
    }

    if (isMyTokenExpired(authToken)) {
      deleteCookie("access_token");
      toast.error(t("error.sessionExpired"));
      window.location.href = "/sign-in-brand";
      return;
    }
  }

  try {
    const url = endpointParams ? `${endPoint}?${endpointParams}` : endPoint;

    // Dynamically set headers based on isMultipart flag
    const headers = {
      ...(isMultipart
        ? {} // For multipart/form-data, axios will automatically set the boundary, so no need to set Content-Type manually
        : { "Content-Type": "application/json" }), // Default to application/json
      ...(authToken && !noAuth ? { Authorization: `Bearer ${authToken}` } : {}),
    };

    const config = {
      method: method,
      url: url,
      headers: headers,
      data: body,
    };

    const response = await axios(config);
    if (successMessage) {
      toast.success(successMessage);
    }
    return response;
  } catch (error) {
    console.error(error);
    if (!dontShowError) {
      toast.error(
        `${errorMessage} (${
          error.response?.data?.metadata?.message || "Unknown error"
        })`
      );
    }

    if (error.response?.status === 401) {
      if (getUserTypeFromToken(authToken)) {
        window.location.href = "/sign-in-brand";
      } else {
        window.location.href = "/sign-in-realm";
      }
    }
  }
};

export default CallAPI;
