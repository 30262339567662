import React, { useState, useEffect } from "react";
import arrow from '../../../../../../assets/media/arrow.png';
import '../../../../../../assets/css/ChallengeDashboard.css';
import downArrow from '../../../../../../assets/media/down-arrow.png';
import SearchIcon from '../../../../../../assets/media/SearchIcon.svg';
import SortByButton from "../../../../../button/sortByButton";
import FilterButton from "../../../../../button/filterButton";
import DatePickerButton from "../../../../../button/datePickerButton";
import TotalCards from "../../../../../cards/TotalCards";
import CreateChallengeButton from "../../../../../button/createChallengeButton";
import TabButton from "../../../../../button/tabButton";
import GridListSwitchButton from "../../../../../button/gridListSwitchButton";
import ChallengeCard from "../../../../../cards/challengeCard";
import DynamicTable from "../../../../../tables/DynamicTable";
import facebookIcon from "../../../../../../assets/media/facebook.svg";
import instagramIcon from "../../../../../../assets/media/instagram.svg";
import twitterIcon from "../../../../../../assets/media/x-twitter.svg";
import youtubeIcon from "../../../../../../assets/media/youtube.svg";
import tiktokIcon from "../../../../../../assets/media/tiktok.svg";
import placeholderImg from "../../../../../../assets/media/placeholder.png";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { getCookie, getUserTypeFromToken, isMyTokenExpired } from "../../../../auth/tokenUtils";
import { apiUrl, imagesUrl } from "../../../../../../config";
import axios from "axios";
import Pagination from "../../../../../tables/Pagination";
import { useNavigate } from "react-router-dom";
import ItemsPerPageButton from "../../../../../button/itemsPerPageButton";
import { isIn } from "validator";
import Loader from "../../../../../popup/loader";
import CreateCampaignButton from "../../../../../button/createCampaignButton";
import CampaignCard from "../../../../../cards/campaignCard";
import CallAPI from "../../../../../api/callApi";
import NavigationBar from "../../../../../utils/navigation_bar/navigationBar";

const CampaignDashboard = () => {
    const [viewType, setViewType] = useState('GRID');
    const { t, i18n } = useTranslation("challengeDashboard");
    const token = getCookie("access_token");
    const [challenges, setChallenges] = useState();
    const [campaigns, setCampaigns] = useState();
    const [uuid, setUuid] = useState();
    const [campaignFilter, setCampaignFilter] = useState(" ");
    const [campaignStatusFilter, setCampaignStatusFilter] = useState(" ");
    const [campaignsList, setCampaignsList] = useState();
    const [corporateFilter, setCorporateFilter] = useState(" ");
    const [pageMetaData, setPageMetaData] = useState();
    const [clickedPage, setClickedPage] = useState('0');
    const [activeTab, setActiveTab] = useState(0);
    const [activeTabKey, setActiveTabKey] = useState("ALL");
    const [responseData, setResponseData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [challengesForList, setChallengesForList] = useState([]);
    const [itemPerPage, setItemPerPage] = useState(20);
    const [challengeStartDatesWithProvider, setChallengeStartDatesWithProvider] = useState([]);
    const [prevClickedPage, setPrevClickedPage] = useState('0');
    const [filterChallengeType, setFilterChallengeType] = useState([]);
    const [filterSocialChallengeType, setFilterSocialChallengeType] = useState([]);
    const [filterSocialProvider, setFilterSocialProvider] = useState([]);
    const [filterStartDate, setFilterStartDate] = useState();
    const [filterEndDate, setFilterEndDate] = useState();
    const [sortChallengesBy, setSortChallengesBy] = useState();

    const [corporationsList, setCorporationsList] = useState();

    const [campaignStatus, setCampaignStatus] = useState();
    const [corporateNames, setCorporateNames] = useState();

    //templer, diger social providerlar gelene kadar gecici sureligine endpoint icin editlenmis degiskenler.
    const [tempSocialChallengeType, setTempSocialChallengeType] = useState();
    const [tempChallengeType, setTempChallengeType] = useState();
    const [tempSocialProvider, setTempSocialProvider] = useState();
    const [tempStartDate, setTempStartDate] = useState();
    const [tempEndDate, setTempEndDate] = useState();


    const [endpoint, setEndpoint] = useState();
    const [loaderState, setLoaderState] = useState(false);

    const [brandId, setBrandID] = useState('');

    const navigate = useNavigate();


    const [headerValues, setHeaderValues] = useState(false);

    useEffect(() => {

        localStorage.removeItem('checkboxStates');

        if (!token) {
            // Handle the case where the token is empty or undefined
            console.log("JWT token is empty or undefined");
            navigate('/sign-in-brand')
            return;
        }
        if (isMyTokenExpired(token)) {
            document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            toast.error(t("error401"));
            navigate("/sign-in-brand");
        }
        const today = new Date();
        const thirtyDaysAgo = new Date(today);
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30); // Bugünden 30 gün önceki tarihi al

        const year = thirtyDaysAgo.getFullYear();
        const month = (thirtyDaysAgo.getMonth() + 1).toString().padStart(2, '0');
        const firstDayOfMonth = `${year}-${month}-01T00:00:00`;
        const todaysMonth = today.getMonth();

        const lastHourOfDay = `${year}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}T23:59:59`;

        setFilterStartDate(firstDayOfMonth);
        setFilterEndDate(lastHourOfDay);


        fetchCorporations();
        fetchUUID();

        if (uuid) {
            fetchTopData();
            fetchListData();
        }


    }, []);
    const addSpaceBeforeCapital = (str) => {
        return str.replace(/([A-Z])/g, ' $1').trim();
    };
    const getInitials = (str) => {
        const words = str.split(/(?=[A-Z])/);
        return words.map(word => word[0]).join('');
    };

    const fetchCorporations = async () => {
        const responseCorporation = await CallAPI({
            method: "GET", // HTTP isteği türü
            endPoint: apiUrl + "/api/v1/brand-admin/brand/campaign/corporate", // API URL'si
            body: undefined, // İstek gövdesi
            successMessage: undefined, // Başarılı mesaj (isteğe bağlı)
            errorMessage: t("errorMessage"), // Hata mesajı
            navigate: navigate, // Yönlendirme fonksiyonu
            t: t // Çeviri işlevi
        });

        setCorporationsList(responseCorporation.data.responseData
            .filter(item => item !== null) // Null olmayanları filtrele
            .map(item => ({
                title: item,
                corporationName: item
            }))
        );


    }
    const fetchUUID = async () => {
        const endpoint = apiUrl + "/api/v1/brand-admin/brand"
        try {
            setLoaderState(true);
            const response = await axios.get(endpoint, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setLoaderState(false);
            setUuid(response.data.responseData.uuid);


        } catch (error) {
            setLoaderState(false);
            toast.error(error.message);
            console.error('Error fetching data:', error);
        }

    }


    useEffect(() => {
        console.log(campaignFilter);
    }, [campaignFilter])
    const fetchTopData = async () => {

        const url = `https://metabase.dev.soty.io/api/public/card/86606a4d-e283-4b38-ab52-c152a0291dcc/query/json?parameters=[{"type":"category","target":["variable",["template-tag","brand_uuid"]],"value":"${uuid ? uuid : " "}"},{"type":"category","target":["variable",["template-tag","start_date"]],"value":"${filterStartDate ? filterStartDate : "2020-01-01"}"},{"type":"category","target":["variable",["template-tag","end_date"]],"value":"${filterEndDate ? filterEndDate : "2100-01-01T23:59:59"}"},{"type":"category","target":["variable",["template-tag","types"]],"value":"${campaignFilter ? campaignFilter : " "}"},{"type":"category","target":["variable",["template-tag","corporate_names"]],"value":"${corporateFilter ? corporateFilter : " "}"},{"type":"category","target":["variable",["template-tag","status"]],"value":"${campaignStatusFilter ? campaignStatusFilter : " "}"}]`;

        try {
            const response = await axios.get(url);
            const dataSets = [
                {
                    headerTitle: t('total'),
                    title: t("income"),
                    subtitle: t("TG"),
                    data: response.data[0].giro === null ? '0' : response.data[0].giro
                },
                {
                    headerTitle: t('total'),
                    title: t("campaign_count"),
                    subtitle: t("CC"),
                    data: response.data[0].campaign_count === null ? '0' : response.data[0].campaign_count
                },
                {
                    headerTitle: t('total'),
                    title: t("coupon_ratio"),
                    subtitle: t("CR"),
                    data: response.data[0].coupon_ratio === null ? '0' : response.data[0].coupon_ratio
                },
                {
                    headerTitle: t('total'),
                    title: t("earned_soty_coin"),
                    subtitle: t("ESC"),
                    data: response.data[0].earned_soty_coin === null ? '0' : response.data[0].earned_soty_coin
                },
                ,
                {
                    headerTitle: t('total'),
                    title: t("folded_soty_coin"),
                    subtitle: t("FSC"),
                    data: response.data[0].folded_soty_coin === null ? '0' : response.data[0].folded_soty_coin
                },
                ,
                {
                    headerTitle: t('total'),
                    title: t("invited_users"),
                    subtitle: t("IU"),
                    data: response.data[0].invited_users === null ? '0' : response.data[0].invited_users
                },
                ,
                {
                    headerTitle: t('total'),
                    title: t("participation_count"),
                    subtitle: t("PC"),
                    data: response.data[0].participation_count === null ? '0' : response.data[0].participation_count
                },
                ,
                {
                    headerTitle: t('total'),
                    title: t("refund_amount"),
                    subtitle: t("RA"),
                    data: response.data[0].refund_amount
                },
                ,
                {
                    headerTitle: t('total'),
                    title: t("success_ratio"),
                    subtitle: t("SR"),
                    data: response.data[0].success_ratio
                },
            ]

            setHeaderValues(dataSets);

            // Do something with the fetched data here
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchSelectedChallenge = async (campaignId) => {

        const token = getCookie("access_token");
        if (!token) {
            toast.error(t("error.missingAccessToken"));
            if (getUserTypeFromToken(getCookie("access_token"))) {
                navigate("/sign-in-brand");
            }
            else {
                navigate("/sign-in-realm");
            }


            return;
        }
        if (isMyTokenExpired(token)) {
            toast.error(t("error.sessionExpired"));
            if (getUserTypeFromToken(getCookie("access_token"))) {
                navigate("/sign-in-brand");
            }
            else {
                navigate("/sign-in-realm");
            }
            return;

        }

        let endpoint = apiUrl + `/api/v1/brand-admin/brand/campaign/${campaignId}`






        try {
            setLoaderState(true);
            const response = await axios.get(endpoint, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const data = response.data;
            setLoaderState(false);
            navigate('/brand/srm/corporate-campaign/create', { state: { returnedCampaign: data } });


            // Do something with the data
        } catch (error) {
            setLoaderState(false);
            toast.error(error.message);
            console.error('Error fetching data:', error);
        }
    };

    // Tarih dizesini "dd.mm.yyyy" formatına dönüştürme fonksiyonu
    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('.').map(Number);
        return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`;
    };


    const toDetailPage = (id) => {
        navigate('/brand/srm/corporate-campaign/detail', { state: { id: id } });
    }

    const fetchListData = async () => {
        setLoaderState(true);
        const url = `https://metabase.dev.soty.io/api/public/card/55cb0a35-35d5-4f61-a697-685df36416a5/query/json?parameters=[{"type":"category","target":["variable",["template-tag","brand_uuid"]],"value":"${uuid ? uuid : " "}"},{"type":"date/single","target":["variable",["template-tag","start_date"]],"value":"${filterStartDate ? filterStartDate : "2020-01-01"}"},{"type":"date/single","target":["variable",["template-tag","end_date"]],"value":"${filterEndDate ? filterEndDate : "2099-01-01"}"},{"type":"category","target":["variable",["template-tag","statusses"]],"value":"${campaignStatusFilter ? campaignStatusFilter : " "}"},{"type":"category","target":["variable",["template-tag","corporate_names"]],"value":"${corporateFilter ? corporateFilter : " "}"},{"type":"category","target":["variable",["template-tag","types"]],"value":"${campaignFilter ? campaignFilter : " "}"}]`
        try {
            const response = await axios.get(url, {});
            console.log("LIST", response)
            setCampaigns(response.data);

            const transformedData = response.data.map(item => {
                const campaign = item;

                const controlButton = () => {
                    if (!getUserTypeFromToken(getCookie("access_token")) && campaign.isactive === "false") {
                        return (
                            null
                        )
                    }
                    else if (!getUserTypeFromToken(getCookie("access_token")) && campaign.isactive === "true") {
                        return (<button className="dynamic-table-button dashboard-table-button" onClick={toDetailPage(campaign.campaign_id)}
                        >{t("showDetails")}</button>)
                    }
                    else if (getUserTypeFromToken(getCookie("access_token")) && campaign.isactive === "false") {
                        return (<button className="dynamic-table-button dashboard-table-button" onClick={() => { fetchSelectedChallenge(campaign.campaign_id) }}
                        >{t("edit")}</button>)
                    }
                    else if (getUserTypeFromToken(getCookie("access_token")) && campaign.isactive === "true") {
                        return (<button className="dynamic-table-button dashboard-table-button" onClick={() => { toDetailPage(campaign.campaign_id) }}
                        >{t("showDetails")}</button>)
                    }
                }



                return {
                    control: controlButton(),
                    id: campaign.campaign_id,
                    campaignType: t(campaign.campaign_type),
                    campaignName: campaign.campaign_name,
                    campaignImage: <img src={imagesUrl + "/" + campaign.image} alt="image" />,
                    sotierCount: campaign.participation_count,
                    paymentAmount: campaign.giro,
                    corporateName: (

                        campaign.corporate_name + " link " + "https://" + campaign.corporate_domain

                    ),
                    creationDate: campaign.created,
                    status: campaign.publish_status === "RELEASED" ? // If campaign.publishStatus is "RELEASED"
                        campaign.isactive === "true" ?           // Check if campaign.isactive is "false"
                            t("ACTIVE") :                           // If true, set status to "nonActive"
                            t("UNACTIVE") :                              // If false, set status to "ACTIVE"
                        t("DRAFTED")                                // If campaign.publishStatus is not "RELEASED", set status to "DRAFTED"

                };
            });
            setCampaignsList(transformedData);

            setLoaderState(false);

            // Do something with the fetched data here
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoaderState(false);

        }




    };

    // Call the function to fetch the data






    const checkboxData = [
        { title: t("COIN_FOLDING"), campaignType: 'COIN_FOLDING' },
        { title: t("DISCOUNT_PERCENTAGE"), campaignType: 'DISCOUNT_PERCENTAGE' },
        { title: t("SPEND_AND_EARN"), campaignType: 'SPEND_AND_EARN' },
        { title: t("COUPON_PERCENTAGE"), campaignType: 'COUPON_PERCENTAGE' },
        { title: t("FREE_SHIPMENT"), campaignType: 'FREE_SHIPMENT' },
        { title: t("BUY_X_PAY_Y"), campaignType: 'BUY_X_PAY_Y' },
        { title: t("BUY_MORE_PAY_LESS"), campaignType: 'BUY_MORE_PAY_LESS' },

    ];
    const checkboxData2 = [

        { title: t("DRAFTED"), status: "DRAFTED" },
        { title: t("RELEASED"), status: "RELEASED" },
    ]
















    function formatDateStringLocal(inputDate) {
        if (inputDate) {
            const date = new Date(inputDate);
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
            return formattedDate;
        }
        else {
            return ""
        }

    }



    const campaignColumns = [
        { title: t('control'), field: "control", className: "" },
        { title: t('id'), field: "id", className: "" },
        { title: t('campaignName'), field: "campaignName", className: "" },
        { title: t('campaignType'), field: "campaignType", className: "" },
        { title: t('campaignImage'), field: "campaignImage", className: "" },
        { title: t('corporateName'), field: "corporateName", className: "" },
        { title: t('sotierCount'), field: "sotierCount", className: "" },
        { title: t('paymentAmount'), field: "paymentAmount", className: "" },
        { title: t('creationDate'), field: "creationDate", className: "" },
        { title: t('Status'), field: "status", className: "" },
    ];






    const handleViewSelect = (viewType) => {
        if (viewType === 'LIST') {

            setViewType('LIST');
            // Burada list görünümüne geçilecek işlemleri yapabilirsiniz.
        } else if (viewType === 'GRID') {

            setViewType('GRID');
            // Burada grid görünümüne geçilecek işlemleri yapabilirsiniz.
        }
    };








    const handleFilterChange = (e) => {
        if (!e || e.length === 0) {
            setCorporateFilter("")
            setCampaignFilter("");
            setCampaignStatusFilter("");
        } else {
            const campaignTypes = e.filter(item => item.campaignType).map(item => item.campaignType).join(',');
            const statuses = e.filter(item => item.status).map(item => item.status).join(',');
            const corporations = e.filter(item => item.corporationName).map(item => item.corporationName).join(',');

            if (campaignTypes) {
                setCampaignFilter(campaignTypes);
            } else {
                setCampaignFilter("");
            }

            if (statuses) {
                setCampaignStatusFilter(statuses);
            } else {
                setCampaignStatusFilter("");
            }
            if (corporations) {
                setCorporateFilter(corporations);
            }
            else {
                setCorporateFilter("");
            }
        }
    }


    useEffect(() => {
        if (uuid) {
            fetchTopData();
            fetchListData();
        }

    }, [campaignFilter, filterEndDate, campaignStatusFilter, corporateFilter, uuid])




    const handleFilterDatesChanged = ({ selectedStartDate, selectedEndDate }) => {

        if (selectedEndDate) {



            const originalStartDate = new Date(selectedStartDate);
            const originalEndDate = new Date(selectedEndDate);

            originalStartDate.setHours(0, 0, 0);
            originalEndDate.setHours(23, 59, 59);

            const formattedStartDate = `${originalStartDate.getFullYear()}-${(originalStartDate.getMonth() + 1).toString().padStart(2, '0')}-${originalStartDate.getDate().toString().padStart(2, '0')}T${originalStartDate.getHours().toString().padStart(2, '0')}:${originalStartDate.getMinutes().toString().padStart(2, '0')}:${originalStartDate.getSeconds().toString().padStart(2, '0')}`;
            const formattedEndDate = `${originalEndDate.getFullYear()}-${(originalEndDate.getMonth() + 1).toString().padStart(2, '0')}-${originalEndDate.getDate().toString().padStart(2, '0')}T${originalEndDate.getHours().toString().padStart(2, '0')}:${originalEndDate.getMinutes().toString().padStart(2, '0')}:${originalEndDate.getSeconds().toString().padStart(2, '0')}`;

            setFilterStartDate(formattedStartDate);
            setFilterEndDate(formattedEndDate);
        }
        else {
            setFilterStartDate();
            setFilterEndDate();
        }


    }



    const locationArray = [
        [t("corporateCampaigns"), "/brand/srm/corporate-campaign"]
      ];


    return (
        <div className="dashboard-container">
            {loaderState && <Loader />}
            <NavigationBar dividerDisabled locationArray={locationArray} />

            <div className='dashboard-top-divider corporate-campaign-dashboard-divider' />
            <div className="dashboard-controls-container">
                <FilterButton checkboxData={checkboxData} onCheckboxSelection={handleFilterChange} eventType={"campaign"} checkboxData2={checkboxData2} listCheckbox={corporationsList} />
                <DatePickerButton onDateChange={handleFilterDatesChanged} />
            </div>

            <div className="dashboard-header">
                <div className="dashboard-header-cards-container">
                    {headerValues && headerValues.slice(0, 6).map((data, index) => (
                        <TotalCards key={index} {...data} />
                    ))}
                </div>

                <CreateCampaignButton corporateCampaign={true} />
            </div>
            <div className="dashboard-header">
                <div className="dashboard-header-cards-container">

                    {headerValues && headerValues.slice(6, 14).map((data, index) => (
                        <TotalCards key={index} {...data} />
                    ))}
                </div>
                <div className="hidden">
                    <CreateCampaignButton />
                </div>
            </div>

            <div className="dashboard-divider" />
            <div className="dashboard-challenges-wrapper">
                <div className="dashboard-challenges-controls-container">
                    <GridListSwitchButton onSelectView={handleViewSelect} />
                    <div className="dashboard-challenges-search-container">
                        <img className="dashboard-challenges-search-icon" src={SearchIcon} alt="search" />
                        <input className="dashboard-challenges-search" type="text" placeholder="search" />
                    </div>

                </div>
            </div>
            <div className="dashboard-challenges-container ">

                {viewType === 'GRID' && campaignColumns && campaignsList && (
                    Array.isArray(campaigns) ? (
                        <div className="dashboard-challenges-grid-container">
                            {campaigns.map((data, index) => (
                                <CampaignCard
                                    key={index}
                                    campaignData={data}
                                />
                            ))}
                        </div>
                    ) : (
                        <div>
                            {console.log(campaigns)}
                            <p>Henüz kampanya yok.</p>
                        </div>
                    )

                )}

                {viewType === 'LIST' && campaignColumns && campaignsList && (
                    <div>
                        <DynamicTable
                            columns={campaignColumns}
                            data={campaignsList}
                            tableName={t('corporateCampaignTable')}
                            corporateCampaign={true}
                        />
                    </div>
                )}

            </div>

        </div>
    )
}
export default CampaignDashboard;