import React, { useState, useEffect } from "react";
import infoIconEmpty from "../../../../../../assets/media/infoIconEmpty.svg";
import ChallengeImageDropzone from "../../../../../dropzone/ChallengeImageDropzone";
import "../../../../../../assets/css/InteractionCreation.css";
import arrow from "../../../../../../assets/media/arrow.png";
import downArrow from "../../../../../../assets/media/down-arrow.png";
import { getCookie } from "../../../../auth/tokenUtils";
import { useNavigate, useLocation } from "react-router-dom";
import { isMyTokenExpired } from "../../../../auth/tokenUtils";
import tick from "../../../../../../assets/media/tickInteraction.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { apiUrl, imagesUrl } from "../../../../../../config";
import moment from "moment";
import { useTranslation } from "react-i18next";
import noImageToShow from "../../../../../../assets/media/placeholder.png";
import PhoneViewJoin from "../../../../../phone/phoneViewJoin";
import Loader from "../../../../../popup/loader";
import DoubleSidedArrow from "../../../../../../assets/media/double-horizontal-arrow.png";
import CallAPI from "../../../../../api/callApi";
import Pagination from "../../../../../tables/Pagination";
import ItemsPerPageButton from "../../../../../button/itemsPerPageButton";
import { Tooltip } from "react-tippy";
import SegmentPopup from "../../../../../popup/segmentPopup";
import NavigationBar from "../../../../../utils/navigation_bar/navigationBar";
import ApprovalPopup from "../../../../../popup/approvalPopup";
const CampaignCreation = () => {
  const [loaderState, setLoaderState] = useState(false);

  const location = useLocation();
  const { returnedCampaign } = location.state || {};
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [popupSegment, setPopupSegment] = useState(false);

  //validations
  const [isNameValid, setIsNameValid] = useState(true);
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);
  const [isImageValid, setIsImageValid] = useState(true);
  const [isStartDateValid, setIsStartDateValid] = useState(true);
  const [isEndDateValid, setIsEndDateValid] = useState(true);
  const [checked, setChecked] = useState(false);

  const { t, i18n } = useTranslation("challengeCreation");

  //datas to send
  const [imageResponseString, setImageResponseString] = useState("");
  const [challengeName, setChallengeName] = useState("");
  const [challengeDescription, setChallengeDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [freeShipping, setFreeShipping] = useState(false);

  const [discounts, setDiscounts] = useState([0]);
  const [checkedProvider, setCheckedProvider] = useState("");

  const [challengeImage, setChallengeImage] = useState(null);

  const [segments, setSegments] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(false);
  const [segmentPage, setSegmentPage] = useState(0);
  const [segmentTotalPage, setSegmentTotalPage] = useState(false);
  const [segmentPerPage, setSegmentPerPage] = useState(20);
  const [segmentTotalCount, setSegmentTotalCount] = useState(false);

  const [online, setOnline] = useState(false);
  const [shop, setShop] = useState(false);

  const [campaignTypeCoinFolding, setCampaignTypeCoinFolding] = useState(false);
  const [campaignTypePercentageDiscount, setCampaignTypePercentageDiscount] =
    useState(false);
  const [campaignTypePercentageCoupon, setCampaignTypePercentageCoupon] =
    useState(false);
  const [campaignTypeXPriceToY, setCampaignTypeXPriceToY] = useState(false);
  const [campaignTypeFreeShipping, setCampaignTypeFreeShipping] =
    useState(false);
  const [campaignTypeXProduct, setCampaignTypeXProduct] = useState(false);
  const [campaignTypeXBuyYSpend, setCampaignTypeXBuyYSpend] = useState(false);
  const [sendInApp, setSendInApp] = useState(false);
  const [sendSms, setSendSms] = useState(false);
  const [sendMail, setSendMail] = useState(false);

  const [x2, setX2] = useState(false);
  const [x3, setX3] = useState(false);
  const [x4, setX4] = useState(false);
  const [x5, setX5] = useState(false);
  const [customFolding, setCustomFolding] = useState(false);
  const [foldingString, setFoldingString] = useState(false);

  const [xProductCount, setXProductCount] = useState(1);

  const [campaignTypeXBuyYSpendCustom, setCampaignTypeXBuyYSpendCustom] =
    useState(false);
  const [buy2Spend1, setBuy2Spend1] = useState(false);
  const [buy3Spend2, setBuy3Spend2] = useState(false);
  const [buy4Spend3, setBuy4Spend3] = useState(false);
  const [buySpendCustomBuy, setBuySpendCustomBuy] = useState(false);
  const [buySpendCustomSpend, setBuySpendCustomSpend] = useState(false);
  const [buyCountText, setBuyCountText] = useState(false);
  const [payCountText, setPayCountText] = useState(false);

  const [percent5, setPercent5] = useState(false);
  const [percent10, setPercent10] = useState(false);
  const [percent15, setPercent15] = useState(false);
  const [percent20, setPercent20] = useState(false);
  const [percent30, setPercent30] = useState(false);
  const [customPercent, setCustomPercent] = useState(false);
  const [percentString, setPercentString] = useState(false);

  const [percentCoupon5, setPercentCoupon5] = useState(false);
  const [percentCoupon10, setPercentCoupon10] = useState(false);
  const [percentCoupon15, setPercentCoupon15] = useState(false);
  const [percentCoupon20, setPercentCoupon20] = useState(false);
  const [percentCoupon30, setPercentCoupon30] = useState(false);
  const [customPercentCoupon, setCustomPercentCoupon] = useState(false);
  const [percentCouponString, setPercentCouponString] = useState(false);

  const [xPrice, setXPrice] = useState(false);
  const [yCoin, setYCoin] = useState(false);

  const [minimumSpending, setMinimumSpending] = useState(false);

  const [singleParticipate, setSingleParticipate] = useState(false);

  useEffect(() => {
    handleFetchSegments();
  }, []);
  useEffect(() => {
    handleFetchSegments();
  }, [segmentPage, segmentPerPage]);
  useEffect(() => {
    console.log(customPercentCoupon);
  }, [customPercentCoupon]);

  useEffect(() => {
    if (returnedCampaign) {
      const responseData = returnedCampaign.responseData || {};
      console.log(returnedCampaign);
      setSingleParticipate(responseData.singleParticipate || "");
      setImageResponseString(responseData.image || "");
      setSendSms(responseData.smsNotificationEnabled);
      setSendMail(responseData.emailNotificationEnabled);
      setSendInApp(responseData.inAppNotificationEnabled);
      setChallengeName(responseData.name || "");
      setChallengeImage(imagesUrl + "/" + responseData.image);
      setChallengeDescription(responseData.description || "");
      setStartDate(
        moment.utc(responseData.startDate).format("YYYY-MM-DDTHH:mm:ss") || ""
      );
      setEndDate(
        moment.utc(responseData.endDate).format("YYYY-MM-DDTHH:mm:ss") || ""
      );
      if (responseData.campaignType === "BUY_X_PAY_Y") {
        handleCampaingTypeBuyXPayY();
        if (responseData.buyCount === 2 && responseData.payCount === 1) {
          handleBuy2Spend1();
        } else if (responseData.buyCount === 3 && responseData.payCount === 2) {
          handleBuy3Spend2();
        } else if (responseData.buyCount === 4 && responseData.payCount === 3) {
          handleBuy4Spend3();
        } else {
          setCampaignTypeXBuyYSpendCustom(true);
          setBuySpendCustomBuy(responseData.buyCount);
          setBuySpendCustomSpend(responseData.payCount);
        }
      } else if (responseData.campaignType === "FREE_SHIPMENT") {
        setCampaignTypeFreeShipping(true);
        setFreeShipping(true);
      } else if (responseData.campaignType === "BUY_MORE_PAY_LESS") {
        handleCampaignTypeXProduct();
        setDiscounts(responseData.discounts);
      } else if (responseData.campaignType === "COIN_FOLDING") {
        handleCampaignTypeCoinFolding();
        if (responseData.foldingFactor === 2) {
          handleX2();
        } else if (responseData.foldingFactor === 3) {
          handleX3();
        } else if (responseData.foldingFactor === 4) {
          handleX4();
        } else if (responseData.foldingFactor === 5) {
          handleX5();
        } else {
          setCustomFolding(responseData.foldingFactor);
        }
      } else if (responseData.campaignType === "DISCOUNT_PERCENTAGE") {
        handleCampaignTypePercentageDiscount();
        if (responseData.discountPercentage === 5) {
          handlePercent5();
        } else if (responseData.discountPercentage === 10) {
          handlePercent10();
        } else if (responseData.discountPercentage === 15) {
          handlePercent15();
        } else if (responseData.discountPercentage === 20) {
          handlePercent20();
        } else if (responseData.discountPercentage === 30) {
          handlePercent30();
        } else {
          setCustomPercent(responseData.discountPercentage);
        }
      } else if (responseData.campaignType === "COUPON_PERCENTAGE") {
        handleCampaignTypePercentageCoupon();
        if (responseData.couponRatio === 5) {
          handlePercentCoupon5();
        } else if (responseData.couponRatio === 10) {
          handlePercentCoupon10();
        } else if (responseData.couponRatio === 15) {
          handlePercentCoupon15();
        } else if (responseData.couponRatio === 20) {
          handlePercentCoupon20();
        } else if (responseData.couponRatio === 30) {
          handlePercentCoupon30();
        } else {
          setCustomPercentCoupon(responseData.couponRatio);
        }
      } else if (responseData.campaignType === "SPEND_AND_EARN") {
        handleCampaignTypeXPriceToY();
        setXPrice(responseData.spend);
        setYCoin(responseData.earn);
      }
      //store ve online ikisi ayni anda secilebiliyo
      if (responseData.shoppingChannel === "ALL") {
        handleShopChange();
        handleOnlineChange();
      } else if (responseData.shoppingChannel === "STORE") {
        handleShopChange();
      } else if (responseData.shoppingChannel === "ONLINE") {
        handleOnlineChange();
      }
      if (responseData.minimumSpending) {
        setMinimumSpending(responseData.minimumSpending);
      }
    }
  }, [returnedCampaign]);
  useEffect(() => {
    if (!(!customFolding || customFolding === "")) {
      setX2(false);
      setX3(false);
      setX4(false);
      setX5(false);
      setFoldingString("X" + customFolding + " Coin");
    } else {
      setFoldingString();
    }
  }, [customFolding]);
  useEffect(() => {
    if (buySpendCustomBuy || buySpendCustomSpend) {
      setPayCountText(buySpendCustomSpend);
      setBuyCountText(buySpendCustomBuy);
    }
  }, [buySpendCustomBuy, buySpendCustomSpend]);

  useEffect(() => {
    if (!(!customPercent || customPercent === "")) {
      setPercent5(false);
      setPercent10(false);
      setPercent15(false);
      setPercent20(false);
      setPercent30(false);
      setPercentString("%" + customPercent);
    } else {
      setPercentString();
    }
  }, [customPercent]);

  useEffect(() => {
    if (!(!customPercentCoupon || customPercentCoupon === "")) {
      setPercentCoupon5(false);
      setPercentCoupon10(false);
      setPercentCoupon15(false);
      setPercentCoupon20(false);
      setPercentCoupon30(false);
      setPercentCouponString("%" + customPercentCoupon);
    } else {
      setPercentCouponString();
    }
  }, [customPercentCoupon]);

  useEffect(() => {
    if (!campaignTypePercentageCoupon) {
      setPercentCoupon5(false);
      setPercentCoupon10(false);
      setPercentCoupon15(false);
      setPercentCoupon20(false);
      setPercentCoupon30(false);
      setCustomPercentCoupon(false);
    }
  }, [campaignTypePercentageCoupon]);

  const handlePercent5 = () => {
    setPercent5(true);
    setPercent10(false);
    setPercent15(false);
    setPercent20(false);
    setPercent30(false);
    setCustomPercent(false);
    setPercentString("%5");
  };

  const handlePercent10 = () => {
    setPercent5(false);
    setPercent10(true);
    setPercent15(false);
    setPercent20(false);
    setPercent30(false);
    setCustomPercent(false);
    setPercentString("%10");
  };

  const handlePercent15 = () => {
    setPercent5(false);
    setPercent10(false);
    setPercent15(true);
    setPercent20(false);
    setPercent30(false);
    setCustomPercent(false);
    setPercentString("%15");
  };

  const handlePercent20 = () => {
    setPercent5(false);
    setPercent10(false);
    setPercent15(false);
    setPercent20(true);
    setPercent30(false);
    setCustomPercent(false);
    setPercentString("%20");
  };

  const handlePercent30 = () => {
    setPercent5(false);
    setPercent10(false);
    setPercent15(false);
    setPercent20(false);
    setPercent30(true);
    setCustomPercent(false);
    setPercentString("%30");
  };

  const handlePercentsFalse = () => {
    setPercent5(false);
    setPercent10(false);
    setPercent15(false);
    setPercent20(false);
    setPercent30(false);
    setCustomPercent(false);
    setPercentString(false);
  };
  const handleXPriceToYFalse = () => {
    setXPrice(false);
    setYCoin(false);
  };

  const handlePercentCoupon5 = () => {
    setPercentCoupon5(true);
    setPercentCoupon10(false);
    setPercentCoupon15(false);
    setPercentCoupon20(false);
    setPercentCoupon30(false);
    setCustomPercentCoupon(false);
    setPercentCouponString("%5");
  };

  const handlePercentCoupon10 = () => {
    setPercentCoupon5(false);
    setPercentCoupon10(true);
    setPercentCoupon15(false);
    setPercentCoupon20(false);
    setPercentCoupon30(false);
    setCustomPercentCoupon(false);
    setPercentCouponString("%10");
  };

  const handlePercentCoupon15 = () => {
    setPercentCoupon5(false);
    setPercentCoupon10(false);
    setPercentCoupon15(true);
    setPercentCoupon20(false);
    setPercentCoupon30(false);
    setCustomPercentCoupon(false);
    setPercentCouponString("%15");
  };

  const handlePercentCoupon20 = () => {
    setPercentCoupon5(false);
    setPercentCoupon10(false);
    setPercentCoupon15(false);
    setPercentCoupon20(true);
    setPercentCoupon30(false);
    setCustomPercentCoupon(false);
    setPercentCouponString("%20");
  };

  const handlePercentCoupon30 = () => {
    setPercentCoupon5(false);
    setPercentCoupon10(false);
    setPercentCoupon15(false);
    setPercentCoupon20(false);
    setPercentCoupon30(true);
    setCustomPercentCoupon(false);
    setPercentCouponString("%30");
  };

  const handlePercentsCouponFalse = () => {
    setPercentCoupon5(false);
    setPercentCoupon10(false);
    setPercentCoupon15(false);
    setPercentCoupon20(false);
    setPercentCoupon30(false);
    setCustomPercentCoupon(false);
    setPercentCouponString(false);
  };
  const handleFoldingsFalse = () => {
    setX2(false);
    setX3(false);
    setX4(false);
    setX5(false);
    setCustomFolding(false);
    setFoldingString(false);
  };
  const handleX2 = () => {
    setX2(true);
    setX3(false);
    setX4(false);
    setX5(false);
    setCustomFolding(false);
    setFoldingString("X2 Coin");
  };
  const handleX3 = () => {
    setX2(false);
    setX3(true);
    setX4(false);
    setX5(false);
    setCustomFolding(false);
    setFoldingString("X3 Coin");
  };
  const handleX4 = () => {
    setX2(false);
    setX3(false);
    setX4(true);
    setX5(false);
    setCustomFolding(false);
    setFoldingString("X4 Coin");
  };
  const handleX5 = () => {
    setX2(false);
    setX3(false);
    setX4(false);
    setX5(true);
    setCustomFolding(false);
    setFoldingString("X5 Coin");
  };

  const handleBuy2Spend1 = () => {
    setBuy2Spend1(true);
    setBuy3Spend2(false);
    setBuy4Spend3(false);
    setCampaignTypeXBuyYSpendCustom(false);
    setBuyCountText("2");
    setPayCountText("1");
  };
  const handleBuy3Spend2 = () => {
    setBuy2Spend1(false);
    setBuy3Spend2(true);
    setBuy4Spend3(false);
    setCampaignTypeXBuyYSpendCustom(false);
    setBuyCountText("3");
    setPayCountText("2");
  };
  const handleBuy4Spend3 = () => {
    setBuy2Spend1(false);
    setBuy3Spend2(false);
    setBuy4Spend3(true);
    setCampaignTypeXBuyYSpendCustom(false);
    setBuyCountText("4");
    setPayCountText("3");
  };
  const handleCampaignTypeXBuyYSpendCustom = () => {
    setBuy2Spend1(false);
    setBuy3Spend2(false);
    setBuy4Spend3(false);
    setCampaignTypeXBuyYSpendCustom(true);
    setBuyCountText(false);
    setPayCountText(false);
  };

  useEffect(() => {
    if (!campaignTypeXBuyYSpend && !returnedCampaign) {
      setBuyCountText();
      setPayCountText();
      setBuySpendCustomSpend(false);
      setBuySpendCustomBuy(false);
      setCampaignTypeXBuyYSpendCustom(false);
      setBuy2Spend1(false);
      setBuy3Spend2(false);
      setBuy4Spend3(false);
    }
  }, [campaignTypeXBuyYSpend]);

  useEffect(() => {
    if (shop) {
      setCampaignTypeFreeShipping(false);
      setFreeShipping(false);
    }
  }, [shop]);

  const handleCampaingTypeBuyXPayY = () => {
    setCampaignTypeCoinFolding(false);
    setCampaignTypePercentageDiscount(false);
    setCampaignTypeXPriceToY(false);
    setCampaignTypeFreeShipping(false);
    setCampaignTypeXProduct(false);
    setCampaignTypeXBuyYSpend(true);
    setCampaignTypePercentageCoupon(false);
    setCampaignTypeFreeShipping(false);
    setFreeShipping(false);
    handleFoldingsFalse();
    handlePercentsFalse();
    handlePercentsCouponFalse();
    handleXPriceToYFalse();
  };

  const handleCampaignTypeCoinFolding = () => {
    setCampaignTypeCoinFolding(true);
    setCampaignTypePercentageDiscount(false);
    setCampaignTypeXPriceToY(false);
    setCampaignTypeFreeShipping(false);
    setCampaignTypeXProduct(false);
    setCampaignTypeXBuyYSpend(false);
    setCampaignTypePercentageCoupon(false);
    setCampaignTypeFreeShipping(false);
    setFreeShipping(false);
    handlePercentsFalse();
    handlePercentsCouponFalse();
    handleXPriceToYFalse();
  };

  const handleCampaignTypePercentageDiscount = () => {
    setCampaignTypeCoinFolding(false);
    setCampaignTypePercentageDiscount(true);
    setCampaignTypeXPriceToY(false);
    setCampaignTypeFreeShipping(false);
    setCampaignTypeXProduct(false);
    setCampaignTypeXBuyYSpend(false);
    setCampaignTypePercentageCoupon(false);
    setCampaignTypeFreeShipping(false);
    setFreeShipping(false);
    handleFoldingsFalse();
    handlePercentsCouponFalse();
    handleXPriceToYFalse();
  };

  const handleCampaignTypePercentageCoupon = () => {
    setCampaignTypeCoinFolding(false);
    setCampaignTypePercentageDiscount(false);
    setCampaignTypePercentageCoupon(true);
    setCampaignTypeXPriceToY(false);
    setCampaignTypeFreeShipping(false);
    setCampaignTypeXProduct(false);
    setCampaignTypeXBuyYSpend(false);
    setCampaignTypeFreeShipping(false);
    setFreeShipping(false);
    handleFoldingsFalse();
    handlePercentsFalse();
    handleXPriceToYFalse();
  };

  const handleCampaignTypeXPriceToY = () => {
    setCampaignTypeCoinFolding(false);
    setCampaignTypePercentageDiscount(false);
    setCampaignTypeXPriceToY(true);
    setCampaignTypeFreeShipping(false);
    setCampaignTypeXProduct(false);
    setCampaignTypeXBuyYSpend(false);
    setCampaignTypePercentageCoupon(false);
    setCampaignTypeFreeShipping(false);
    setFreeShipping(false);
    handleFoldingsFalse();
    handlePercentsFalse();
    handlePercentsCouponFalse();
  };

  const handleCampaignTypeFreeShipping = () => {
    setCampaignTypeCoinFolding(false);
    setCampaignTypePercentageDiscount(false);
    setCampaignTypeXPriceToY(false);
    setCampaignTypeFreeShipping(true);
    setCampaignTypeXProduct(false);
    setCampaignTypeXBuyYSpend(false);
    setCampaignTypePercentageCoupon(false);
    setFreeShipping(true);
    handleFoldingsFalse();
    handlePercentsFalse();
    handlePercentsCouponFalse();
    handleXPriceToYFalse();
  };

  const handleCampaignTypeXProduct = () => {
    setCampaignTypeCoinFolding(false);
    setCampaignTypePercentageDiscount(false);
    setCampaignTypeXPriceToY(false);
    setCampaignTypeFreeShipping(false);
    setCampaignTypeXProduct(true);
    setCampaignTypeXBuyYSpend(false);
    setCampaignTypePercentageCoupon(false);
    setCampaignTypeFreeShipping(false);
    setFreeShipping(false);
    handleFoldingsFalse();
    handlePercentsFalse();
    handlePercentsCouponFalse();
    handleXPriceToYFalse();
  };

  const handleCampaignTypeXBuyYSpend = () => {
    setCampaignTypeCoinFolding(false);
    setCampaignTypePercentageDiscount(false);
    setCampaignTypeXPriceToY(false);
    setCampaignTypeFreeShipping(false);
    setCampaignTypeXProduct(false);
    setCampaignTypeXBuyYSpend(true);
    setCampaignTypePercentageCoupon(false);
    setCampaignTypeFreeShipping(false);
    setFreeShipping(false);
    handleFoldingsFalse();
    handlePercentsFalse();
    handlePercentsCouponFalse();
    handleXPriceToYFalse();
  };

  const handleOnlineChange = () => {
    setOnline(!online);
  };

  const handleShopChange = () => {
    setShop(!shop);
  };

  useEffect(() => {
    console.log(sendSms, sendMail, sendInApp);
  }, [sendSms, sendMail, sendInApp]);

  const challengeNameChange = (e) => {
    setChallengeName(e.target.value);
  };

  const handleInputChange = (value, setter) => {
    setter(value);
  };

  const handleImageUpload = async (file, isChallengeImage) => {
    setLoaderState(true);

    try {
      // Get the access token from the cookie
      const authToken = getCookie("access_token");

      // Create a FormData object to send the file
      const formData = new FormData();

      // Convert Blob URL to Blob data
      const response = await fetch(file);
      const blobData = await response.blob();

      // Append Blob data to FormData
      formData.append("file", blobData);

      // Determine the URL based on whether it's a challenge or a question image
      const uploadUrl = isChallengeImage
        ? apiUrl + "/api/v1/brand-admin/brand/challenge/logo"
        : apiUrl + "/api/v1/brand-admin/brand/challenge/logo"; // Replace with the actual URL

      // Determine the state and toast message based on whether it's a challenge or a question image
      const stateToUpdate = isChallengeImage && setImageResponseString;
      const successMessage =
        isChallengeImage && t("success.uploadChallengeImage");

      // Use Axios to send the POST request to upload the file
      const uploadResponse = await axios.post(uploadUrl, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      stateToUpdate(uploadResponse.data.responseData);

      // İşlem başarıyla tamamlandığında state'i güncelleyebilirsiniz
      setChallengeImage(file);
      setLoaderState(false);

      toast.success(successMessage);
    } catch (error) {
      setLoaderState(false);

      console.error("Dosya yüklenirken hata oluştu:", error);
      setChallengeImage("");
      toast.error(
        t("error.fileUpload") +
          "(" +
          error.response?.data?.metadata?.message +
          ")"
      );
    }
  };

  const handleFetchSegments = async () => {
    setLoaderState(true);
    const segmentResponse = await CallAPI({
      method: "GET", // HTTP isteği türü
      endPoint:
        apiUrl +
        `/api/v1/brand-admin/brand/segment?page=${segmentPage}&size=${segmentPerPage}`, // API URL'si
      body: undefined, // İstek gövdesi
      successMessage: undefined, // Başarılı mesaj (isteğe bağlı)
      errorMessage: t("errorMessage"), // Hata mesajı
      navigate: navigate, // Yönlendirme fonksiyonu
      t: t, // Çeviri işlevi
    });
    setLoaderState(false);
    console.log(segmentResponse);
    setSegmentTotalPage(segmentResponse.data.pageMetadata.totalPage);
    setSegmentPage(segmentResponse.data.pageMetadata.page);
    setSegmentTotalCount(segmentResponse.data.pageMetadata.count);
    console.log(segmentResponse.data.responseData);

    // allSotiers'ı en başa ekleyelim
    const updatedSegments = [
      {
        id: 0,
        name: t("allSotiers"),
        description: t("segmentForAllUsers"),
        expressionText: `=${t("allSotiers")}`,
      },
      ...segmentResponse.data.responseData,
    ];

    setSegments(updatedSegments);
  };

  const startDateChange = (e) => {
    const startDate = e.target.value;
    if (startDate === "") {
      toast.error(t("error.missingStartDate"));
      return;
    }

    // Tarihten "Z" harfini kaldırın
    const formattedStartDate = new Date(startDate).toISOString().slice(0, 19);
    const selectedStartDate = new Date(startDate);
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    if (selectedStartDate < now) {
      toast.error(t("error.invalidStartDate"));
      e.target.value = "";
      handleInputChange("", setStartDate, "startDate");
      return;
    } else {
      setStartDate(formattedStartDate);
      handleInputChange(formattedStartDate, setStartDate, "startDate");
    }
  };

  const endDateChange = (e) => {
    const newEndDate = e.target.value;
    if (newEndDate === "") {
      toast.error(t("error.missingEndDate"));
      handleInputChange("", setEndDate, "endDate");
      return;
    }

    // Tarihten "Z" harfini kaldırın
    const formattedEndDate = new Date(newEndDate).toISOString().slice(0, 19);
    if (new Date(formattedEndDate) < new Date(startDate)) {
      toast.error(t("error.invalidEndDate"));
      e.target.value = "";
      handleInputChange("", setEndDate, "endDate");
    } else {
      setEndDate(formattedEndDate);
      handleInputChange(formattedEndDate, setEndDate, "endDate");
    }
  };

  const handleCampaignUpload = async (previewBool) => {
    if (sendSms === false && sendMail === false && sendInApp === false) {
      toast.error(t("error.anySendingTypeSelected"));
      return;
    }
    if (selectedSegment === undefined) {
      toast.error(t("error.anySegmentSelected"));
      return;
    }
    if (!online && !shop) {
      toast.error(t("error.anyShoppingChannelSelected"));
      return;
    }
    let shoppingChannel;
    if (shop && online) {
      shoppingChannel = "ALL";
    } else if (online) {
      shoppingChannel = "ONLINE";
    } else if (shop) {
      shoppingChannel = "STORE";
    }

    if (
      !campaignTypeCoinFolding &&
      !campaignTypeFreeShipping &&
      !campaignTypePercentageCoupon &&
      !campaignTypePercentageDiscount &&
      !campaignTypeXBuyYSpend &&
      !campaignTypeXPriceToY &&
      !campaignTypeXProduct &&
      !freeShipping
    ) {
      toast.error(t("error.anyCampaignSelected"));
      return;
    }

    let freeShippingBool;
    let campaignType;
    if (campaignTypeCoinFolding) {
      campaignType = "COIN_FOLDING";
    }
    if (campaignTypeFreeShipping) {
      campaignType = "FREE_SHIPMENT";
      if (
        minimumSpending === 0 ||
        minimumSpending === "0" ||
        minimumSpending === undefined ||
        minimumSpending === null ||
        minimumSpending === false
      ) {
        toast.error(t("pleaseEnterAMinimumSpendingValue"));

        return;
      }
      freeShippingBool = true;
    }
    if (campaignTypePercentageDiscount) {
      campaignType = "DISCOUNT_PERCENTAGE";
    }
    if (campaignTypePercentageCoupon) {
      campaignType = "COUPON_PERCENTAGE";
    }
    if (campaignTypeXBuyYSpend) {
      campaignType = "X_BUY_Y_SPEND";
    }
    if (campaignTypeXPriceToY) {
      campaignType = "SPEND_AND_EARN";
    }
    let discountsArray;
    if (campaignTypeXProduct) {
      // Update discounts to replace empty strings with 0
      const updatedDiscounts = discounts.map(discount => 
        discount === "" ? 0 : discount
      );
    
      if (
        updatedDiscounts.every(
          (discount) =>
            discount === 0 || discount === "0" || discount === undefined
        )
      ) {
        toast.error(t("please_enter_at_least_one_discount"));
        return;
      }
    
      campaignType = "BUY_MORE_PAY_LESS";
      discountsArray = updatedDiscounts;
      console.log(discountsArray);
    }
    

    if (campaignTypeXBuyYSpend) {
      campaignType = "BUY_X_PAY_Y";
    }
    let SegmentToSend;

    let buyCount;
    let payCount;
    if (campaignTypeXBuyYSpend) {
      if (buy2Spend1) {
        buyCount = 2;
        payCount = 1;
      } else if (buy3Spend2) {
        buyCount = 3;
        payCount = 2;
      } else if (buy4Spend3) {
        buyCount = 4;
        payCount = 3;
      } else {
        if (!buySpendCustomSpend) {
          toast.error(t("PaymentItemCountError"));
          return;
        }
        if (!buySpendCustomBuy) {
          toast.error(t("ItemPurchaseQuantityError"));
          return;
        }

        buyCount = buySpendCustomBuy;
        payCount = buySpendCustomSpend;
      }
    }

    let foldingOption;
    if (campaignTypeCoinFolding) {
      if (
        !x2 &&
        !x3 &&
        !x4 &&
        !x5 &&
        (!customFolding || customFolding === "")
      ) {
        toast.error(t("error.anyFoldingOptionSelected"));
        return;
      }
      if (x2) {
        foldingOption = 2;
      }
      if (x3) {
        foldingOption = 3;
      }
      if (x4) {
        foldingOption = 4;
      }
      if (x5) {
        foldingOption = 5;
      }
      if (customFolding) {
        foldingOption = customFolding;
      }
    }
    let PercentageDiscount;
    if (campaignTypePercentageDiscount) {
      console.log(
        percent5,
        percent10,
        percent15,
        percent20,
        percent30,
        customPercent
      );
      if (
        !percent5 &&
        !percent10 &&
        !percent15 &&
        !percent20 &&
        !percent30 &&
        (!customPercent || customPercent === "")
      ) {
        toast.error(t("error.anyPercentOptionSelected"));
        return;
      }
      if (percent5) {
        PercentageDiscount = 5;
      }
      if (percent10) {
        PercentageDiscount = 10;
      }
      if (percent15) {
        PercentageDiscount = 15;
      }
      if (percent20) {
        PercentageDiscount = 20;
      }
      if (percent30) {
        PercentageDiscount = 30;
      }
      if (customPercent) {
        PercentageDiscount = customPercent;
      }
    }
    let PercentageCoupon;
    if (campaignTypePercentageCoupon) {
      if (
        !percentCoupon5 &&
        !percentCoupon10 &&
        !percentCoupon15 &&
        !percentCoupon20 &&
        !percentCoupon30 &&
        (!customPercentCoupon || customPercentCoupon === "")
      ) {
        toast.error(t("error.anyPercentOptionSelected"));
        return;
      }
      if (percentCoupon5) {
        PercentageCoupon = 5;
      }
      if (percentCoupon10) {
        PercentageCoupon = 10;
      }
      if (percentCoupon15) {
        PercentageCoupon = 15;
      }
      if (percentCoupon20) {
        PercentageCoupon = 20;
      }
      if (percentCoupon30) {
        PercentageCoupon = 30;
      }
      if (customPercentCoupon) {
        PercentageCoupon = customPercentCoupon;
      }
    }

    let spend;
    let earn;
    if (campaignTypeXPriceToY) {
      if ((!xPrice || xPrice === "") && (!yCoin || yCoin === "")) {
        toast.error(t("xPriceYCoinMissing")); //eksik kodla dili degistir localization
        return;
      }
      spend = xPrice;
      earn = yCoin;
    }

    if (!startDate) {
      setIsStartDateValid(false);
      toast.error(t("error.missingStartDate"));
      return;
    }
    setIsStartDateValid(true);

    if (!endDate) {
      setIsEndDateValid(false);
      toast.error(t("error.missingEndDate"));
      return;
    }
    setIsEndDateValid(true);

    const startTime = new Date(startDate);
    const endTime = new Date(endDate);
    const timeDifference = endTime - startTime;

    if (timeDifference < 60000) {
      toast.error(t("error.invalidTimeRange"));
      return;
    }

    const authToken = getCookie("access_token");
    if (!authToken) {
      console.error("Access token is missing.");
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(authToken)) {
      toast.error(t("error.sessionExpired"));
      navigate("/sign-in-brand");
    }

    const campaignData = {
      image: imageResponseString,
      name: challengeName,
      description: challengeDescription,
      startDate: startDate,
      endDate: endDate,
      foldingFactor: foldingOption ? foldingOption : undefined,
      campaignType: campaignType,
      shoppingChannel: shoppingChannel,
      minimumSpending: minimumSpending ? minimumSpending : undefined,
      discountPercentage: PercentageDiscount ? PercentageDiscount : undefined,
      segment: selectedSegment === 0 ? null : selectedSegment,
      couponRatio: PercentageCoupon,
      earn: earn,
      spend: spend,
      payCount: payCount,
      buyCount: buyCount,
      freeShipping: freeShippingBool,
      discounts: discountsArray,
      inAppNotificationEnabled: sendInApp,
      emailNotificationEnabled: sendMail,
      smsNotificationEnabled: sendSms,
      singleParticipate: singleParticipate,
    };
    console.log("Kampanya");
    console.log(campaignData);
    if (returnedCampaign) {
      setLoaderState(true);

      const endPoint =
        apiUrl +
        "/api/v1/brand-admin/brand/campaign/" +
        returnedCampaign.responseData.id;
      axios
        .put(endPoint, campaignData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setLoaderState(false);

            toast.success(t("success.updateCampaign")); // Başarı mesajı göster

            if (previewBool) {
              navigate("/brand/srm/campaign/preview", {
                state: { returnedApiData: response.data },
              });
            } else {
              navigate("/brand/srm/campaign");
            }

            // Başarılı sonuçları işlemek için burada kod ekleyebilirsiniz.
          }
        })
        .catch((error) => {
          setLoaderState(false);

          console.log(error);
          toast.error(
            t("error.updateCampaign") +
              "(" +
              error.response.data.metadata.message +
              ")"
          );
          // Hataları işleyin (örneğin, kullanıcıya bir hata mesajı gösterin)
        });
    } else {
      setLoaderState(true);
      const endPoint = apiUrl + "/api/v1/brand-admin/brand/campaign";
      axios
        .post(endPoint, campaignData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setLoaderState(false);

            toast.success(t("success.CreateCampaign")); // Başarı mesajı göster

            if (previewBool) {
              navigate("/brand/srm/campaign/preview", {
                state: { returnedApiData: response.data },
              });
            } else {
              navigate("/brand/challenges");
            }

            // Başarılı sonuçları işlemek için burada kod ekleyebilirsiniz.
          }
        })
        .catch((error) => {
          setLoaderState(false);

          console.log(error);
          toast.error(
            t("error.CreateCampaign") +
              "(" +
              error.response.data.metadata.message +
              ")"
          );
          // Hataları işleyin (örneğin, kullanıcıya bir hata mesajı gösterin)
        });
    }
  };

  // Handler function to update discounts
  const handleDiscountChange = (index, value) => {
    const newDiscounts = [...discounts];
    newDiscounts[index] = value;
    setDiscounts(newDiscounts);
    console.log(newDiscounts);
  };

  // Handler function to add a new discount input
  const handleAddDiscount = () => {
    setDiscounts([...discounts, 0]); // Yeni bir discount ekleyerek array'i genişlet
  };
  // Handler function to delete a discount
  const handleDeleteDiscount = (indexToRemove) => {
    setDiscounts(discounts.filter((_, index) => index !== indexToRemove));
  };

  const handleSegmentPopup = (segment) => {
    setPopupSegment(segment);
    setPopup(true);
  };

  const locationArray = [
    [t("campaigns"), "/brand/srm/campaign"],
    [t("createCampaign"), "/brand/srm/campaign/create"],
  ];

  return (
    <div className="dashboard-container">
    
      {popup && (
        <ApprovalPopup
          questionText={t("areYouSureToDeleteSegment")}
          cancelText={t("cancel")}
          approveText={t("delete")}
          onClose={() => {
            setPopup(false);
          }}
          isDeleteApprove={false}
          onCancelButtonClick={() => setPopup(false)}
          approvalObject={popupSegment}
        />
      )}

      <NavigationBar locationArray={locationArray} />
    
      {loaderState && <Loader />}
      <div className="dashboard-row">
        <div className="challenge-creation-content-container">
          {/* KAMPANYA GORSELI */}
          <div className="challenge-creation-challenge-img-container">
            <div className="challenge-creation-challenge-segment-title">
              {t("CampaignImage")}
            </div>

            <ChallengeImageDropzone
              onImageUpload={(file) => handleImageUpload(file, true)}
              defaultImageUrl={
                returnedCampaign
                  ? imagesUrl + "/" + returnedCampaign.responseData.image
                  : ""
              }
              style={{ border: isImageValid ? "" : "2px solid red" }}
            />
            <label>{t("allowedFileTypes")} png, jpg</label>
          </div>
          {/* KAMPANYA ADI */}
          <div className="challenge-creation-input-container">
            <div className="challenge-creation-input-container-header">
              <div className="challenge-creation-input-container-header-title">
                {t("CampaignName")}
                <span>*</span>:
              </div>
              <div className="challenge-creation-input-container-header-subtitle">
                {t("enterChallengeTitle")}
              </div>
            </div>
            <hr />
            <div className="challenge-creation-input-container-content-wrapper">
              <div className="challenge-title-container">
                <input
                  className="challenge-title"
                  placeholder={t("enterCampaignTitlePlaceHolder")}
                  defaultValue={challengeName}
                  maxLength="60"
                  value={challengeName}
                  onBlur={challengeNameChange}
                  onChange={(e) =>
                    handleInputChange(
                      e.target.value,
                      setChallengeName,
                      "challengeName"
                    )
                  }
                  style={{ border: isNameValid ? "" : "2px solid red" }}
                />
                <div className="challenge-title-info-container">
                  <img
                    className="challenge-title-info-icon"
                    src={infoIconEmpty}
                    alt="info:"
                  />
                  <div className="challenge-title-info-text">
                    {t("min")} 10, {t("max")} 60 {t("character")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* KAMPANYA ACIKLAMA */}
          <div className="challenge-creation-input-container">
            <div className="challenge-creation-input-container-header">
              <div className="challenge-creation-input-container-header-title">
                {t("campaignDescription")}
                <span>*</span>:
              </div>
              <div className="challenge-creation-input-container-header-subtitle">
                {t("enterCampaignDescription")}
              </div>
            </div>
            <hr />
            <div className="challenge-creation-input-container-content-wrapper">
              <div className="challenge-title-container">
                <textarea
                  className="challenge-title"
                  placeholder={t("enterCampaignDescriptionPlaceholder")}
                  maxLength="511"
                  defaultValue={challengeDescription}
                  onChange={(e) =>
                    handleInputChange(
                      e.target.value,
                      setChallengeDescription,
                      "campaignDescription"
                    )
                  }
                  style={{ border: isDescriptionValid ? "" : "2px solid red" }}
                />
                <div className="challenge-title-info-container">
                  <img
                    className="challenge-title-info-icon"
                    src={infoIconEmpty}
                    alt="info:"
                  />
                  <div className="challenge-title-info-text">
                    {t("min")} 20, {t("max")} 512 {t("character")}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* SEGMENT LISTESI */}
          <div className="challenge-creation-input-container">
            <div className="challenge-creation-input-container-header">
              <div className="challenge-creation-input-container-header-title">
                {t("SegmentList")}
                <span>*</span>:
              </div>
              <div className="challenge-creation-input-container-header-subtitle">
                {t("SegmentListSubtitle")}
              </div>
            </div>
            <hr />
            <div className="challenge-creation-input-container-content-wrapper">
              <div className="segment-list-container">
                <div className="segment-list-inner">
                  {segments &&
                    segments?.map((segment, index) => (
                      <div
                        key={index}
                        className="challenge-creation-segment-row"
                      >
                        <div
                          className={
                            selectedSegment === segment.id
                              ? "qr-checkbox checked"
                              : "qr-checkbox"
                          }
                          onClick={() => {
                            setSelectedSegment(segment.id);
                          }}
                        >
                          <img src={tick} alt="Tick" />
                        </div>

                        <div className="challenge-creation-segment-content-container">
                          <Tooltip
                            title={t("showDetails")}
                            position="bottom-start"
                            theme="light"
                            size="small"
                            delay="1000"
                          >
                            <div
                              className="challenge-creation-segment-title segment-list-segment-title outline"
                              onClick={() => {
                                handleSegmentPopup(segment);
                                console.log("SEGMENT = ", segment);
                              }}
                            >
                              {segment.name}
                            </div>
                          </Tooltip>
                          <div className="challenge-creation-segment-description">
                            {segment.description}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="segment-list-footer">
                <Pagination
                  currentPage={segmentPage + 1 || null}
                  totalPages={segmentTotalPage}
                  onPageChange={(page) => {
                    setSegmentPage(page - 1);
                  }}
                />
                <ItemsPerPageButton
                  onSelectItem={(e) => {
                    handleInputChange(e, setSegmentPerPage);
                  }}
                  title={t("segmentPerPage")}
                />
              </div>
            </div>
          </div>
          {/* KANAL TURU */}
          <div
            className={`challenge-creation-input-container ${
              returnedCampaign?.campaignType === "FREE_SHIPMENT" && "disabled"
            }`}
          >
            <div className="challenge-creation-input-container-header">
              <div className="challenge-creation-input-container-header-title">
                {t("ChannelType")}
                <span>*</span>:
              </div>
              <div className="challenge-creation-input-container-header-subtitle">
                {t("ChannelTypeDesc")}
              </div>
            </div>
            <hr />
            <div className="challenge-creation-input-container-content-wrapper">
              <div className="challenge-creation-segment-row">
                <div className="challenge-creation-channel-container">
                  <div
                    className={online ? "qr-checkbox checked" : "qr-checkbox"}
                    onClick={() => {
                      returnedCampaign?.campaignType !== "FREE_SHIPMENT" &&
                        handleOnlineChange();
                    }}
                  >
                    <img src={tick} alt="Tick" />
                  </div>
                  <div className="challenge-creation-segment-content-container">
                    <div className="challenge-creation-segment-title">
                      {t("online")}
                    </div>
                    <div className="challenge-creation-segment-description"></div>
                  </div>
                </div>
                <div className="challenge-creation-channel-container">
                  <div
                    className={shop ? "qr-checkbox checked" : "qr-checkbox"}
                    onClick={() => {
                      returnedCampaign?.campaignType !== "FREE_SHIPMENT" &&
                        handleShopChange();
                    }}
                  >
                    <img src={tick} alt="Tick" />
                  </div>
                  <div className="challenge-creation-segment-content-container">
                    <div className="challenge-creation-segment-title">
                      {t("store")}
                    </div>
                    <div className="challenge-creation-segment-description"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* KAMPANYA TURU */}
          <div
            className={`challenge-creation-input-container ${
              returnedCampaign?.campaignType && "disabled"
            }`}
          >
            <div className="challenge-creation-input-container-header">
              <div className="challenge-creation-input-container-header-title">
                {t("campaignType")}
                <span>*</span>:
              </div>
              <div className="challenge-creation-input-container-header-subtitle">
                {t("campaignTypeSubtitle")}
              </div>
            </div>
            <hr />
            <div className="challenge-creation-input-container-content-wrapper">
              <div className="challenge-creation-segment-row">
                <div
                  className={
                    campaignTypeCoinFolding
                      ? "qr-checkbox checked"
                      : "qr-checkbox"
                  }
                  onClick={() => {
                    !returnedCampaign?.campaignType &&
                      handleCampaignTypeCoinFolding();
                  }}
                >
                  <img src={tick} alt="Tick" />
                </div>
                <div className="challenge-creation-segment-content-container">
                  <div className="challenge-creation-segment-title">
                    {t("sotyCoinFolding")}
                  </div>
                  <div className="challenge-creation-segment-description"></div>
                </div>
              </div>

              <div className="challenge-creation-segment-row">
                <div
                  className={
                    campaignTypePercentageDiscount
                      ? "qr-checkbox checked"
                      : "qr-checkbox"
                  }
                  onClick={() => {
                    !returnedCampaign?.campaignType &&
                      handleCampaignTypePercentageDiscount();
                  }}
                >
                  <img src={tick} alt="Tick" />
                </div>
                <div className="challenge-creation-segment-content-container">
                  <div className="challenge-creation-segment-title">
                    {t("givePercentageDiscount")}
                  </div>
                  <div className="challenge-creation-segment-description"></div>
                </div>
              </div>
              <div
                className={`challenge-creation-segment-row ${
                  online === true && shop === false ? " " : "disabled"
                }`}
              >
                <div
                  className={
                    freeShipping ? "qr-checkbox checked" : "qr-checkbox"
                  }
                  onClick={() => {
                    !returnedCampaign?.campaignType &&
                      online === true &&
                      shop === false &&
                      handleCampaignTypeFreeShipping();
                  }}
                >
                  <img src={tick} alt="Tick" />
                </div>
                <div className="challenge-creation-segment-content-container">
                  <div className="challenge-creation-segment-title">
                    {t("freeShipping")}
                  </div>
                  <div className="challenge-creation-segment-description"></div>
                </div>
              </div>

              <div className="challenge-creation-segment-row">
                <div
                  className={
                    campaignTypeXPriceToY
                      ? "qr-checkbox checked"
                      : "qr-checkbox"
                  }
                  onClick={() => {
                    handleCampaignTypeXPriceToY();
                  }}
                >
                  <img src={tick} alt="Tick" />
                </div>
                <div className="challenge-creation-segment-content-container">
                  <div className="challenge-creation-segment-title">
                    {t("ySotyCoinForEveryXTlPurchase")}
                  </div>
                  <div className="challenge-creation-segment-description"></div>
                </div>
              </div>
              <div className="challenge-creation-segment-row">
                <div
                  className={
                    campaignTypePercentageCoupon
                      ? "qr-checkbox checked"
                      : "qr-checkbox"
                  }
                  onClick={() => {
                    !returnedCampaign?.campaignType &&
                      handleCampaignTypePercentageCoupon();
                  }}
                >
                  <img src={tick} alt="Tick" />
                </div>
                <div className="challenge-creation-segment-content-container">
                  <div className="challenge-creation-segment-title">
                    {t("percentageCoupon")}
                  </div>
                  <div className="challenge-creation-segment-description"></div>
                </div>
              </div>
              <div className="challenge-creation-segment-row">
                <div
                  className={
                    campaignTypeXProduct ? "qr-checkbox checked" : "qr-checkbox"
                  }
                  onClick={() => {
                    !returnedCampaign?.campaignType &&
                      handleCampaignTypeXProduct();
                  }}
                >
                  <img src={tick} alt="Tick" />
                </div>
                <div className="challenge-creation-segment-content-container">
                  <div className="challenge-creation-segment-title">
                    {t("percentageDiscountOnXItems")}
                  </div>
                  <div className="challenge-creation-segment-description"></div>
                </div>
              </div>
              <div className="challenge-creation-segment-row ">
                <div
                  className={
                    campaignTypeXBuyYSpend
                      ? "qr-checkbox checked"
                      : "qr-checkbox"
                  }
                  onClick={() => {
                    !returnedCampaign?.campaignType &&
                      handleCampaingTypeBuyXPayY();
                  }}
                >
                  <img src={tick} alt="Tick" />
                </div>
                <div className="challenge-creation-segment-content-container">
                  <div className="challenge-creation-segment-title">
                    {t("buyXPayNow")}
                  </div>
                  <div className="challenge-creation-segment-description"></div>
                </div>
              </div>
            </div>
          </div>
          {/* FREE SHIPPING */}
          {campaignTypeFreeShipping && (
            <div className="challenge-creation-input-container">
              <div className="challenge-creation-input-container-header">
                <div className="challenge-creation-input-container-header-title">
                  {t("freeShipping")}:
                </div>
              </div>
              <hr />
              <div className="coin-folding-container">
                <div className="coin-folding-input-container">
                  <div className="coin-folding-input-wrapper">
                    <div
                      className={`coin-folding-input coin-folding-input-active`}
                    >
                      {t("freeShipping")}
                      <img src={tick} alt="tick" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* COIN FOLDING */}
          {campaignTypeCoinFolding && (
            <div className="challenge-creation-input-container">
              <div className="challenge-creation-input-container-header">
                <div className="challenge-creation-input-container-header-title">
                  {t("coinFolding")}
                  <span>*</span>:
                </div>
                <div className="challenge-creation-input-container-header-subtitle">
                  {t("coinFoldingSubtitle")}
                </div>
              </div>
              <hr />
              <div className="coin-folding-container">
                <div className="coin-folding-input-container">
                  <div className="challenge-creation-input-container-header-subtitle">
                    {t("popularChoices")}
                  </div>
                  <div className="coin-folding-input-wrapper">
                    <div
                      className={`coin-folding-input ${
                        x2 && "coin-folding-input-active"
                      }`}
                      onClick={() => {
                        handleX2();
                      }}
                    >
                      X2
                      {x2 && <img src={tick} alt="tick" />}
                    </div>
                    <div
                      className={`coin-folding-input ${
                        x3 && "coin-folding-input-active"
                      }`}
                      onClick={() => {
                        handleX3();
                      }}
                    >
                      X3
                      {x3 && <img src={tick} alt="tick" />}
                    </div>
                    <div
                      className={`coin-folding-input ${
                        x4 && "coin-folding-input-active"
                      }`}
                      onClick={() => {
                        handleX4();
                      }}
                    >
                      X4
                      {x4 && <img src={tick} alt="tick" />}
                    </div>
                    <div
                      className={`coin-folding-input ${
                        x5 && "coin-folding-input-active"
                      }`}
                      onClick={() => {
                        handleX5();
                      }}
                    >
                      X5
                      {x5 && <img src={tick} alt="tick" />}
                    </div>
                  </div>
                </div>
                <div className="coin-folding-input-container">
                  <div className="challenge-creation-input-container-header-subtitle">
                    {t("other")}
                  </div>
                  <div className="coin-folding-input-wrapper">
                    <div className="coin-folding-input-type-wrapper">
                      x
                      <input
                        type="number"
                        className="coin-folding-input-type"
                        onChange={(e) => {
                          setCustomFolding(e.target.value);
                        }}
                        value={customFolding}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* % DISCOUNT TO X PRODUCT */}
          {campaignTypeXProduct && (
            <div className="challenge-creation-input-container">
              <div className="challenge-creation-input-container-header">
                <div className="challenge-creation-input-container-header-title">
                  {t("productDiscountPercentage")}
                  <span>*</span>:
                </div>
              </div>
              <hr />
              <div className="discount-to-x-product-container">
                {discounts.map((discount, index) => (
                  <div
                    key={index}
                    className="discount-to-x-product-row-wrapper"
                  >
                    <div className="discount-to-x-product-row">
                      <div className="discount-to-x-product-counter-circle">
                        {index + 2}
                      </div>
                      <input
                        type="number"
                        min="0"
                        max="100" // En fazla 100 girilebilir
                        className="challenge-creation-soty-coin-input"
                        placeholder={t("pleaseEnterPercentage")}
                        value={discount} // Use value instead of defaultValue
                        onChange={(e) => {
                          // Girilen değeri kontrol et
                          let newValue = e.target.value;
                          // Eğer girilen değer 100'den büyükse 100 yap
                        
                          if (newValue > 100) {
                            newValue = "100";
                          }
                        
                          // State'i güncelle
                          handleDiscountChange(index, newValue);
                        }} // Use onChange to trigger handler function
                      />

                      <div className="challenge-creation-input-container-header-subtitle">
                        {localStorage.getItem("language") === "tr-TR"
                          ? index + 2 + ". " + t("theProductsDiscount")
                          : index + 2 + ". " + t("theProductsDiscount")}
                      </div>
                    </div>
                    <div className="discount-to-x-product-row">
                      {discounts.length > 1 && (
                        <div className="discount-to-x-product-row">
                          <div
                            className="discount-to-x-product-button"
                            onClick={() => handleDeleteDiscount(index)}
                          >
                            <div className="discount-to-x-product-button-minus">
                              -
                            </div>
                            <span>{t("delete")}</span>
                          </div>
                        </div>
                      )}
                      {/* Add button, sadece 4 indirim varsa görünür */}
                      {discounts.length < 4 &&
                        index === discounts.length - 1 && (
                          <div className="discount-to-x-product-row">
                            <div
                              className="discount-to-x-product-button"
                              onClick={() => {
                                handleAddDiscount();
                              }}
                            >
                              <div className="discount-to-x-product-button-plus">
                                +
                              </div>
                              <span>{t("addProduct")}</span>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* PERCENTAGE DISCOUNT */}
          {campaignTypePercentageDiscount && (
            <div className="challenge-creation-input-container">
              <div className="challenge-creation-input-container-header">
                <div className="challenge-creation-input-container-header-title">
                  {t("PercentageDiscount")}
                  <span>*</span>:
                </div>
                <div className="challenge-creation-input-container-header-subtitle">
                  {t("PercentageDiscountSubtitle")}
                </div>
              </div>
              <hr />
              <div className="coin-folding-container">
                <div className="coin-folding-input-container campaign-option-select-container">
                  <div className="challenge-creation-input-container-header-subtitle">
                    {t("popularChoices")}
                  </div>
                  <div className="coin-folding-input-wrapper">
                    <div
                      className={`percentage-coupon-input ${
                        percent5 && "percentage-coupon-input-active"
                      }`}
                      onClick={() => {
                        handlePercent5();
                      }}
                    >
                      %5
                      {percent5 && <img src={tick} alt="tick" />}
                    </div>
                    <div
                      className={`percentage-coupon-input ${
                        percent10 && "percentage-coupon-input-active"
                      }`}
                      onClick={() => {
                        handlePercent10();
                      }}
                    >
                      %10
                      {percent10 && <img src={tick} alt="tick" />}
                    </div>
                    <div
                      className={`percentage-coupon-input ${
                        percent15 && "percentage-coupon-input-active"
                      }`}
                      onClick={() => {
                        handlePercent15();
                      }}
                    >
                      %15
                      {percent15 && <img src={tick} alt="tick" />}
                    </div>
                    <div
                      className={`percentage-coupon-input ${
                        percent20 && "percentage-coupon-input-active"
                      }`}
                      onClick={() => {
                        handlePercent20();
                      }}
                    >
                      %20
                      {percent20 && <img src={tick} alt="tick" />}
                    </div>
                    <div
                      className={`percentage-coupon-input ${
                        percent30 && "percentage-coupon-input-active"
                      }`}
                      onClick={() => {
                        handlePercent30();
                      }}
                    >
                      %30
                      {percent30 && <img src={tick} alt="tick" />}
                    </div>
                  </div>
                </div>
                <div className="coin-folding-input-container">
                  <div className="challenge-creation-input-container-header-subtitle">
                    {t("other")}
                  </div>
                  <div className="coin-folding-input-wrapper">
                    <div className="coin-folding-input-type-wrapper">
                      %
                      <input
                        type="number"
                        min={0}
                        max={100}
                        className="coin-folding-input-type"
                        onChange={(e) => {
                          if (e.target.value >= 0 && e.target.value <= 100) {
                            setCustomPercent(e.target.value);
                          }
                        }}
                        value={customPercent}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* PERCENTAGE COUPON */}
          {campaignTypePercentageCoupon && (
            <div className="challenge-creation-input-container">
              <div className="challenge-creation-input-container-header">
                <div className="challenge-creation-input-container-header-title">
                  {t("percentageCoupon")}
                  <span>*</span>:
                </div>
                <div className="challenge-creation-input-container-header-subtitle">
                  {t("PercentageDiscountSubtitle")}
                </div>
              </div>
              <hr />
              <div className="coin-folding-container">
                <div className="coin-folding-input-container campaign-option-select-container">
                  <div className="challenge-creation-input-container-header-subtitle">
                    {t("popularChoices")}
                  </div>
                  <div className="coin-folding-input-wrapper">
                    <div
                      className={`percentage-coupon-input ${
                        percentCoupon5 && "percentage-coupon-input-active"
                      }`}
                      onClick={() => {
                        handlePercentCoupon5();
                      }}
                    >
                      %5
                      {percentCoupon5 && <img src={tick} alt="tick" />}
                    </div>
                    <div
                      className={`percentage-coupon-input ${
                        percentCoupon10 && "percentage-coupon-input-active"
                      }`}
                      onClick={() => {
                        handlePercentCoupon10();
                      }}
                    >
                      %10
                      {percentCoupon10 && <img src={tick} alt="tick" />}
                    </div>
                    <div
                      className={`percentage-coupon-input ${
                        percentCoupon15 && "percentage-coupon-input-active"
                      }`}
                      onClick={() => {
                        handlePercentCoupon15();
                      }}
                    >
                      %15
                      {percentCoupon15 && <img src={tick} alt="tick" />}
                    </div>
                    <div
                      className={`percentage-coupon-input ${
                        percentCoupon20 && "percentage-coupon-input-active"
                      }`}
                      onClick={() => {
                        handlePercentCoupon20();
                      }}
                    >
                      %20
                      {percentCoupon20 && <img src={tick} alt="tick" />}
                    </div>
                    <div
                      className={`percentage-coupon-input ${
                        percentCoupon30 && "percentage-coupon-input-active"
                      }`}
                      onClick={() => {
                        handlePercentCoupon30();
                      }}
                    >
                      %30
                      {percentCoupon30 && <img src={tick} alt="tick" />}
                    </div>
                  </div>
                </div>
                <div className="coin-folding-input-container">
                  <div className="challenge-creation-input-container-header-subtitle">
                    {t("other")}
                  </div>
                  <div className="coin-folding-input-wrapper">
                    <div className="coin-folding-input-type-wrapper">
                      %
                      <input
                        type="number"
                        className="coin-folding-input-type"
                        min="0"
                        max="100"
                        onChange={(e) => {
                          if (e.target.value >= 0 && e.target.value <= 100) {
                            setCustomPercentCoupon(e.target.value);
                          }
                        }}
                        value={customPercentCoupon}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* X PRICE TO Y */}
          {campaignTypeXPriceToY && (
            <div className="challenge-creation-input-container">
              <div className="challenge-creation-input-container-header">
                <div className="challenge-creation-input-container-header-title">
                  {t("XPriceToY")}
                  <span>*</span>:
                </div>
                <div className="challenge-creation-input-container-header-subtitle">
                  {t("XPriceToYSubtitle")}
                </div>
              </div>
              <hr />
              <div className="coin-folding-container">
                <div className="coin-folding-input-container campaign-option-select-container">
                  <div className="challenge-creation-input-container-header-subtitle">
                    {t("amount")}
                  </div>
                  <div className="coin-folding-input-wrapper x-price-input-container">
                    <input
                      type="number"
                      min="0"
                      className="challenge-creation-soty-coin-input"
                      placeholder={t("TL")}
                      defaultValue={returnedCampaign?.responseData.spend}
                      onChange={(e) => {
                        setXPrice(e.target.value);
                      }}
                    />
                    <span class="turkish-lira-sign">{t("TL")}</span>

                    <img
                      className="X-Price-To-Y-Icon"
                      src={DoubleSidedArrow}
                      alt={"<->"}
                    />
                    <input
                      type="number"
                      min="0"
                      className="challenge-creation-soty-coin-input"
                      placeholder={t("sotyCoinExchange")}
                      defaultValue={returnedCampaign?.responseData.earn}
                      onChange={(e) => {
                        setYCoin(e.target.value);
                      }}
                    />
                  </div>
                  <div className="challenge-creation-date-description-row">
                    <img
                      className="challenge-creation-date-description-icon"
                      src={infoIconEmpty}
                      alt="icon"
                    />
                    <div className="challenge-creation-date-description">
                      {t("sotyCoinValue")}
                    </div>
                  </div>
                  {yCoin && (
                    <div className="challenge-creation-date-description-row">
                      <img
                        className="challenge-creation-date-description-icon"
                        src={infoIconEmpty}
                        alt="icon"
                      />
                      <div className="challenge-creation-date-description">
                        {t("sotyCoinValueYouInput")}
                      </div>
                      <div className="challenge-creation-date-description challenge-creation-money-div">
                        {yCoin / 10}
                        {t("TL")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* X AL Y ODE */}
          {campaignTypeXBuyYSpend && (
            <div className="challenge-creation-input-container">
              <div className="challenge-creation-input-container-header">
                <div className="challenge-creation-input-container-header-title">
                  {t("buyXPayNow")}
                  <span>*</span>:
                </div>
                <div className="challenge-creation-input-container-header-subtitle"></div>
              </div>
              <hr />
              <div className="coin-folding-container">
                <div className="coin-folding-input-container campaign-option-select-container">
                  <div className="coin-folding-container">
                    <div className="coin-folding-input-container campaign-option-select-container">
                      <div className="challenge-creation-input-container-header-subtitle">
                        {t("popularChoices")}
                      </div>
                      <div className="coin-folding-input-wrapper">
                        <div
                          className={`percentage-coupon-input ${
                            buy2Spend1 && "percentage-coupon-input-active"
                          }`}
                          onClick={() => {
                            handleBuy2Spend1();
                          }}
                        >
                          {localStorage.getItem("language") === "tr-TR"
                            ? 2 + " " + t("buy") + " " + 1 + " " + t("pay")
                            : t("buy") + " " + 2 + " " + t("pay") + " " + 1}
                          {buy2Spend1 && <img src={tick} alt="tick" />}
                        </div>
                        <div
                          className={`percentage-coupon-input ${
                            buy3Spend2 && "percentage-coupon-input-active"
                          }`}
                          onClick={() => {
                            handleBuy3Spend2();
                          }}
                        >
                          {localStorage.getItem("language") === "tr-TR"
                            ? 3 + " " + t("buy") + " " + 2 + " " + t("pay")
                            : t("buy") + " " + 3 + " " + t("pay") + " " + 2}
                          {buy3Spend2 && <img src={tick} alt="tick" />}
                        </div>
                        <div
                          className={`percentage-coupon-input ${
                            buy4Spend3 && "percentage-coupon-input-active"
                          }`}
                          onClick={() => {
                            handleBuy4Spend3();
                          }}
                        >
                          {localStorage.getItem("language") === "tr-TR"
                            ? 4 + " " + t("buy") + " " + 3 + " " + t("pay")
                            : t("buy") + " " + 4 + " " + t("pay") + " " + 3}
                          {buy4Spend3 && <img src={tick} alt="tick" />}
                        </div>
                        <div
                          className={`percentage-coupon-input ${
                            campaignTypeXBuyYSpendCustom &&
                            "percentage-coupon-input-active"
                          }`}
                          onClick={() => {
                            handleCampaignTypeXBuyYSpendCustom();
                          }}
                        >
                          {t("customValue")}
                          {campaignTypeXBuyYSpendCustom && (
                            <img src={tick} alt="tick" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {campaignTypeXBuyYSpendCustom && (
                    <>
                      <div className="coin-folding-input-wrapper x-price-input-container">
                        <input
                          type="number"
                          min="0"
                          max="5" // Burada maksimum değeri belirleyin
                          className="challenge-creation-soty-coin-input"
                          placeholder={t("ItemPurchaseQuantityError")}
                          defaultValue={returnedCampaign?.responseData.buyCount}
                          onChange={(e) => {
                            // Değer 5'ten büyükse 5'e eşitle
                            if (e.target.value > 5) {
                              e.target.value = 5;
                            }
                            setBuySpendCustomBuy(e.target.value);
                          }}
                        />

                        {t("buy")}

                        <img
                          className="X-Price-To-Y-Icon"
                          src={DoubleSidedArrow}
                          alt={"<->"}
                        />
                        <input
                          type="number"
                          min="0"
                          className="challenge-creation-soty-coin-input"
                          placeholder={t("PaymentItemCountError")}
                          defaultValue={returnedCampaign?.responseData.payCount}
                          onChange={(e) => {
                            // Değer 5'ten büyükse 5'e eşitle
                            if (e.target.value > 4) {
                              e.target.value = 4;
                            }
                            setBuySpendCustomSpend(e.target.value);
                          }}
                        />
                        {t("pay")}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* ALISVERIS ALT LIMIT */}
          {!campaignTypeXPriceToY && !campaignTypeXBuyYSpend && (
            <>
              <div className="challenge-creation-input-container">
                <div className="challenge-creation-input-container-header">
                  <div className="challenge-creation-input-container-header-title">
                    {t("minShoppingLimit")}
                    {!campaignTypeFreeShipping && " (" + t("optional") + ")"}
                    {campaignTypeFreeShipping && <span>*</span>}:
                  </div>
                  <div className="challenge-creation-input-container-header-subtitle">
                    {t("setSotyCoinAmountCampaign")}{" "}
                  </div>
                </div>
                <hr />
                <div className="challenge-creation-input-container-content-wrapper shopping-min-limit-row">
                  <input
                    type="number"
                    min="0"
                    className="challenge-creation-soty-coin-input"
                    placeholder={t("enterCoinAmount")}
                    defaultValue={
                      returnedCampaign?.responseData.minimumSpending
                    }
                    onChange={(e) => {
                      setMinimumSpending(e.target.value);
                    }}
                  />

                  <div className="challenge-creation-date-description-row padding-none">
                    <img
                      className="challenge-creation-date-description-icon"
                      src={infoIconEmpty}
                      alt="icon"
                    />
                    {!campaignTypeFreeShipping && (
                      <div className="challenge-creation-date-description">
                        {t("optional")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {/* ANKET TARIHI */}
          <div className="challenge-creation-input-container">
            <div className="challenge-creation-input-container-header">
              <div className="challenge-creation-input-container-header-title">
                {t("campaignDate")}
                <span>*</span>:
              </div>
              <div className="challenge-creation-input-container-header-subtitle">
                {t("campaignDateTimeSet")}
              </div>
            </div>
            <hr />
            <div className="challenge-creation-input-container-content-wrapper">
              <div className="challenge-creation-date-column-container">
                <div className="challenge-creation-date-column">
                  <div className="challenge-creation-date-title">
                    {t("startDate")}
                    <span>*</span>
                  </div>
                  <input
                    type="datetime-local"
                    className="challenge-creation-date-picker"
                    defaultValue={
                      returnedCampaign
                        ? moment
                            .utc(returnedCampaign.responseData.startDate)
                            .local()
                            .format("YYYY-MM-DDTHH:mm:ss")
                        : startDate
                    }
                    onBlur={startDateChange}
                    style={{ border: isStartDateValid ? "" : "2px solid red" }}
                  />
                </div>

                <div className="challenge-creation-date-column">
                  <div className="challenge-creation-date-title">
                    {t("endDate")}
                    <span>*</span>
                  </div>
                  <input
                    type="datetime-local"
                    className="challenge-creation-date-picker"
                    defaultValue={
                      returnedCampaign
                        ? moment
                            .utc(returnedCampaign.responseData.endDate)
                            .local()
                            .format("YYYY-MM-DDTHH:mm:ss")
                        : endDate
                    }
                    onBlur={endDateChange}
                    style={{ border: isEndDateValid ? "" : "2px solid red" }}
                  />
                </div>
              </div>

              <div className="challenge-creation-date-description-row">
                <img
                  className="challenge-creation-date-description-icon"
                  src={infoIconEmpty}
                  alt="icon"
                />
                <div className="challenge-creation-date-description">
                  {t("setCampaignStartDate")}
                </div>
              </div>
            </div>
          </div>
          {/* KAMPANYA KATILABILME SAYISI */}
          <div className="challenge-creation-input-container">
            <div className="challenge-creation-input-container-header">
              <div className="challenge-creation-input-container-header-title">
                {t("campaignParticipationCount")}
                <span>*</span>:
              </div>
              <div className="challenge-creation-input-container-header-subtitle">
                {t("campaignParticipationCountSubtitle")}
              </div>
            </div>
            <hr />
            <div className="coin-folding-container">
              <div className="coin-folding-input-container">
                <div className="coin-folding-input-wrapper">
                  <div
                    className={`coin-folding-input ${
                      singleParticipate && "coin-folding-input-active"
                    }`}
                    onClick={() => {
                      setSingleParticipate(true);
                    }}
                  >
                    {t("single")}
                    {singleParticipate && <img src={tick} alt="tick" />}
                  </div>
                  <div
                    className={`coin-folding-input ${
                      !singleParticipate && "coin-folding-input-active"
                    }`}
                    onClick={() => {
                      setSingleParticipate(false);
                    }}
                  >
                    {t("multiple")}
                    {!singleParticipate && <img src={tick} alt="tick" />}
                  </div>
                </div>
                <div className="challenge-creation-date-description-row">
                  <img
                    className="challenge-creation-date-description-icon"
                    src={infoIconEmpty}
                    alt="icon"
                  />
                  <div className="challenge-creation-date-description">
                    {t("campaignParticipationCountDesc")}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* BILGILENDIRME */}
          <div className="challenge-creation-input-container">
            <div className="challenge-creation-input-container-header">
              <div className="challenge-creation-input-container-header-title">
                {t("campaignMessageTypeTitle")}
                <span>*</span>:
              </div>
              <div className="challenge-creation-input-container-header-subtitle">
                {t("campaignMessageTypeSubtitle")}
              </div>
            </div>
            <hr />
            <div className="challenge-creation-input-container-content-wrapper">
              <div className="challenge-creation-segment-row">
                <div
                  className={sendInApp ? "qr-checkbox checked" : "qr-checkbox"}
                  onClick={() => {
                    handleInputChange(!sendInApp, setSendInApp);
                  }}
                >
                  <img src={tick} alt="Tick" />
                </div>
                <div className="challenge-creation-segment-content-container">
                  <div className="challenge-creation-segment-title">
                    {t("inAppNotification")}
                  </div>
                  <div className="challenge-creation-segment-description"></div>
                </div>
              </div>

              <div className="challenge-creation-segment-row">
                <div
                  className={sendSms ? "qr-checkbox checked" : "qr-checkbox"}
                  onClick={() => {
                    handleInputChange(!sendSms, setSendSms);
                  }}
                >
                  <img src={tick} alt="Tick" />
                </div>
                <div className="challenge-creation-segment-content-container">
                  <div className="challenge-creation-segment-title">
                    {t("sms")}
                  </div>
                  <div className="challenge-creation-segment-description"></div>
                </div>
              </div>
              <div className="challenge-creation-segment-row">
                <div
                  className={sendMail ? "qr-checkbox checked" : "qr-checkbox"}
                  onClick={() => {
                    handleInputChange(!sendMail, setSendMail);
                  }}
                >
                  <img src={tick} alt="Tick" />
                </div>
                <div className="challenge-creation-segment-content-container">
                  <div className="challenge-creation-segment-title">
                    {t("mail")}
                  </div>
                  <div className="challenge-creation-segment-description"></div>
                </div>
              </div>
            </div>
          </div>

          {/* KAMPANYA ONAY DUGMELERI */}
          <div className="challenge-creation-submit-button-container">
            <button
              className="draft"
              onClick={() => {
                handleCampaignUpload(false);
              }}
            >
              {t("saveAsDraft")}
            </button>
            <button
              className="preview"
              onClick={() => {
                handleCampaignUpload(true);
              }}
            >
              {t("preview")}
            </button>
          </div>
        </div>

        <div
          className="challenge-creation-phone-container"
          style={{ position: "sticky", top: "0", right: "30px" }}
        >
          <PhoneViewJoin
            name={challengeName}
            description={challengeDescription}
            endDate={endDate || ""}
            logo={challengeImage || noImageToShow}
            imagesUrl={imagesUrl}
            brandLogo={challengeImage || noImageToShow}
            challengeType={t("Campaign")}
            socialProvider={checkedProvider}
            foldingString={foldingString}
            percentString={percentString}
            startDate={startDate}
            percentCouponString={percentCouponString}
            spendToEarnSpend={xPrice}
            spendToEarnEarn={yCoin}
            payCount={campaignTypeXBuyYSpend && payCountText && payCountText}
            buyCount={buyCountText && buyCountText}
            freeShipping={freeShipping}
            discounts={campaignTypeXProduct && discounts}
          />
        </div>
      </div>
    </div>
  );
};

export default CampaignCreation;
