import { BrandAdminProfileRightContainer } from "../../utils/BrandAdminProfileRightContainer.js";
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import validator from "validator";
import axios from "axios";
import "../../../assets/css/BrandAdminProfile.css";
import arrow from "../../../assets/media/arrow.png";
import checkImage from "../../../assets/media/check.svg";

import { showErrorToast } from "../../toast/toast.js";
import tick from "../../../assets/media/Checklist.svg";
import downArrow from "../../../assets/media/down-arrow.png";
import Collapsible from "../../collapsible/collapsible";
import instagramIcon from "../../../assets/media/instagram.svg";
import xIcon from "../../../assets/media/x-twitter.svg";
import { toast } from "react-toastify";
import youtubeIcon from "../../../assets/media/youtube.svg";
import facebookIcon from "../../../assets/media/facebook.svg";
import tiktokIcon from "../../../assets/media/tiktok.svg";
import calendar from "../../../assets/media/calendar.svg";
import { useNavigate } from "react-router-dom";
import ImageDropzone from "../../dropzone/ImageDropzone";
import contractTexts from "./contractTexts";
import { getCookie, getUserRolesFromToken } from "../auth/tokenUtils";
import { isMyTokenExpired } from "../auth/tokenUtils";
import {
  apiUrl,
  brandTiktokClientKey,
  brandTiktokRedirectUri,
  imagesUrl,
} from "../../../config";
import { handleFacebookLogin } from "../../api/FacebookApi.js";
import failedIcon from "../../../assets/media/failed.png";
import {
  FacebookLoginButton,
  InstagramLoginButton,
  TwitterLoginButton,
  TikTokLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import TikTokApi from "../../api/TiktokApi.js";
import { useTranslation } from "react-i18next";
import Loader from "../../popup/loader.js";
import SingleNumberInput from "../../utils/singleNumberInput.js";
import CallAPI from "../../api/callApi.js";
import ChallengeImageDropzone from "../../dropzone/ChallengeImageDropzone.js";
import AreYouSure from "../../popup/areYouSure.js";
const BrandAdminProfile = () => {
  const [loaderState, setLoaderState] = useState(false);
  const { t, i18n } = useTranslation("brandAdminProfile");
  const defaultSotyLogo =
    imagesUrl + "/917f80c5-97e3-4fca-8cce-6247a5d1afa711043674371957123852";
  const [brandCategories, setBrandCategories] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const [created, setCreated] = useState("");
  const [id, setId] = useState(0);
  const [firstLogin, setFirstLogin] = useState(true);
  const [status, setStatus] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [url, setUrl] = useState("");
  const [brandName, setBrandName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [taxOffice, setTaxOffice] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [giro, setGiro] = useState("");
  const [storeCount, setStoreCount] = useState("");
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState("");
  const [logo, setLogo] = useState("");
  const [invoiceAdress, setInvoiceAdress] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [tiktokUrl, setTiktokUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [contractApproved, setContractApproved] = useState(false);
  const [formApproved, setFormApproved] = useState(false);
  const [checked, setIsChecked] = useState(false);
  const [ulke, setUlke] = useState("");
  const [sehir, setSehir] = useState("");
  const [ilce, setIlce] = useState("");
  const [mahalle, setMahalle] = useState("");
  const [cadde, setCadde] = useState("");
  const [sokak, setSokak] = useState("");
  const [no, setNo] = useState("");
  const [postaKodu, setPostaKodu] = useState("");
  const [requestId, setRequestId] = useState("");
  const [formattedCreated, setFormattedCreated] = useState("");
  const [roles, setRoles] = useState([]); // To store user roles
  const [isUpdateBrandAllowed, setIsUpdateBrandAllowed] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [logoReadOnly, setLogoReadOnly] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [isForm1Filled, setIsForm1Filled] = useState(false);
  const [isForm2Filled, setIsForm2Filled] = useState(false);
  const [isForm3Filled, setIsForm3Filled] = useState(false);
  const [isForm4Filled, setIsForm4Filled] = useState(false);
  const [isForm5Filled, setIsForm5Filled] = useState(false);
  const [isForm6Filled, setIsForm6Filled] = useState(false);
  const [isForm7Filled, setIsForm7Filled] = useState(false);
  const [UUID, setUUID] = useState();
  const [tiktokAccessToken, setTiktokAccessToken] = useState("");
  const [facebookSignIn, setFacebookSignIn] = useState(false);
  const [facebookConnected, setFacebookConnected] = useState(false);
  const [tiktokConnected, setTiktokConnected] = useState(false);
  const [publicApiKey, setPublicApiKey] = useState();
  const [privateApiKey, setPrivateApiKey] = useState();
  const [newSubBrandName, setNewSubBrandName] = useState();
  const [newSubBrandImage, setNewSubBrandImage] = useState();
  const [subBrands, setSubBrands] = useState([]);
  const [subBrandToDelete, setSubBrandToDelete] = useState();
  const [firstCampaignDiscountPercentage, setFirstCampaignDiscountPercentage] =
    useState(5);
  useState();

  const handleSignInWithFacebook = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    try {
      // Facebook girişi için işlemler
      const accessToken = await handleFacebookLogin(apiUrl);
      console.log("DATA GELDI = Access token:", accessToken.accessToken);

      // API endpoint'i oluştur
      const endPoint = apiUrl + `/api/v1/brand-user/social-media`;

      // Kullanıcının kimlik doğrulama token'ını al
      const authToken = getCookie("access_token");

      // Gönderilecek veri
      const requestData = {
        token: accessToken.accessToken, // accessToken bir string olarak varsayılmıştır
      };

      // Axios ile isteği gerçekleştir
      axios
        .put(endPoint, requestData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`, // Kimlik doğrulama token'ını ekleyin
          },
        })
        .then((response) => {
          if (response.status === 200) {
            // Başarı durumunda başarılı bir toast göster
            toast.success("Facebook hesabı sisteme kaydedildi.");
            setFacebookSignIn(true);
          }
          // Başarıyı işlemek veya kullanıcıyı yönlendirmek için buraya kod ekleyebilirsiniz
        })
        .catch((error) => {
          // Hata durumunda kullanıcıya anlaşılır bir hata mesajı göster
          toast.error(
            "Facebook hesabı sisteme kaydedilirken bir hata oluştu. Lütfen tekrar deneyin."
          );

          // Axios hatasını konsola yazdır
          console.error("Axios Hatası:", error);

          // Hataları işlemek için ek kod ekleyebilirsiniz (örneğin, kullanıcıya daha fazla bilgi vermek)
        });

      // Artık erişim belirteci ile uygulama mantığınızda kullanabilirsiniz
    } catch (error) {
      // Facebook girişi sırasında genel bir hata durumunda kullanıcıya bilgi ver
      console.error("Facebook girişi sırasında bir hata oluştu:", error);
      toast.error(
        "Facebook girişi sırasında bir hata oluştu. Lütfen tekrar deneyin."
      );
      // Hatası nasıl işleyeceğinizi belirleyin
    }
  };
  const fetchSubBrands = async () => {
    try {
      const response = await CallAPI({
        method: "GET",
        endPoint: apiUrl + "/api/v1/brand-admin/brand",
      });
      setSubBrands(response.data.responseData.subBrands);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  const handleInputChange = (field, value) => {
    // Değerleri doğrula
    //validate(value);

    // Hangi alandaki değeri güncelleyeceğinizi belirlemek için field kullanabilirsiniz
    if (field === "instagramUrl") {
      setInstagramUrl(value);
    } else if (field === "youtubeUrl") {
      setYoutubeUrl(value);
    } else if (field === "facebookUrl") {
      setFacebookUrl(value);
    } else if (field === "tiktokUrl") {
      setTiktokUrl(value);
    } else if (field === "twitterUrl") {
      setTwitterUrl(value);
    }
  };
  const handleSubBrandSubmit = async () => {
    if (!newSubBrandName) {
      toast.error(t("subBrandNameNotDefined"));
      return;
    }
    if (!newSubBrandImage) {
      toast.error(t("subBrandImageNotDefined"));
      return;
    }
    setLoaderState(true);
    try {
      const response = await CallAPI({
        method: "POST",
        endPoint: apiUrl + `/api/v1/brand-admin/brand/sub-brand`,
        body: {
          name: newSubBrandName,
          logo: newSubBrandImage,
        },
        successMessage: t("subBrandAddedSuccessfully"),
        errorMessage: t("anErrorAcurredWhileCreatngSubBrand"),
      });
      console.log(response);
      setSubBrands(response.data.responseData.subBrands);
      setLoaderState(false);
    } catch (error) {
      console.log(error);
      setLoaderState(false);
    }
  };
  const handleFormAndSocialSubmit = (e) => {
    if (!firstCampaignDiscountPercentage) {
      toast.error(t("firstCampaignDiscountPercentageMustBetween5and100"));
      return;
    } else if (
      !(
        firstCampaignDiscountPercentage >= 5 ||
        firstCampaignDiscountPercentage <= 100
      )
    ) {
      toast.error(t("firstCampaignDiscountPercentageMustBetween5and100"));
      return;
    }
    e.preventDefault();

    let bool = handleSocialSubmit(e);
    console.log(bool);
    if (bool) handleSubmit(e);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-based, so add 1
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const [progress, setProgress] = useState(0);

  const checkForm = () => {
    const checkbox1 = document.getElementById("EULACheck1");
    const checkbox2 = document.getElementById("EULACheck2");
    const checkbox3 = document.getElementById("formApproveInput");

    if (
      (checkbox1.checked === false || checkbox2.checked === false) &&
      checkbox3.checked === true
    ) {
      toast.error("Lütfen sözleşmelerin onaylandığından emin olun.");
    }

    if (checkbox2.checked === true && checkbox1.checked === true) {
      setContractApproved(true);
      if (checkbox3.checked === true) {
        setFormApproved(true);
      }
      if (checkbox3.checked === false) {
        setFormApproved(false);
      }
    } else {
      setContractApproved(false);
      setFormApproved(false);
      checkbox3.checked = false;
    }
  };

  useEffect(() => {
    const allFields1Filled =
      firstName !== "" && lastName !== "" && phoneNumber !== "" && email !== "";
    setIsForm1Filled(allFields1Filled);
    const allFields2Filled = url !== "" && brandName !== "";
    setIsForm2Filled(allFields2Filled);
    const allFields3Filled =
      companyName !== "" && taxOffice !== "" && taxNumber !== "";
    setIsForm3Filled(allFields3Filled);
    const allFileds4Filled = giro !== "" && storeCount !== "";
    setIsForm4Filled(allFileds4Filled);
    const allFields5Filled =
      ulke !== "" &&
      sehir !== "" &&
      ilce !== "" &&
      mahalle !== "" &&
      cadde !== "" &&
      sokak !== "" &&
      no !== "" &&
      postaKodu !== "";
    setIsForm5Filled(allFields5Filled);
    const allFields6Filled =
      instagramUrl !== "" &&
      youtubeUrl !== "" &&
      facebookUrl !== "" &&
      twitterUrl !== "" &&
      tiktokUrl;
    setIsForm6Filled(allFields6Filled);
    const allFields7Filled =
      formApproved !== false && contractApproved !== false;
    setIsForm7Filled(allFields7Filled);
  }, [
    contractApproved,
    formApproved,
    firstName,
    lastName,
    phoneNumber,
    email,
    url,
    brandName,
    companyName,
    taxOffice,
    taxNumber,
    giro,
    storeCount,
    ulke,
    sehir,
    ilce,
    mahalle,
    cadde,
    sokak,
    no,
    postaKodu,
    instagramUrl,
    youtubeUrl,
    facebookUrl,
    twitterUrl,
    tiktokUrl,
  ]);

  useEffect(() => {
    const totalInputs = 23; // Toplam input sayısı
    const filledInputs = [
      firstName,
      lastName,
      phoneNumber,
      email,
      url,
      brandName,
      companyName,
      taxOffice,
      taxNumber,
      giro,
      storeCount,
      ulke,
      sehir,
      ilce,
      mahalle,
      sokak,
      no,
      postaKodu,
      instagramUrl,
      youtubeUrl,
      facebookUrl,
      twitterUrl,
      tiktokUrl /* Diğer inputlar buraya eklenir... */,
    ].filter((input) => input !== "").length;
    const newProgress = parseInt(
      Math.round((filledInputs / totalInputs) * 100),
      10
    );
    setProgress(newProgress);
  }, [
    firstName,
    lastName,
    phoneNumber,
    email,
    url,
    brandName,
    companyName,
    taxOffice,
    taxNumber,
    giro,
    storeCount,
    ulke,
    sehir,
    ilce,
    mahalle,
    sokak,
    no,
    postaKodu,
    instagramUrl,
    youtubeUrl,
    facebookUrl,
    twitterUrl,
    tiktokUrl,
  ]);

  // Hangi input alanı değiştiyse, ilgili state'i güncelle
  const handleChange = (e) => {
    const { id, value } = e.target;

    switch (id) {
      case "id":
        setId(value);
        break;
      case "status":
        setStatus(value);
        break;
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "phoneNumber":
        if (/[^0-9]/.test(value)) {
          showErrorToast("Lütfen geçerli bir telefon numarası girin");
        } else {
          // Ensure that value only contains numbers and has a maximum length of 10
          const cleanedValue = value.slice(0, 10);
          setPhoneNumber(cleanedValue);
        }
        break;
      case "email":
        setEmail(value);
        break;
      case "url":
        setUrl(value);
        break;
      case "brandName":
        setBrandName(value);
        break;
      case "companyName":
        setCompanyName(value);
        break;
      case "taxOffice":
        setTaxOffice(value);
        break;
      case "taxNumber":
        setTaxNumber(value);
        break;
      case "giro":
        setGiro(value);
        break;
      case "storeCount":
        setStoreCount(value);
        break;
      case "companyPhoneNumber":
        setCompanyPhoneNumber(value);
        break;
      case "logo":
        setLogo(value);
        break;
      case "invoiceAdress":
        setInvoiceAdress(value);
        break;
      case "ulke":
        setUlke(value);
        break;

      case "sehir":
        setSehir(value);
        break;

      case "ilce":
        setIlce(value);
        break;

      case "mahalle":
        setMahalle(value);
        break;

      case "cadde":
        setCadde(value);
        break;

      case "sokak":
        setSokak(value);
        break;

      case "postaKodu":
        setPostaKodu(value);
        break;

      case "no":
        setNo(value);
        break;
      /*
      case "instagramUrl":
        setInstagramUrl(value);
        break;
      case "facebookUrl":
        setFacebookUrl(value);
        break;
      case "youtubeUrl":
        setYoutubeUrl(value);
        break;
      case "tiktokUrl":
        setTiktokUrl(value);
        break;
      case "twitterUrl":
        setTwitterUrl(value);
        break;
      */
      case "contractApproved":
        setContractApproved(!contractApproved);
        break;
      case "formApproved":
        setFormApproved(!formApproved);
        break;
      case "created":
        setCreated(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    // Construct the URL with the brand ID
    const endPoint =
      apiUrl +
      `/api/v1/brand-admin/brand/form?isContractApproved=${contractApproved}&isFormApproved=${formApproved}&discountPercentage=${firstCampaignDiscountPercentage}`;
    // Get the access token from the cookie
    const authToken = getCookie("access_token");

    // Create an object with the data you want to send to the backend
    const dataToSend = {
      contractApproved: contractApproved,
      formApproved: formApproved,
    };

    // Use Axios to send the PUT request
    axios
      .put(endPoint, dataToSend, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, // Include your authentication token
        },
      })
      .then((response) => {
        if (response.status === 200) {
          window.location.reload();
          toast.success("form iletildi"); // Show success toast
        }
        // You can add code here to handle success or redirect the user
      })
      .catch((error) => {
        toast.error("Form iletilirken bir hata oluştu. Lütfen tekrar deneyin.");
        // Handle errors (e.g., show an error message to the user)
      });
  };

  const generateSocialMediaUrl = (platform, username) => {
    let url = "";
    if (!username) return;
    switch (platform) {
      case "instagram":
        url = `https://www.instagram.com/${username}`;

        break;
      case "facebook":
        url = `https://www.facebook.com/${username}`;
        break;
      case "youtube":
        url = `https://www.youtube.com/channel/${username}`;
        break;
      case "tiktok":
        url = `https://www.tiktok.com/@${username}`;
        break;
      case "twitter":
        url = `https://twitter.com/${username}`;
        break;
      default:
        // Bilinmeyen bir platform ise boş bir URL oluşturabilirsiniz.
        url = "";
    }
    return url;
  };

  const deleteSubBrand = async (subBrandId) => {
    setLoaderState(true);
    console.log(subBrandId);
    try {
      const response = await CallAPI({
        method: "DELETE",
        endPoint: apiUrl + "/api/v1/brand-admin/brand/sub-brand/" + subBrandId,
        successMessage: t("subBrandDeletedSuccessfully"),
        errorMessage: t("anErrorAcurredWhileDeletingSubBrand"),
      });
      console.log(response);
      fetchSubBrands();
      setLoaderState(false);
    } catch (error) {
      console.log(error);
      setLoaderState(false);
    }
  };
  const handleSocialSubmit = (e) => {
    e.preventDefault();
    console.log(instagramUrl);
    if (
      (instagramUrl === undefined || instagramUrl === "") &&
      (youtubeUrl === undefined || youtubeUrl === "") &&
      (facebookUrl === undefined || facebookUrl === "") &&
      (twitterUrl === undefined || twitterUrl === "") &&
      (tiktokUrl === undefined || tiktokUrl === "") &&
      (facebookConnected === false) &&
      (tiktokConnected === false)
    ) {
      toast.error(
        t("atLeastOneSocialMediaAccountMustBeIntegratedToTheAccount")
      );
      return false;
    }
    const data = {
      instagramUrl: generateSocialMediaUrl("instagram", instagramUrl),
      youtubeUrl: generateSocialMediaUrl("youtube", youtubeUrl),
      facebookUrl: generateSocialMediaUrl("facebook", facebookUrl),
      twitterUrl: generateSocialMediaUrl("twitter", twitterUrl),
      tiktokUrl: generateSocialMediaUrl("tiktok", tiktokUrl),
    };
    console.log(data);
    console.log("datasreadytosend", data);

    const endPoint = apiUrl + "/api/v1/brand-admin/brand/social-media";
    // Get the access token from the cookie
    const authToken = getCookie("access_token");

    // Make the PUT request using a library like axios or fetch
    // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint
    axios
      .put(endPoint, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, // Include your authentication token
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("sosyal medya hesaplari sisteme kaydedildi"); // Show success toast
          return true;
        }

        // You can add code here to handle success or redirect the user
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors (e.g., show an error message to the user)
      });
    return true;
    e.preventDefault();
  };

  const handleInvoiceSubmit = (e) => {
    e.preventDefault();
    if (
      ulke === "" ||
      sehir === "" ||
      ilce === "" ||
      mahalle === "" ||
      no === "" ||
      postaKodu === ""
    ) {
      // Display an error toast
      toast.error("Lütfen adres bilgilerini eksiksiz bir şekilde giriniz.");
      return; // Prevent form submission
    }

    const data = {
      country: ulke,
      city: sehir,
      area: ilce,
      neighborhood: mahalle,
      street: cadde,
      road: sokak,
      no: no,
      zip: postaKodu,
    };

    const endPoint = apiUrl + "/api/v1/brand-admin/brand/invoice-address";
    // Get the access token from the cookie
    const authToken = getCookie("access_token");

    // Make the PUT request using a library like axios or fetch
    // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint
    axios
      .put(endPoint, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, // Include your authentication token
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("adres bilgileri sisteme kaydedildi"); // Show success toast
        }
        // You can add code here to handle success or redirect the user
      })
      .catch((error) => {
        console.error("Error:", error.message);
        // Handle errors (e.g., show an error message to the user)
      });

    e.preventDefault();
  };

  const handleSubBrandImageUpload = async (file) => {
    setLoaderState(true);

    try {
      const authToken = getCookie("access_token");

      const formData = new FormData();

      const response = await fetch(file);
      const blobData = await response.blob();

      formData.append("file", blobData);

      const uploadUrl = `${apiUrl}/api/v1/brand-admin/brand/logo`;

      const uploadResponse = await axios.post(uploadUrl, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      setLoaderState(false);
      setNewSubBrandImage(uploadResponse.data.responseData);

      console.log(uploadResponse);
    } catch (error) {
      console.log(error);
      toast("Bir Hata ile Karşılaşıldı. Lütfen Tekrar Deneyin.");
      setLoaderState(false);
    }
  };

  const handleImageUpload = (images) => {
    // Check if any images were uploaded
    if (images.length === 0) {
      return;
    }

    // Assuming you have a function called `toast` to display toast notifications
    const endPoint = apiUrl + "/api/v1/brand-admin/brand/logo";
    const authToken = getCookie("access_token"); // Replace with your actual authentication token

    const formData = new FormData();
    images.forEach((image, index) => {
      // Append the image with the field name 'file' and specify the type
      formData.append("file", image, image.name);
      formData.append("type", "image/jpeg"); // Specify the image type here
    });

    fetch(endPoint, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(async (response) => {
        if (response.status === 200) {
          toast("Logo sisteme eklendi");

          // Resim URL'sini almak için yanıtın metnini bekleyin
          fetchImg();
        } else {
          throw new Error("Logo eklenirken bir hata oluştu.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast("Bir Hata İle Karşılaşıldı. Lütfen Tekrar Deneyin.");
      });
  };

  useEffect(() => {
    const token = getCookie("access_token");
    if (!token) {
      console.error("Access token is missing.");
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error("Oturumunuzun süresi doldu. Lütfen yeniden giriş yapınız.");
      navigate("/sign-in-brand");
    }
    handleOAuthRedirect();
    fetchData();
    fetchSubBrands();
    const userRoles = getUserRolesFromToken(token);
    const isUpdateBrandAllowed = userRoles.includes("UPDATE_BRAND");
    const isUpdateBrandLogoAllowed = userRoles.includes("UPDATE_BRAND_LOGO");
    console.log(isUpdateBrandLogoAllowed);
    setRoles(userRoles);
    console.log(userRoles);
    setIsUpdateBrandAllowed(isUpdateBrandAllowed);
    setReadOnly(!isUpdateBrandAllowed);
    setLogoReadOnly(!isUpdateBrandLogoAllowed);

    if (userRoles.includes("VIEW_BRAND_KEYS")) {
      fetchApiKey();
    }
  }, []);

  const fetchApiKey = async () => {
    setLoaderState(true);
    const token = getCookie("access_token");
    if (!token) {
      console.error("Access token is missing.");
      navigate("/sign-in-brand");

      return;
    }

    const ApiEndpoint = apiUrl + "/api/v1/brand-admin/brand/key";

    try {
      const response = await axios.get(ApiEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;
      console.log("API KEYYYYYY =   ", data);
      setPrivateApiKey(response.data.responseData.privateKey);
      setPublicApiKey(response.data.responseData.publicKey);
      setLoaderState(false);
    } catch (error) {
      setLoaderState(false);

      console.log(error);
      toast.error(error.response.data.metadata.message);
      // Hataları işleyin (örneğin, kullanıcıya bir hata mesajı gösterin)
    }
  };

  const fetchImg = async () => {
    const token = getCookie("access_token");

    if (!token) {
      console.error("Access token is missing.");
      navigate("/sign-in-brand");
      return;
    }

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(apiUrl + "/api/v1/brand-admin/brand", options);

    if (response.status !== 200) {
      console.error("Error fetching data. Status: ", response.status);
      navigate("/sign-in-brand");
      return;
    }

    const responseData = await response.json();

    const { requestId } = responseData.metadata;

    setRequestId(requestId);

    const { logo } = responseData.responseData;

    setLogo(logo || "");
  };

  const setUsernamesFromApiData = (data) => {
    const newUsernames = {};

    if (data.instagramUrl) {
      const match = data.instagramUrl.match(
        /https:\/\/www.instagram.com\/(\w+)/
      );
      if (match && match[1]) {
        newUsernames.instagramUrl = match[1];
      }
    }

    if (data.facebookUrl) {
      const match = data.facebookUrl.match(/https:\/\/www.facebook.com\/(\w+)/);
      if (match && match[1]) {
        newUsernames.facebookUrl = match[1];
      }
    }

    if (data.youtubeUrl) {
      const match = data.youtubeUrl.match(
        /https:\/\/www.youtube.com\/channel\/(\w+)/
      );
      if (match && match[1]) {
        newUsernames.youtubeUrl = match[1];
      }
    }

    if (data.tiktokUrl) {
      const match = data.tiktokUrl.match(
        /https:\/\/www.tiktok.com\/@([^\/\?]+)/
      );
      if (match && match[1]) {
        newUsernames.tiktokUrl = match[1];
      }
    }

    if (data.twitterUrl) {
      const match = data.twitterUrl.match(
        /https:\/\/twitter.com\/([a-zA-Z0-9_]+)/
      );
      if (match && match[1]) {
        newUsernames.twitterUrl = match[1];
      }
    }

    return newUsernames;
  };

  const fetchData = async () => {
    const token = getCookie("access_token");
    const userRoles = getUserRolesFromToken(token);
    if (!token) {
      console.error("Access token is missing.");
      navigate("/sign-in-brand");
      return;
    }

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let response;
    try {
      response = await fetch(apiUrl + "/api/v1/brand-admin/brand", options);
    } catch {
      if (response.status !== 200) {
        console.error("Error fetching data. Status: ", response.status);
        navigate("/sign-in-brand");
        return;
      }
    }

    const responseData = await response.json();
    console.log(responseData);
    setFirstLogin(
      !(
        responseData.responseData.formApproved &&
        responseData.responseData.contractApproved
      )
    );
    setBrandCategories(responseData.responseData.brandCategories);
    // Set formatted created date
    const formattedCreatedDate = formatDate(responseData.responseData.created);
    setFormattedCreated(formattedCreatedDate);

    const { requestId } = responseData.metadata;

    setRequestId(requestId);

    const {
      id,
      status,
      firstName,
      lastName,
      phoneNumber,
      email,
      url,
      brandName,
      companyName,
      taxOffice,
      taxNumber,
      giro,
      storeCount,
      companyPhoneNumber,
      logo,
      invoiceAdress,
      instagramUrl,
      facebookUrl,
      facebookConnected,
      tiktokConnected,
      youtubeUrl,
      tiktokUrl,
      uuid,
      twitterUrl,
      created,
    } = responseData.responseData;
    const editedSocialMediaData = setUsernamesFromApiData(
      responseData.responseData
    );
    setId(id);
    setFacebookConnected(facebookConnected || "");
    setTiktokConnected(tiktokConnected || "");
    setUUID(uuid);
    setStatus(status);
    setFirstName(firstName || "");
    setLastName(lastName || "");
    setPhoneNumber(phoneNumber || "");
    setEmail(email || "");
    setUrl(url || "");
    setBrandName(brandName || "");
    setCompanyName(companyName || "");
    setTaxOffice(taxOffice || "");
    setLogo(logo || "");
    setTaxNumber(taxNumber || "");
    setGiro(giro || "");
    setStoreCount(storeCount || "");
    setCompanyPhoneNumber(companyPhoneNumber || "");
    setInvoiceAdress(invoiceAdress || "");
    setInstagramUrl(editedSocialMediaData.instagramUrl || "");
    setFacebookUrl(editedSocialMediaData.facebookUrl || "");
    setYoutubeUrl(editedSocialMediaData.youtubeUrl || "");
    setTiktokUrl(editedSocialMediaData.tiktokUrl || "");
    setTwitterUrl(editedSocialMediaData.twitterUrl || "");
    setCreated(created || "");
    setIsChecked(checked || false);
    const invoiceAdressParts = responseData.responseData.invoiceAdress
      ? responseData.responseData.invoiceAdress.split("|")
      : [];

    setUlke(invoiceAdressParts[0] || "");
    setSehir(invoiceAdressParts[1] || "");
    setIlce(invoiceAdressParts[2] || "");
    setMahalle(invoiceAdressParts[3] || "");
    setCadde(invoiceAdressParts[4] || "");
    setSokak(invoiceAdressParts[5] || "");
    setNo(invoiceAdressParts[6] || "");
    setPostaKodu(invoiceAdressParts[7] || "");
  };

  const validate = (value) => {
    return;
  };

  function formatStoreCount(storeCount) {
    // Aralık değerini "_" karakterinden bölelim
    const [title, rangeStart, rangeEnd] = storeCount.split("_");

    // Aralık değerlerini birleştirip istediğimiz formatta döndürelim
    return `${rangeStart}-${rangeEnd}`;
  }
  function formatGiro(giro) {
    // "RANGE_" kısmını çıkaralım
    const rangePart = giro.replace("RANGE_", "");

    // Alt çizgi (_) karakterini boşlukla değiştirelim
    const formattedRange = rangePart.replace("_", " - ");

    // TL ekleyelim
    const formattedGiro = formattedRange + " TL";

    return formattedGiro;
  }

  const storeCountData = [];
  storeCountData.push({ storeCount: "RANGE_0_10", range: "0-10" });
  for (let i = 0; i <= 2500; i += 500) {
    const rangeStart = i;
    const rangeEnd = i + 500;
    const storeCountKey = `RANGE_${rangeStart}_${rangeEnd}`;
    const rangeLabel = `${rangeStart}-${rangeEnd}`;

    storeCountData.push({ storeCount: storeCountKey, range: rangeLabel });
  }

  function convertStoreCountLabel(storeCount) {
    const foundStoreCount = storeCountData.find(
      (item) => item.storeCount === storeCount
    );
    return foundStoreCount ? foundStoreCount.range : "Bilinmeyen Aralık";
  }
  const handleStoreCountChange = (e) => {
    const selectedValue = e.target.value;
    // Özel bir dönüşüm yaparak "0-10" seçeneğini "RANGE_0_10" olarak çevirin
    const convertedValue = convertStoreCountToRange(selectedValue);
    setSelectedStoreCount(convertedValue);
    setStoreCount(convertedValue);
  };

  function convertStoreCountToRange(storeCount) {
    const storeCountToRangeMap = {
      "0-500": "RANGE_0_500",
      "500-1000": "RANGE_100_200",
      "1000-1500": "RANGE_400_500",
      "1500-2000": "RANGE_500_600",
      "2500-3000": "RANGE_600_700",
      // Diğer dönüşümleri buraya ekleyin...
    };

    return storeCountToRangeMap[storeCount] || storeCount;
  }

  const [selectedStoreCount, setSelectedStoreCount] = useState("RANGE_0_10");

  const initiateOAuth = (event) => {
    event.preventDefault();
    const url = new URL(window.location.href);
    const hostname = url.hostname;
    console.log("https://" + hostname + brandTiktokRedirectUri);
    try {
      const codeVerifier = generateRandomString(64);
      const codeChallenge = generateCodeChallengeFromVerifier(codeVerifier);

      // Construct the URL for TikTok's OAuth authorization
      const url = new URL("https://www.tiktok.com/v2/auth/authorize");
      url.searchParams.append("client_key", "sbawu1k3uezqj373nk");
      url.searchParams.append(
        "scope",
        "user.info.basic,user.info.stats,user.info.profile,video.list"
      );
      url.searchParams.append("response_type", "code");
      url.searchParams.append(
        "redirect_uri",
        "https://" + hostname + brandTiktokRedirectUri
      );
      url.searchParams.append("state", id);
      url.searchParams.append("code_challenge", codeChallenge);
      url.searchParams.append("code_challenge_method", "S256");

      // Redirect the user to the TikTok OAuth authorization page
      window.location.href = url.toString();
    } catch (error) {
      console.error("Error initiating OAuth:", error);
    }
  };
  // On the page specified in `redirect_uri`, e.g., https://admin.dev.soty.io/brand/settings/profile

  // Function to get query parameters from the URL
  const getQueryParams = () => {
    const params = new URLSearchParams(window.location.search);

    return {
      code: params.get("code"),
      state: params.get("state"),
    };
  };

  // Extract the authorization code from the URL
  const handleOAuthRedirect = () => {
    const { code, state } = getQueryParams();

    if (code) {
      console.log("Authorization Code:", code);
      tiktokLogin({ token: code });
      // Here you can send the code to your server to exchange it for an access token
      // or handle it according to your application's logic.
    } else {
      console.log("Authorization code not found in URL.");
    }
  };

  const tiktokLogin = async ({ token }) => {
    const body = {
      socialMedia: "TIKTOK",
      token: token,
    };
    console.log("BODY", body);

    try {
      // Make the API call
      const response = await CallAPI({
        method: "PUT",
        endPoint: apiUrl + "/api/v1/brand-user/social-media",
        body: body,
      });

      console.log(response);

      // Process response data
      setFacebookConnected(
        response?.data?.responseData?.facebookConnected || ""
      );
      setTiktokConnected(response?.data?.responseData?.tiktokConnected || "");

      // Clear URL search parameters if the response is successful
      if (window.location.search) {
        const url = new URL(window.location);
        url.search = ""; // Clear the search params
        window.history.replaceState({}, document.title, url.toString()); // Update the URL in the browser without reloading
      }
    } catch (error) {
      console.error(
        "Error during TikTok login:",
        error.response ? error.response.data : error.message
      );
    }
  };

  // Call the function to handle the redirect

  // Helper function to generate a random string
  function generateRandomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  // Helper function to generate code challenge from code verifier
  async function generateCodeChallengeFromVerifier(codeVerifier) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hash = await crypto.subtle.digest("SHA-256", data);
    const base64 = btoa(String.fromCharCode(...new Uint8Array(hash)));
    return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
  }

  return (
    <div className="dashboard-container">
      {loaderState && <Loader />}
      {subBrandToDelete && (
        <AreYouSure
          questionText={t("areYouSureToDeleteThisBrand")}
          isNegative={true}
          isCondition={true}
          acceptText={t("delete")}
          cancelText={t("cancel")}
          onCancel={() => setSubBrandToDelete()} // Make sure to handle this properly
          onAccept={() => {
            deleteSubBrand(subBrandToDelete.id);
            setSubBrandToDelete(null); // Reset the state after deletion
          }}
          content={
            <div className="brand-admin-info-container">
              <div className="brand-admin-info-container-title-container"></div>
              <div className="brand-admin-info-container-wrapper">
                <div className="brand-admin-info-container-row">
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">Marka Adı:</div>
                    <div className="brand-admin-input-area">
                      <input
                        id="brandName"
                        className="brand-admin-input"
                        type="text"
                        readOnly
                        value="AzimStore" // Ideally, this should come from a state or prop
                      />
                    </div>
                  </div>
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">Marka Logo:</div>
                    <img
                      className="sub-brand-image-container"
                      src={imagesUrl + "/" + subBrandToDelete.logo}
                      alt="brandLogo"
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        />
      )}
      <div className="content-location-container">
        <a className="content-location-container-href" href="#">
          <img src={arrow} alt="arrow" />
        </a>
        <a className="content-location-container-link-text" href="#">
          <div className="older">{t("userSettings")}</div>
        </a>
        <img src={downArrow} className="backArrow" alt="down-arrow" />
        <div className="current">{t("profileInfo")}</div>
      </div>
      <div className="brand-admin-content-container">
        <div
          className="brand-admin-content-column-left"
        >
          <div className="brand-admin-application-date-info-button-container">
            <div className="brand-admin-application-date-info-container">
              <div className="brand-admin-application-date-info">
                <img src={calendar} alt="calendar" />
                <div className="brand-admin-application-date-info-text">
                  {t("applicationDate")} :
                </div>
                <div className="brand-admin-application-date-info-date-time">
                  {formattedCreated}
                </div>
              </div>
              <div className="brand-admin-application-request-info-container">
                <div className="brand-admin-application-request-info-title">
                  {t("requestId")} :
                </div>
                <div className="brand-admin-application-request-id">
                  {requestId}
                </div>
              </div>
              {publicApiKey && (
                <div className="brand-admin-application-request-info-container">
                  <div className="brand-admin-application-request-info-title">
                    {t("publicApiKey")} :
                  </div>
                  <div className="brand-admin-application-request-id">
                    {publicApiKey}
                  </div>
                </div>
              )}

              {privateApiKey && (
                <div className="brand-admin-application-request-info-container">
                  <div className="brand-admin-application-request-info-title">
                    {t("privateApiKey")} :
                  </div>
                  <div className="brand-admin-application-request-id">
                    {privateApiKey}
                  </div>
                </div>
              )}
            </div>
            <div className="brand-admin-application-button-container">
              <button className="brand-admin-application-developer-kit-button">
                {t("developerKit")}
              </button>
            </div>
          </div>

          <div className="brand-admin-inputs-container">
            <div className="brand-admin-info-container">
              <div className="brand-admin-info-container-title-container">
                {isForm1Filled && (
                  <img
                    src={checkImage}
                    alt="ico"
                    className="brand-admin-info-container-title-img"
                  />
                )}
                <div className="brand-admin-info-container-title">
                  {t("personalInfo")}
                </div>
              </div>
              <div className="brand-admin-info-container-wrapper">
                <div className="brand-admin-info-container-row">
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">{t("name")}:</div>
                    <div className="brand-admin-input-area">
                      <input
                        id="firstName"
                        className="brand-admin-input"
                        type="text"
                        value={firstName}
                        onChange={handleChange}
                        readOnly={true}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          firstName !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">
                      {t("surname")}:
                    </div>
                    <div className="brand-admin-input-area">
                      <input
                        id="lastName"
                        className="brand-admin-input"
                        type="text"
                        value={lastName}
                        onChange={handleChange}
                        readOnly={true}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          lastName !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                </div>
                <div className="brand-admin-info-container-row">
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">
                      {t("phoneNumber")}:
                    </div>
                    <div className="brand-admin-input-area">
                      <input
                        id="phoneNumber"
                        className="brand-admin-input"
                        type="text"
                        value={phoneNumber}
                        onChange={handleChange}
                        readOnly={true}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          phoneNumber !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">{t("email")}:</div>
                    <div className="brand-admin-input-area">
                      <input
                        id="email"
                        className="brand-admin-input"
                        type="text"
                        value={email}
                        onChange={handleChange}
                        readOnly={true}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          email !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="brand-admin-info-container">
              <div className="brand-admin-info-container-title-container">
                {isForm2Filled && (
                  <img
                    src={checkImage}
                    alt="ico"
                    className="brand-admin-info-container-title-img"
                  />
                )}

                <div className="brand-admin-info-container-title">
                  {t("brandInfo")}
                </div>
              </div>
              <div className="brand-admin-info-container-wrapper">
                <div className="brand-admin-info-container-row">
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">
                      {t("domainName")}:
                    </div>

                    <div className="brand-admin-input-area">
                      <input
                        id="url"
                        className="brand-admin-input"
                        type="text"
                        value={url}
                        onChange={handleChange}
                        readOnly={true}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          url !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>

                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">
                      {t("brandName")}:
                    </div>
                    <div className="brand-admin-input-area">
                      <input
                        id="brandName"
                        className="brand-admin-input"
                        type="text"
                        value={brandName}
                        onChange={handleChange}
                        readOnly={true}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          brandName !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                  {!logoReadOnly ? (
                    <div className="brand-admin-input-container">
                      <div className="brand-admin-input-title image-dropzone-container">
                        {t("brandLogo")}:
                      </div>
                      <ImageDropzone
                        className="brand-admin-input"
                        onImageUpload={handleImageUpload}
                      />
                    </div>
                  ) : (
                    <div className="brand-admin-input-container"></div>
                  )}
                </div>
              </div>
            </div>

            <div className="brand-admin-info-forced-row">
              <div className="brand-admin-info-container">
                <div className="brand-admin-info-container-title-container">
                  {newSubBrandName && newSubBrandImage && (
                    <img
                      src={checkImage}
                      alt="ico"
                      className="brand-admin-info-container-title-img"
                    />
                  )}

                  <div className="brand-admin-info-container-title">
                    {t("addNewSubBrand")}
                  </div>
                </div>
                <div className="brand-admin-info-container-wrapper">
                  <div className="brand-admin-info-container-row">
                    <div className="brand-admin-input-container">
                      <div className="brand-admin-input-title">
                        {t("brandLogo")}:
                      </div>

                      <ChallengeImageDropzone
                        brandLogo={true}
                        onImageUpload={(file) =>
                          handleSubBrandImageUpload(file)
                        }
                      />
                    </div>
                    <div className="brand-admin-input-container">
                      <div className="brand-admin-input-title">
                        {t("brandName")}:
                      </div>
                      <div className="brand-admin-input-area">
                        <input
                          id="brandName"
                          className="brand-admin-input"
                          type="text"
                          value={newSubBrandName}
                          onChange={(e) => setNewSubBrandName(e.target.value)}
                        />
                        {newSubBrandName && (
                          <img
                            src={tick}
                            alt="ico"
                            className={`brand-admin-input-img ${
                              brandName !== "" ? "slide-in" : ""
                            }`}
                          />
                        )}
                      </div>
                    </div>

                    <div className="brand-admin-input-container">
                      <div className="brand-admin-input-title hidden">
                        {t("brandLogo")}:
                      </div>
                      <button
                        className="button save-sub-brand-button"
                        onClick={() => handleSubBrandSubmit()}
                        disabled={readOnly}
                      >
                        {t("save")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="brand-admin-info-forced-row">
              {subBrands &&
                Array.isArray(subBrands) &&
                subBrands.map((brand, index) => (
                  <div key={index} className="brand-admin-info-container">
                    <div className="brand-admin-info-container-title-container">
                      <img
                        src={checkImage}
                        alt="ico"
                        className="brand-admin-info-container-title-img"
                      />

                      <div className="brand-admin-info-container-title">
                        {t("subBrandInfo")}
                      </div>
                    </div>
                    <div className="brand-admin-info-container-wrapper">
                      <div className="brand-admin-info-container-row">
                        <div className="brand-admin-input-container">
                          <div className="brand-admin-input-title">
                            {t("brandName")}:
                          </div>
                          <div className="brand-admin-input-area">
                            <input
                              id="brandName"
                              className="brand-admin-input"
                              type="text"
                              value={brand.name}
                              readOnly={true}
                            />
                            <img
                              src={tick}
                              alt="ico"
                              className={`brand-admin-input-img ${
                                brand.name !== "" ? "slide-in" : ""
                              }`}
                            />
                          </div>
                        </div>

                        <div className="brand-admin-input-container">
                          <div className="brand-admin-input-title">
                            {t("brandLogo")}:
                          </div>
                          <img
                            className="sub-brand-image-container"
                            src={imagesUrl + "/" + brand.logo}
                            alt="brandLogo"
                          />
                        </div>
                        
                      </div>
                      <button
                          className="delete-sub-brand-button"
                          onClick={() => {
                            setSubBrandToDelete(brand);
                          }}
                        >
                          {t("delete")}
                        </button>
                    </div>
                    
                  </div>
                ))}
            </div>
            <div className="brand-admin-info-container">
              <div className="brand-admin-info-container-title-container">
                {isForm3Filled && (
                  <img
                    src={checkImage}
                    alt="ico"
                    className="brand-admin-info-container-title-img"
                  />
                )}
                <div className="brand-admin-info-container-title">
                  {t("companyInfo")}
                </div>
              </div>
              <div className="brand-admin-info-container-wrapper">
                <div className="brand-admin-info-container-row">
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">
                      {t("companyName")}:
                    </div>
                    <div className="brand-admin-input-area">
                      <input
                        id="companyName"
                        className="brand-admin-input"
                        type="text"
                        value={companyName}
                        onChange={handleChange}
                        readOnly={true}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          companyName !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">
                      {t("taxOffice")}:
                    </div>
                    <div className="brand-admin-input-area">
                      <input
                        id="taxOffice"
                        className="brand-admin-input"
                        type="text"
                        value={taxOffice}
                        onChange={handleChange}
                        readOnly={true}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          taxOffice !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                </div>

                <div className="brand-admin-info-container-row">
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">
                      {t("taxNumber")}:
                    </div>
                    <div className="brand-admin-input-area">
                      <input
                        id="taxNumber"
                        className="brand-admin-input"
                        type="text"
                        value={taxNumber}
                        onChange={handleChange}
                        readOnly={true}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          taxNumber !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="brand-admin-info-container">
              <div className="brand-admin-info-container-title-container">
                {isForm4Filled && (
                  <img
                    src={checkImage}
                    alt="ico"
                    className="brand-admin-info-container-title-img"
                  />
                )}
                <div className="brand-admin-info-container-title">
                  {t("additionalCompanyInfo")}
                </div>
              </div>
              <div className="brand-admin-info-container-wrapper">
                <div className="brand-admin-info-container-row">
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">
                      {t("revenue")}:
                    </div>
                    <div className="brand-admin-input-area">
                      <input
                        id="giro"
                        className="brand-admin-input"
                        type="text"
                        value={formatGiro(giro)}
                        onChange={handleChange}
                        readOnly={true}
                      />

                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          giro !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">
                      {t("storeCount")}:
                    </div>
                    <div className="brand-admin-input-area">
                      <input
                        id="storeCount"
                        className="brand-admin-input"
                        type="text"
                        value={formatStoreCount(storeCount)}
                        onChange={handleChange}
                        readOnly={true}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          storeCount !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="brand-admin-info-container">
              <div className="brand-admin-info-container-title-container">
                {isForm4Filled && (
                  <img
                    src={checkImage}
                    alt="ico"
                    className="brand-admin-info-container-title-img"
                  />
                )}
                <div className="brand-admin-info-container-title">
                  {t("brandCategories")}
                </div>
              </div>

              <div className="brand-admin-info-container-wrapper">
                <div className="brand-admin-info-container-row">
                  {brandCategories.map((category) => (
                    <div className="brand-admin-input-container">
                      <div className="brand-admin-input-area">
                        <input
                          id="storeCount"
                          className="brand-admin-input"
                          type="text"
                          value={
                            navigator.language.startsWith("en")
                              ? category.categoryEn
                              : category.category
                          }
                          readOnly={true}
                        />
                        <img
                          src={tick}
                          alt="ico"
                          className={`brand-admin-input-img ${
                            storeCount !== "" ? "slide-in" : ""
                          }`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {firstLogin && (
              <div className="brand-admin-info-container">
                <div className="brand-admin-info-container-title-container">
                  <img
                    src={checkImage}
                    alt="ico"
                    className="brand-admin-info-container-title-img"
                  />

                  <div className="brand-admin-info-container-title">
                    {t("firstCampaignDiscountPercentage")}
                  </div>
                </div>

                <div className="brand-admin-info-container-wrapper">
                  <div className="brand-admin-info-container-row">
                    <SingleNumberInput
                      title={t("firstCampaignDiscountPercentage")}
                      inputPlaceholder={5}
                      value={firstCampaignDiscountPercentage}
                      titleDescription={t(
                        "firstCampaignDiscountPercentageDesc"
                      )}
                      isMandatory={true}
                      onChange={(e) => {
                        if (e.target.value >= 100) {
                          setFirstCampaignDiscountPercentage(100);
                        } else {
                          setFirstCampaignDiscountPercentage(e.target.value);
                        }
                      }}
                      onBlur={(e) => {
                        if (e.target.value >= 100) {
                          setFirstCampaignDiscountPercentage(100);
                        } else if (e.target.value <= 5) {
                          setFirstCampaignDiscountPercentage(5);
                        } else {
                          setFirstCampaignDiscountPercentage(e.target.value);
                        }
                      }}
                      infoText={[t("min5")]}
                    />
                  </div>
                </div>
              </div>
            )}

            {/*
            <div className="brand-admin-info-container">
              <div className="brand-admin-info-container-title-container">
                <img
                  src= {checkImage}
                  alt="ico"
                  className="brand-admin-info-container-title-img"
                />
                <div className="brand-admin-info-container-title">
                  Odeme Bilgileri
                </div>
              </div>
              <CreditCard/>
            </div>
            */}
            <div className="brand-admin-info-container">
              <div className="brand-admin-info-container-title-container">
                {isForm5Filled && (
                  <img
                    src={checkImage}
                    alt="ico"
                    className="brand-admin-info-container-title-img"
                  />
                )}
                <div className="brand-admin-info-container-title">
                  {t("invoiceInfo")}
                </div>
              </div>
              <div className="brand-admin-info-container-wrapper">
                <div className="brand-admin-info-container-row">
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">
                      {t("country")}:
                    </div>
                    <div className="brand-admin-input-area">
                      <input
                        id="ulke"
                        className="brand-admin-input"
                        type="text"
                        value={ulke}
                        onChange={handleChange}
                        readOnly={readOnly}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          ulke !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">{t("city")}:</div>
                    <div className="brand-admin-input-area">
                      <input
                        id="sehir"
                        className="brand-admin-input"
                        type="text"
                        defaultValue={sehir}
                        onChange={handleChange}
                        readOnly={readOnly}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          sehir !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">
                      {t("district")}:
                    </div>
                    <div className="brand-admin-input-area">
                      <input
                        id="ilce"
                        className="brand-admin-input"
                        type="text"
                        defaultValue={ilce}
                        onChange={handleChange}
                        readOnly={readOnly}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          ilce !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                </div>
                <div className="brand-admin-info-container-row">
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">
                      {t("neighborhood")}:
                    </div>
                    <div className="brand-admin-input-area">
                      <input
                        id="mahalle"
                        className="brand-admin-input"
                        type="text"
                        defaultValue={mahalle}
                        onChange={handleChange}
                        readOnly={readOnly}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          mahalle !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">
                      {t("street")}:
                    </div>
                    <div className="brand-admin-input-area">
                      <input
                        id="cadde"
                        className="brand-admin-input"
                        type="text"
                        defaultValue={cadde}
                        onChange={handleChange}
                        readOnly={readOnly}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          cadde !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">
                      {t("avenue")}:
                    </div>
                    <div className="brand-admin-input-area">
                      <input
                        id="sokak"
                        className="brand-admin-input"
                        type="text"
                        defaultValue={sokak}
                        onChange={handleChange}
                        readOnly={readOnly}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          sokak !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                </div>

                <div className="brand-admin-info-container-row">
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">{t("no")}:</div>
                    <div className="brand-admin-input-area">
                      <input
                        id="no"
                        className="brand-admin-input"
                        type="text"
                        defaultValue={no}
                        onChange={handleChange}
                        readOnly={readOnly}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          no !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">
                      {t("postalCode")}:
                    </div>
                    <div className="brand-admin-input-area">
                      <input
                        id="postaKodu"
                        className="brand-admin-input"
                        type="text"
                        defaultValue={postaKodu}
                        onChange={handleChange}
                        readOnly={readOnly}
                      />
                      <img
                        src={tick}
                        alt="ico"
                        className={`brand-admin-input-img ${
                          postaKodu !== "" ? "slide-in" : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className="brand-admin-input-container">
                    <div className="brand-admin-input-title">
                      <br />
                    </div>
                    <button
                      className="brand-admin-input-area invoice-button"
                      onClick={handleInvoiceSubmit}
                      disabled={readOnly}
                    >
                      {t("save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/*
                <div className="brand-admin-info-container">
              <div className="brand-admin-info-container-title-container">
                <img
                  src= {checkImage}
                  alt="ico"
                  className="brand-admin-info-container-title-img"
                />
                <div className="brand-admin-info-container-title">
                  Sadakat Kart
                </div>
              </div>
              <div className="brand-admin-info-container-wrapper">
                <div className="brand-admin-info-container-row"></div>
              </div>
            </div>
              */}

            <div className="brand-admin-info-container">
              <div className="brand-admin-info-container-title-container">
                {isForm6Filled && (
                  <img
                    src={checkImage}
                    alt="ico"
                    className="brand-admin-info-container-title-img"
                  />
                )}
                <div className="brand-admin-info-container-title">
                  {t("socialMedia")}
                </div>
              </div>
              <div className="brand-admin-info-container-social-wrapper">
                {/* Instagram */}
                <div className="brand-admin-info-container-row brand-admin-social-row facebook-column">
                  <div className="brand-admin-social-row-input-area">
                    <div className="brand-admin-social-row-left">
                      <img
                        className="brand-admin-social-icon"
                        alt="inst"
                        src={instagramIcon}
                      ></img>
                      <div className="social-inputs-container">
                        <label className="brand-admin-input-area social-input-left">
                          https://www.instagram.com/
                        </label>
                        <input
                          className="brand-admin-input-area social-input-right"
                          defaultValue={instagramUrl}
                          readOnly={readOnly}
                          onBlur={(event) =>
                            handleInputChange(
                              "instagramUrl",
                              event.target.value
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="brand-admin-social-row-right">
                      <button className="brand-admin-social-integration-button display-none">
                        {t("integrate")}
                      </button>
                      {instagramUrl ? (
                        <img
                          className="brand-admin-social-check-icon"
                          alt="check"
                          src={checkImage}
                        ></img>
                      ) : null}
                    </div>
                  </div>
                  <div className="brand-admin-social-row-button-area">
                    {/*<InstagramLoginButton onClick={null}/>*/}
                  </div>
                </div>
                {/* Youtube */}
                <div className="brand-admin-info-container-row brand-admin-social-row facebook-column display-none">
                  <div className="brand-admin-social-row-input-area">
                    <div className="brand-admin-social-row-left">
                      <img
                        className="brand-admin-social-icon"
                        alt="inst"
                        src={youtubeIcon}
                      ></img>
                      <div className="social-inputs-container">
                        <label className="brand-admin-input-area social-input-left">
                          https://www.youtube.com/channel/
                        </label>
                        <input
                          className="brand-admin-input-area social-input-right"
                          defaultValue={youtubeUrl}
                          readOnly={readOnly}
                          onBlur={(event) =>
                            handleInputChange("youtubeUrl", event.target.value)
                          }
                        />
                      </div>
                    </div>

                    <div className="brand-admin-social-row-right">
                      <button className="brand-admin-social-integration-button hidden">
                        {t("integrate")}
                      </button>
                      {youtubeUrl ? (
                        <img
                          className="brand-admin-social-check-icon"
                          alt="check"
                          src={checkImage}
                        ></img>
                      ) : null}
                    </div>
                  </div>
                  <div className="brand-admin-social-row-button-area">
                    {/*<GoogleLoginButton onClick={null}/>*/}
                  </div>
                </div>
                {/* Facebook */}

                <div className="brand-admin-info-container-row brand-admin-social-row facebook-column">
                  <div className="brand-admin-social-row-input-area">
                    <div className="brand-admin-social-row-left">
                      <img
                        className="brand-admin-social-icon"
                        alt="inst"
                        src={facebookIcon}
                      ></img>

                      <button
                        className={`brand-admin-social-integration-button ${
                          facebookSignIn ? "visibility-hidden" : ""
                        }`}
                        onClick={(event) => handleSignInWithFacebook(event)}
                      >
                        {facebookConnected
                          ? t("integrateAgain")
                          : t("integrate")}
                      </button>

                      {facebookConnected ? (
                        <img
                          className="brand-admin-social-check-icon"
                          alt="check"
                          src={checkImage}
                        ></img>
                      ) : null}
                    </div>
                  </div>
                  <div className="brand-admin-social-row-button-area"></div>
                </div>

                {/* Twitter */}

                <div className="brand-admin-info-container-row brand-admin-social-row facebook-column display-none">
                  <div className="brand-admin-social-row-input-area">
                    <div className="brand-admin-social-row-left">
                      <img
                        className="brand-admin-social-icon"
                        alt="inst"
                        src={xIcon}
                      ></img>
                      <div className="social-inputs-container">
                        <label className="brand-admin-input-area social-input-left">
                          https://www.twitter.com/
                        </label>
                        <input
                          className="brand-admin-input-area social-input-right"
                          defaultValue={twitterUrl}
                          readOnly={readOnly}
                          onBlur={(event) =>
                            handleInputChange("twitterUrl", event.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="brand-admin-social-row-right ">
                      <button className="brand-admin-social-integration-button hidden">
                        {t("integrate")}
                      </button>
                      {twitterUrl ? (
                        <img
                          className="brand-admin-social-check-icon"
                          alt="check"
                          src={checkImage}
                        ></img>
                      ) : null}
                    </div>
                  </div>
                  <div className="brand-admin-social-row-button-area">
                    {/*<TwitterLoginButton onClick={null} />*/}
                  </div>
                </div>
                {/* TikTok */}

                <div className="brand-admin-info-container-row brand-admin-social-row facebook-column">
                  <div className="brand-admin-social-row-input-area">
                    <div className="brand-admin-social-row-left">
                      <img
                        className="brand-admin-social-icon"
                        alt="inst"
                        src={tiktokIcon}
                      ></img>

                      <button
                        className="brand-admin-social-integration-button"
                        onClick={(event) => initiateOAuth(event)}
                      >
                        {tiktokConnected ? t("integrateAgain") : t("integrate")}
                      </button>

                      {tiktokConnected ? (
                        <img
                          className="brand-admin-social-check-icon"
                          alt="check"
                          src={checkImage}
                        ></img>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="brand-admin-info-container">
              <div className="brand-admin-info-container-title-container">
                {isForm7Filled && (
                  <img
                    src={checkImage}
                    alt="ico"
                    className="brand-admin-info-container-title-img"
                  />
                )}
                <div className="brand-admin-info-container-title">
                  {t("contracts")}:
                </div>
              </div>
              <div className="brand-admin-info-container-wrapper">
                <div className="brand-admin-info-container-row">
                  <div className="brand-admin-contract-text">
                    {contractTexts.contractText1}
                  </div>
                </div>
                {firstLogin && (
                  <div className="brand-admin-info-container-row brand-admin-info-check-container">
                    <input
                      type="checkbox"
                      id="EULACheck1"
                      name="scales"
                      onChange={checkForm}
                      disabled={readOnly}
                    />
                    <label htmlFor="scales">{t("readAndApprove")}</label>
                  </div>
                )}

                <div className="brand-admin-info-container-row">
                  <div className="brand-admin-contract-text">
                    {contractTexts.contractText2}
                  </div>
                </div>
                {firstLogin && (
                  <div className="brand-admin-info-container-row brand-admin-info-check-container">
                    <input
                      type="checkbox"
                      id="EULACheck2"
                      name="scales"
                      onChange={checkForm}
                      disabled={readOnly}
                    />
                    <label htmlFor="scales">{t("readAndApprove")}</label>
                  </div>
                )}
              </div>
            </div>
          </div>
          {firstLogin && (
            <div className="brand-admin-submit-container">
              <label>
                <input
                  className="submit-input"
                  id="formApproveInput"
                  type="checkbox"
                  onChange={checkForm}
                />{" "}
                {t("approve")}
              </label>
              <button
                className="brand-admin-info-container brand-admin-submit-button"
                type="submit"
                onClick={(e) => {
                  handleFormAndSocialSubmit(e);
                }}
                disabled={!formApproved || readOnly}
              >
                {t("submit")}
              </button>
            </div>
          )}
        </div>
        <div className="brand-admin-content-column-right">
          <div className="progress-bar-container">
            <div className="progress-bar">
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
            <div className="progress-label">
              {t("profileCompletion", { progress: progress.toFixed(2) })}
            </div>
          </div>
          <BrandAdminProfileRightContainer
            t={t}
            brandCategories={brandCategories}
            checkImage={checkImage}
            isForm1Filled={isForm1Filled}
            firstName={firstName}
            lastName={lastName}
            phoneNumber={phoneNumber}
            email={email}
            isForm2Filled={isForm2Filled}
            url={url}
            brandName={brandName}
            imagesUrl={imagesUrl}
            logo={logo}
            defaultSotyLogo={defaultSotyLogo}
            isForm3Filled={isForm3Filled}
            companyName={companyName}
            taxOffice={taxOffice}
            taxNumber={taxNumber}
            isForm4Filled={isForm4Filled}
            formatGiro={formatGiro}
            giro={giro}
            formatStoreCount={formatStoreCount}
            storeCount={storeCount}
            failedIcon={failedIcon}
            isForm5Filled={isForm5Filled}
            ulke={ulke}
            sehir={sehir}
            ilce={ilce}
            mahalle={mahalle}
            cadde={cadde}
            sokak={sokak}
            no={no}
            postaKodu={postaKodu}
            isForm6Filled={isForm6Filled}
            instagramIcon={instagramIcon}
            instagramUrl={instagramUrl}
            youtubeIcon={youtubeIcon}
            youtubeUrl={youtubeUrl}
            facebookIcon={facebookIcon}
            facebookUrl={facebookUrl}
            xIcon={xIcon}
            twitterUrl={twitterUrl}
            tiktokIcon={tiktokIcon}
            tiktokUrl={tiktokUrl}
          />
        </div>
      </div>
    </div>
  );
};

export default BrandAdminProfile;
