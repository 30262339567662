import React, { useState, useEffect } from "react";
import arrow from "../../../../../assets/media/arrow.png";
import "../../../../../assets/css/ChallengeDashboard.css";
import downArrow from "../../../../../assets/media/down-arrow.png";
import SearchIcon from "../../../../../assets/media/SearchIcon.svg";
import SortByButton from "../../../../button/sortByButton";
import FilterButton from "../../../../button/filterButton";
import DatePickerButton from "../../../../button/datePickerButton";
import TotalCards from "../../../../cards/TotalCards";
import CreateChallengeButton from "../../../../button/createChallengeButton";
import TabButton from "../../../../button/tabButton";
import GridListSwitchButton from "../../../../button/gridListSwitchButton";
import ChallengeCard from "../../../../cards/challengeCard";
import DynamicTable from "../../../../tables/DynamicTable";
import facebookIcon from "../../../../../assets/media/facebook.svg";
import instagramIcon from "../../../../../assets/media/instagram.svg";
import twitterIcon from "../../../../../assets/media/x-twitter.svg";
import youtubeIcon from "../../../../../assets/media/youtube.svg";
import tiktokIcon from "../../../../../assets/media/tiktok.svg";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import { t } from "i18next";
import {
  getCookie,
  getUserTypeFromToken,
  isMyTokenExpired,
} from "../../../auth/tokenUtils";
import { apiUrl, imagesUrl } from "../../../../../config";
import axios from "axios";
import Pagination from "../../../../tables/Pagination";
import { useNavigate } from "react-router-dom";
import ItemsPerPageButton from "../../../../button/itemsPerPageButton";
import { isIn } from "validator";
import Loader from "../../../../popup/loader";
import NavigationBar from "../../../../utils/navigation_bar/navigationBar";

const ChallengeDashboard = () => {
  const [viewType, setViewType] = useState("GRID");
  const { t, i18n } = useTranslation("challengeDashboard");
  const token = getCookie("access_token");
  const [challenges, setChallenges] = useState();
  const [pageMetaData, setPageMetaData] = useState();
  const [clickedPage, setClickedPage] = useState("0");
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabKey, setActiveTabKey] = useState("ALL");
  const [responseData, setResponseData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [challengesForList, setChallengesForList] = useState([]);
  const [itemPerPage, setItemPerPage] = useState(20);
  const [challengeStartDatesWithProvider, setChallengeStartDatesWithProvider] =
    useState([]);
  const [prevClickedPage, setPrevClickedPage] = useState("0");
  const [filterChallengeType, setFilterChallengeType] = useState([]);
  const [filterSocialChallengeType, setFilterSocialChallengeType] = useState(
    []
  );
  const [filterSocialProvider, setFilterSocialProvider] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState();
  const [filterEndDate, setFilterEndDate] = useState();
  const [sortChallengesBy, setSortChallengesBy] = useState();

  //templer, diger social providerlar gelene kadar gecici sureligine endpoint icin editlenmis degiskenler.
  const [tempSocialChallengeType, setTempSocialChallengeType] = useState();
  const [tempChallengeType, setTempChallengeType] = useState();
  const [tempSocialProvider, setTempSocialProvider] = useState();
  const [tempStartDate, setTempStartDate] = useState();
  const [tempEndDate, setTempEndDate] = useState();

  const [endpoint, setEndpoint] = useState();
  const [loaderState, setLoaderState] = useState(false);

  const [brandId, setBrandID] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("checkboxStates");

    if (!token) {
      // Handle the case where the token is empty or undefined
      console.log("JWT token is empty or undefined");
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(token)) {
      document.cookie =
        "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      toast.error(t("error401"));
      navigate("/sign-in-brand");
    }
  }, []);

  const sortItems = [
    { id: 1, text: t("newToOld"), string: "NEWEST" },
    { id: 2, text: t("oldToNew"), string: "OLDEST" },
    { id: 3, text: t("highestParticipation"), string: "HIGH_PARTICIPATION" },
    { id: 4, text: t("lowestParticipation"), string: "LOW_PARTICIPATION" },
    { id: 5, text: t("highestSuccessRate"), string: "HIGH_SUCCESS_RATE" },
    { id: 6, text: t("lowestSuccessRate"), string: "LOW_SUCCESS_RATE" },
  ];

  const checkboxData = [
    {
      title: t("contentCreation"),
      challengeType: "SOCIAL_MEDIA",
      socialChallengeType: "CONTENT_CREATION",
    },
    {
      title: t("interaction"),
      challengeType: "SOCIAL_MEDIA",
      socialChallengeType: "INTERACTION",
    },
    { title: t("survey"), challengeType: "SURVEY", socialChallengeType: "" },
  ];

  const checkboxSocialProviderData = [
    { title: "Facebook", count: 10, icon: facebookIcon, string: "FACEBOOK" },
    { title: "Instagram", count: 20, icon: instagramIcon, string: "INSTAGRAM" },
    /* { title: 'Twitter', count: 15, icon: twitterIcon, string: 'TWITTER'  },
         { title: 'YouTube', count: 30, icon: youtubeIcon, string: 'YOUTUBE'  },
         { title: 'TikTok', count: 25, icon: tiktokIcon, string: 'TIKTOK'  },*/
  ];

  const handleItemsPerPageChange = (item) => {
    setClickedPage("0");
    setItemPerPage(item);
  };

  const fetchChallenges = async () => {
    if (!endpoint) return;
    try {
      setLoaderState(true);

      /* const response = await axios.get(apiUrl + `/api/v1/brand-admin/brand/challenge/analytics?challengeFilter=${activeTabKey}&page=${clickedPage}&size=${itemPerPage}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            */

      const response = await axios.get(apiUrl + endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("used endpoint =", endpoint);
      setResponseData(response.data.responseData);
      setChallenges(
        response.data.responseData.detailAnalyticsResponsePageData.data
      );
      console.log(response.data.responseData);
      setPageMetaData(
        response.data.responseData.detailAnalyticsResponsePageData
      );
      setCurrentPage(
        response.data.responseData.detailAnalyticsResponsePageData.page + 1
      );

      if (response.data.responseData.detailAnalyticsResponsePageData.data) {
        // Tüm challenge başlangıç tarihlerini ve socialProvider bilgilerini al
        const challengeData =
          response.data.responseData.detailAnalyticsResponsePageData.data.map(
            (challenge) => ({
              date: challenge.challenge.startDate.split("T")[0],
              socialProvider: challenge.challenge.socialProvider,
              id: challenge.challenge.id,
              name: challenge.challenge.name,
            })
          );

        // challengeStartDates state'ini güncelle
        setChallengeStartDatesWithProvider(challengeData);
      }

      const transformedData =
        response.data.responseData.detailAnalyticsResponsePageData.data.map(
          (item) => {
            const challenge = item.challenge;

            const controlButton = () => {
              if (
                !getUserTypeFromToken(getCookie("access_token")) &&
                challenge.status === "DRAFTED"
              ) {
                return null;
              } else if (
                !getUserTypeFromToken(getCookie("access_token")) &&
                challenge.status === "RELEASED"
              ) {
                return (
                  <button
                    className="dynamic-table-button dashboard-table-button"
                    onClick={() =>
                      fetchSelectedChallengeToDetailPage(
                        challenge.id,
                        challenge
                      )
                    }
                  >
                    {t("showDetails")}
                  </button>
                );
              } else if (
                getUserTypeFromToken(getCookie("access_token")) &&
                challenge.status === "DRAFTED"
              ) {
                return (
                  <button
                    className="dynamic-table-button dashboard-table-button"
                    onClick={() =>
                      fetchSelectedChallenge(challenge.id, challenge)
                    }
                  >
                    {t("edit")}
                  </button>
                );
              } else if (
                getUserTypeFromToken(getCookie("access_token")) &&
                challenge.status === "RELEASED"
              ) {
                return (
                  <button
                    className="dynamic-table-button dashboard-table-button"
                    onClick={() =>
                      fetchSelectedChallengeToDetailPage(
                        challenge.id,
                        challenge
                      )
                    }
                  >
                    {t("showDetails")}
                  </button>
                );
              }
            };

            return {
              control: controlButton(),
              id: challenge.id,
              challengeLogo: (
                <img
                  src={imagesUrl + "/" + challenge.logo}
                  alt={`Logo for the challenge`}
                />
              ),
              description: challenge.description,
              name: challenge.name,
              challengeType:
                challenge.challengeType === "SOCIAL_MEDIA"
                  ? t("socialMedia")
                  : t("survey"),
              status: challenge.status,
              startDate: formatDateStringLocal(challenge.startDate),
              endDate: formatDateStringLocal(challenge.endDate),
              coinPrize: challenge.prize  + " = " + challenge.prize / 10 + t("TL"),

              successRate: item
                ? item.successRate
                  ? "%" + (item.successRate * 100).toFixed(1)
                  : "0"
                : "0",
              brand: {
                // ... (properties from brand)
              },
            };
          }
        );

      setChallengesForList(transformedData);
      setLoaderState(false);
    } catch (error) {
      setLoaderState(false);
      console.error("Error fetching data:", error);
      {
        error.response.data &&
          error.response.data.metadata &&
          error.response.data.metadata.message &&
          toast.error(error.response.data.metadata.message);
      }
      if (error.response && error.response.status === 401) {
        document.cookie =
          "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        toast.error(t("error401"));
        navigate("/sign-in-brand");
      } else {
        toast.error(error.message);
      }
    }
  };

  const handlePageChange = (page) => {
    setClickedPage(page);
  };

  const dataSets = [
    {
      headerTitle: t("total"),
      title: t("challengeCount"),
      subtitle: t("ChC"),
      data: responseData
        ? responseData.detailAnalyticsResponsePageData
          ? responseData.detailAnalyticsResponsePageData.count
          : "0"
        : "0",
    },
    {
      headerTitle: t("total"),
      title: t("sharedSotyCoin"),
      subtitle: t("SSC"),
      data: responseData
        ? responseData.totalGivenCoin
          ? responseData.totalGivenCoin
          : "0"
        : "0",
        subData: (responseData?.totalGivenCoin / 10 || 0 / 10)  + t("TL") 
    },
    {
      headerTitle: t("total"),
      title: t("challengeSuccessRate"),
      subtitle: t("CSR"),
      data: responseData
        ? responseData.successRatio
          ? "%" + (responseData.successRatio * 100).toFixed(2)
          : "0"
        : "0",
    },
  ];

  const handleTabClick = (clickedTab) => {
    let tab;
    switch (clickedTab.id) {
      case 0:
        tab = "ALL";
        break;
      case 1:
        tab = "ACTIVE";
        break;
      case 2:
        tab = "COMPLETED";
        break;
      case 3:
        tab = "UPCOMING";
        break;
      case 4:
        tab = "DRAFT";
        break;
      default:
        tab = "ALL"; // Set a default value in case the clickedTab.id is not matched
        break;
    }
    setActiveTabKey(tab);
    setActiveTab(clickedTab.id);
    setClickedPage("0");
  };

  const tabData = [
    { id: 0, title: t("allChallenges") },
    { id: 1, title: t("activeChallenges") },
    { id: 2, title: t("completedChallenges") },
    { id: 3, title: t("incomingChallenges") },
    { id: 4, title: t("draftedChallenges") },
  ];

  const getSocialIcon = (provider) => {
    switch (provider) {
      case "FACEBOOK":
        return facebookIcon;
      case "INSTAGRAM":
        return instagramIcon;
      case "TWITTER":
        return twitterIcon;
      case "YOUTUBE":
        return youtubeIcon;
      case "TIKTOK":
        return tiktokIcon;
      default:
        return null; // or a default icon if needed
    }
  };

  function formatDateStringLocal(inputDate) {
    if (inputDate) {
      const date = new Date(inputDate);
      const formattedDate = `${date.getDate().toString().padStart(2, "0")}.${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}.${date.getFullYear()}`;
      return formattedDate;
    } else {
      return "";
    }
  }

  const challengeColumns = [
    { title: t("control"), field: "control", className: "" },
    { title: t("id"), field: "id", className: "" },
    { title: t("challengeType"), field: "challengeType", className: "" },
    { title: t("challengeLogo"), field: "challengeLogo", className: "" },
    { title: t("challengeName"), field: "name", className: "" },
      // { title: t("segment"), field: "segment", className: "" },
    { title: t("coinPrize"), field: "coinPrize", className: "" },
    { title: t("startDate"), field: "startDate", className: "" },
    { title: t("endDate"), field: "endDate", className: "" },
    { title: t("successRate"), field: "successRate", className: "" },
  ];

  const handleViewSelect = (viewType) => {
    if (viewType === "LIST") {
      console.log("List view selected");
      setViewType("LIST");
      // Burada list görünümüne geçilecek işlemleri yapabilirsiniz.
    } else if (viewType === "GRID") {
      console.log("Grid view selected");
      setViewType("GRID");
      // Burada grid görünümüne geçilecek işlemleri yapabilirsiniz.
    }
  };

  const fetchSelectedChallenge = async (challengeId, challenge) => {
    const token = getCookie("access_token");
    if (!token) {
      toast.error(t("error.missingAccessToken"));
      if (getUserTypeFromToken(getCookie("access_token"))) {
        navigate("/sign-in-brand");
      } else {
        navigate("/sign-in-realm");
      }

      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error(t("error.sessionExpired"));
      if (getUserTypeFromToken(getCookie("access_token"))) {
        navigate("/sign-in-brand");
      } else {
        navigate("/sign-in-realm");
      }
      return;
    }

    let endpoint;
    if (getUserTypeFromToken(getCookie("access_token"))) {
      if (challenge.challengeType === "SURVEY") {
        endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/survey/${challengeId}/detail`;
      } else if (challenge.socialChallengeType === "INTERACTION") {
        endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/social/interaction/${challengeId}/detail`;
      } else if (challenge.socialChallengeType === "CONTENT_CREATION") {
        endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/social/content/${challengeId}/detail`;
      }
    } else {
      if (challenge.challengeType === "SURVEY") {
        endpoint = `${apiUrl}/api/v1/admin/challenge/survey/${challengeId}/detail`;
      } else if (challenge.socialChallengeType === "INTERACTION") {
        endpoint = `${apiUrl}/api/v1/admin/challenge/social/interaction/${challengeId}/detail`;
      } else if (challenge.socialChallengeType === "CONTENT_CREATION") {
        endpoint = `${apiUrl}/api/v1/admin/challenge/social/content/${challengeId}/detail`;
      }
    }

    try {
      setLoaderState(true);
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;

      if (data.responseData.socialChallengeType === "CONTENT_CREATION") {
        setLoaderState(false);
        navigate("/brand/challenges/content-creation", {
          state: { returnedChallenge: data },
        });
      } else if (data.responseData.socialChallengeType === "INTERACTION") {
        setLoaderState(false);
        navigate("/brand/challenges/interaction-creation", {
          state: { returnedChallenge: data },
        });
      } else if (data.responseData.challengeType === "SURVEY") {
        setLoaderState(false);
        navigate("/brand/challenges/survey-creation", {
          state: { returnedChallenge: data },
        });
      }
      // Do something with the data
    } catch (error) {
      setLoaderState(false);
      toast.error(error.message);
      console.error("Error fetching data:", error);
    }
  };

  const fetchSelectedChallengeToDetailPage = async (challengeId, challenge) => {
    setLoaderState(true);
    const token = getCookie("access_token");
    if (!token) {
      toast.error(t("error.missingAccessToken"));
      if (getUserTypeFromToken(getCookie("access_token"))) {
        setLoaderState(false);
        navigate("/sign-in-brand");
      } else {
        setLoaderState(false);
        navigate("/sign-in-realm");
      }

      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error(t("error.sessionExpired"));
      if (getUserTypeFromToken(getCookie("access_token"))) {
        setLoaderState(false);
        navigate("/sign-in-brand");
      } else {
        setLoaderState(false);
        navigate("/sign-in-realm");
      }
      return;
    }

    let endpoint;
    if (getUserTypeFromToken(getCookie("access_token"))) {
      if (challenge.challengeType === "SURVEY") {
        endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/survey/${challengeId}/detail`;
      } else if (challenge.socialChallengeType === "INTERACTION") {
        endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/social/interaction/${challengeId}/detail`;
      } else if (challenge.socialChallengeType === "CONTENT_CREATION") {
        endpoint = `${apiUrl}/api/v1/brand-admin/brand/challenge/social/content/${challengeId}/detail`;
      }
    } else {
      if (challenge.challengeType === "SURVEY") {
        endpoint = `${apiUrl}/api/v1/admin/challenge/survey/${challengeId}/detail`;
      } else if (challenge.socialChallengeType === "INTERACTION") {
        endpoint = `${apiUrl}/api/v1/admin/challenge/social/interaction/${challengeId}/detail`;
      } else if (challenge.socialChallengeType === "CONTENT_CREATION") {
        endpoint = `${apiUrl}/api/v1/admin/challenge/social/content/${challengeId}/detail`;
      }
    }

    if (getUserTypeFromToken(getCookie("access_token"))) {
      try {
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data;

        setLoaderState(false);
        navigate("/brand/challenges/challenge-detail", {
          state: { returnedChallenge: data.responseData },
        });
        // Do something with the data
      } catch (error) {
        setLoaderState(false);
        console.error("Error fetching data:", error);
      }
    } else {
      try {
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data;

        setLoaderState(false);
        navigate("/challenges/detail", {
          state: { returnedChallenge: data.responseData },
        });
        // Do something with the data
      } catch (error) {
        setLoaderState(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleSortClick = (itemId, itemText, itemString) => {
    console.log(itemString);
    setSortChallengesBy(itemString);
  };

  const handleFilterChange = (
    selectedContentTypes,
    selectedSocialMediaTypes
  ) => {
    if (selectedContentTypes === null || selectedSocialMediaTypes === null) {
      setFilterChallengeType([]);
      setFilterSocialChallengeType([]);
      setFilterSocialProvider([]);
      return;
    }

    if (selectedContentTypes !== null || selectedSocialMediaTypes !== null) {
      // Handle the case when selectedContentTypes is empty or contains null values
      selectedContentTypes.forEach((item) => {
        // Check if the item is not null
        if (item) {
          const { challengeType, socialChallengeType, title } = item;

          if (
            challengeType === "SOCIAL_MEDIA" &&
            !filterChallengeType.includes("SOCIAL_MEDIA")
          ) {
            setFilterChallengeType((prev) => [...prev, "SOCIAL_MEDIA"]);
          } else if (
            challengeType === "SURVEY" &&
            !filterChallengeType.includes("SURVEY")
          ) {
            setFilterChallengeType((prev) => [...prev, "SURVEY"]);
          }

          if (
            socialChallengeType === "INTERACTION" &&
            !filterSocialChallengeType.includes("INTERACTION")
          ) {
            setFilterSocialChallengeType((prev) => [...prev, "INTERACTION"]);
          } else if (
            socialChallengeType === "CONTENT_CREATION" &&
            !filterSocialChallengeType.includes("CONTENT_CREATION")
          ) {
            setFilterSocialChallengeType((prev) => [
              ...prev,
              "CONTENT_CREATION",
            ]);
          }
        }
      });
    }

    const isInstagramSelected = selectedSocialMediaTypes.some(
      (obj) => obj.string === "INSTAGRAM"
    );
    const isFacebookSelected = selectedSocialMediaTypes.some(
      (obj) => obj.string === "FACEBOOK"
    );
    const isTwitterSelected = selectedSocialMediaTypes.some(
      (obj) => obj.string === "TWITTER"
    );
    const isTiktokSelected = selectedSocialMediaTypes.some(
      (obj) => obj.string === "TIKTOK"
    );
    const isYoutubeSelected = selectedSocialMediaTypes.some(
      (obj) => obj.string === "YOUTUBE"
    );

    const isSurveySelected = selectedContentTypes.some(
      (obj) => obj.challengeType === "SURVEY"
    );
    const isInteractionSelected = selectedContentTypes.some(
      (obj) => obj.socialChallengeType === "INTERACTION"
    );
    const isContentCreationSelected = selectedContentTypes.some(
      (obj) => obj.socialChallengeType === "CONTENT_CREATION"
    );
    const isSocialMediaSelected = selectedContentTypes.some(
      (obj) => obj.challengeType === "SOCIAL_MEDIA"
    );

    if (!filterSocialProvider.includes("INSTAGRAM") && isInstagramSelected) {
      setFilterSocialProvider((prev) => [...prev, "INSTAGRAM"]);
    }
    if (!filterSocialProvider.includes("FACEBOOK") && isFacebookSelected) {
      setFilterSocialProvider((prev) => [...prev, "FACEBOOK"]);
    }
    if (!filterSocialProvider.includes("TWITTER") && isTwitterSelected) {
      setFilterSocialProvider((prev) => [...prev, "TWITTER"]);
    }
    if (!filterSocialProvider.includes("TIKTOK") && isTiktokSelected) {
      setFilterSocialProvider((prev) => [...prev, "TIKTOK"]);
    }
    if (!filterSocialProvider.includes("YOUTUBE") && isYoutubeSelected) {
      setFilterSocialProvider((prev) => [...prev, "YOUTUBE"]);
    }

    if (!isInstagramSelected) {
      setFilterSocialProvider((prev) =>
        prev.filter((type) => type !== "INSTAGRAM")
      );
    }
    if (!isFacebookSelected) {
      setFilterSocialProvider((prev) =>
        prev.filter((type) => type !== "FACEBOOK")
      );
    }
    if (!isTwitterSelected) {
      setFilterSocialProvider((prev) =>
        prev.filter((type) => type !== "TWITTER")
      );
    }
    if (!isTiktokSelected) {
      setFilterSocialProvider((prev) =>
        prev.filter((type) => type !== "TIKTOK")
      );
    }
    if (!isYoutubeSelected) {
      setFilterSocialProvider((prev) =>
        prev.filter((type) => type !== "YOUTUBE")
      );
    }

    if (!isSurveySelected) {
      setFilterChallengeType((prev) =>
        prev.filter((type) => type !== "SURVEY")
      );
    }
    if (!isInteractionSelected) {
      setFilterSocialChallengeType((prev) =>
        prev.filter((type) => type !== "INTERACTION")
      );
    }
    if (!isContentCreationSelected) {
      setFilterSocialChallengeType((prev) =>
        prev.filter((type) => type !== "CONTENT_CREATION")
      );
    }
    if (!isSocialMediaSelected) {
      setFilterChallengeType((prev) =>
        prev.filter((type) => type !== "SOCIAL_MEDIA")
      );
    }
  };

  useEffect(() => {
    if (
      filterSocialChallengeType.includes("INTERACTION") &&
      filterSocialChallengeType.includes("CONTENT_CREATION")
    ) {
      setTempSocialChallengeType();
      setTempChallengeType("SOCIAL_MEDIA");
    } else if (filterSocialChallengeType.includes("INTERACTION")) {
      setTempChallengeType("SOCIAL_MEDIA");
      setTempSocialChallengeType("INTERACTION");
    } else if (filterSocialChallengeType.includes("CONTENT_CREATION")) {
      setTempChallengeType("SOCIAL_MEDIA");
      setTempSocialChallengeType("CONTENT_CREATION");
    }

    if (
      filterChallengeType.includes("SURVEY") &&
      filterChallengeType.includes("SOCIAL_MEDIA")
    ) {
      if (
        tempChallengeType === "SURVEY" &&
        filterChallengeType.includes("SOCIAL_MEDIA")
      ) {
        setTempChallengeType("SOCIAL_MEDIA");
      } else if (
        tempChallengeType === "SOCIAL_MEDIA" &&
        filterChallengeType.includes("SURVEY")
      ) {
        setTempChallengeType("SURVEY");
        setTempSocialChallengeType();
        setTempSocialProvider();
      } else if (filterChallengeType.includes("SOCIAL_MEDIA")) {
        setTempChallengeType("SOCIAL_MEDIA");
      } else if (filterChallengeType.includes("SURVEY")) {
        setTempChallengeType("SURVEY");
        setTempSocialChallengeType();
        setTempSocialProvider();
      }
    } else if (filterChallengeType.includes("SURVEY")) {
      setTempChallengeType("SURVEY");
      setTempSocialChallengeType();
    } else if (filterChallengeType.includes("SOCIAL_MEDIA")) {
      setTempChallengeType("SOCIAL_MEDIA");

      if (
        filterSocialChallengeType.includes("INTERACTION") &&
        filterSocialChallengeType.includes("CONTENT_CREATION")
      ) {
        setTempSocialChallengeType();
      } else if (filterSocialChallengeType.includes("INTERACTION")) {
        setTempSocialChallengeType("INTERACTION");
      } else if (filterSocialChallengeType.includes("CONTENT_CREATION")) {
        setTempSocialChallengeType("CONTENT_CREATION");
      }
    } else if (
      !(
        filterChallengeType.includes("SOCIAL_MEDIA") &&
        filterChallengeType.includes("SURVEY") &&
        filterSocialChallengeType.includes("INTERACTION") &&
        filterSocialChallengeType.includes("CONTENT_CREATION")
      )
    ) {
      setTempChallengeType();
      setTempSocialProvider();
      setTempSocialChallengeType();
    }

    //SOCIAL PROVIDERS

    if (filterChallengeType.includes("SOCIAL_MEDIA")) {
      if (
        filterSocialProvider.includes("INSTAGRAM") &&
        filterSocialProvider.includes("FACEBOOK")
      ) {
        setTempSocialProvider();
      } else if (filterSocialProvider.includes("FACEBOOK")) {
        setTempSocialProvider("FACEBOOK");
      } else if (filterSocialProvider.includes("INSTAGRAM")) {
        setTempSocialProvider("INSTAGRAM");
      } else if (
        !(
          filterSocialProvider.includes("INSTAGRAM") &&
          filterSocialProvider.includes("FACEBOOK")
        )
      ) {
        setTempSocialProvider();
      }
    } else {
      setTempSocialProvider();
    }
  }, [filterChallengeType, filterSocialChallengeType, filterSocialProvider]);

  useEffect(() => {
    console.log("============================================================");
    console.log("temp challenge type su anda = ", tempChallengeType);
    console.log(
      "temp social challenge type su anda = ",
      tempSocialChallengeType
    );
    console.log("temp social provider = ", tempSocialProvider);
    console.log("sort by =", sortChallengesBy);
    console.log("start date =", filterStartDate);
    console.log("end date =", filterEndDate);

    if (clickedPage === prevClickedPage) {
      setClickedPage("0");
    }

    //ENDPOINTI GUNCELLE
    let endpoint2 = `/api/v1/analytics/challenge?`;

    if (brandId) {
      endpoint2 += `brandId=${brandId}&`;
    }

    endpoint2 += `status=${activeTabKey}&`;

    if (filterStartDate && filterEndDate) {
      endpoint2 += `from=${filterStartDate}&to=${filterEndDate}&`;
    }

    if (tempChallengeType) {
      endpoint2 += `type=${tempChallengeType}&`;
    }
    console.log(tempSocialProvider);

    if (tempSocialProvider) {
      endpoint2 += `provider=${tempSocialProvider}&`;
    }

    if (sortChallengesBy) {
      endpoint2 += `challengeSortBy=${sortChallengesBy}&`;
    }

    if (tempSocialChallengeType) {
      endpoint2 += `socialChallengeType=${tempSocialChallengeType}&`;
    }

    endpoint2 += `page=${clickedPage}&size=${itemPerPage}`;

    setEndpoint(endpoint2);
    console.log(endpoint2);
    setPrevClickedPage(clickedPage);
  }, [
    tempSocialProvider,
    activeTabKey,
    clickedPage,
    itemPerPage,
    tempSocialChallengeType,
    tempChallengeType,
    filterEndDate,
    sortChallengesBy,
  ]);

  useEffect(() => {
    fetchChallenges();
  }, [endpoint]);

  const handleFilterDatesChanged = ({ selectedStartDate, selectedEndDate }) => {
    if (selectedEndDate) {
      console.log(selectedStartDate);
      console.log(selectedEndDate);

      const originalStartDate = new Date(selectedStartDate);
      const originalEndDate = new Date(selectedEndDate);

      originalStartDate.setHours(0, 0, 0);
      originalEndDate.setHours(23, 59, 59);

      const formattedStartDate = `${originalStartDate.getFullYear()}-${(
        originalStartDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${originalStartDate
        .getDate()
        .toString()
        .padStart(2, "0")}T${originalStartDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${originalStartDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${originalStartDate
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      const formattedEndDate = `${originalEndDate.getFullYear()}-${(
        originalEndDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${originalEndDate
        .getDate()
        .toString()
        .padStart(2, "0")}T${originalEndDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${originalEndDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${originalEndDate
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;

      console.log(formattedStartDate);
      setFilterStartDate(formattedStartDate);
      setFilterEndDate(formattedEndDate);
    } else {
      setFilterStartDate();
      setFilterEndDate();
    }
  };

  const locationArray = [[t("challenges"), "/brand/challenges"]];

  return (
    <div className="dashboard-container">
      {loaderState && <Loader />}
      <NavigationBar locationArray={locationArray} dividerDisabled={true} />
      <div className="dashboard-top-divider" />
      <div className="dashboard-controls-container">
        <FilterButton
          checkboxData={checkboxData}
          checkboxSocialProviderData={checkboxSocialProviderData}
          onCheckboxSelection={handleFilterChange}
        />
        <SortByButton items={sortItems} onItemSelect={handleSortClick} />
        <DatePickerButton
          highlightedDates={challengeStartDatesWithProvider}
          onDateChange={handleFilterDatesChanged}
        />
      </div>

      <div className="dashboard-header">
        <div className="dashboard-header-cards-container">
          {dataSets &&
            dataSets.map((data, index) => <TotalCards key={index} {...data} />)}
        </div>
        <CreateChallengeButton />
        
      </div>

      <div className="dashboard-divider" />
      <div className="dashboard-challenges-container">
        <div className="dashboard-challenges-tab-container">
          {tabData.map((tab) => (
            <TabButton
              key={tab.id}
              id={tab.id}
              title={tab.title}
              active={activeTab === tab.id}
              onClick={() => handleTabClick(tab)}
            />
          ))}
        </div>
        <div className="dashboard-divider" />
        <div className="dashboard-challenges-wrapper">
          <div className="dashboard-challenges-controls-container">
            <GridListSwitchButton onSelectView={handleViewSelect} />
            <div className="dashboard-challenges-search-container">
              <img
                className="dashboard-challenges-search-icon"
                src={SearchIcon}
                alt="search"
              />
              <input
                className="dashboard-challenges-search"
                type="text"
                placeholder="search"
              />
            </div>
            <ItemsPerPageButton onSelectItem={handleItemsPerPageChange} />
          </div>
        </div>
        {pageMetaData && (
          <Pagination
            currentPage={currentPage || null}
            totalPages={pageMetaData.totalPage}
            onPageChange={(page) => {
              handlePageChange(page - 1);
            }}
          />
        )}
        {viewType === "GRID" && (
          <div className="dashboard-challenges-grid-container">
            {challenges &&
              challenges.map((data, index) => {
                return <ChallengeCard key={index} challengeData={data} />;
              })}
          </div>
        )}
        {viewType === "LIST" && (
          <div>
            <DynamicTable
              columns={challengeColumns}
              data={challengesForList}
              tableName={t("challengeTable")}
            />
          </div>
        )}

        {pageMetaData && (
          <Pagination
            currentPage={currentPage || null}
            totalPages={pageMetaData.totalPage}
            onPageChange={(page) => {
              handlePageChange(page - 1);
            }}
          />
        )}
      </div>
    </div>
  );
};
export default ChallengeDashboard;
