import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import arrow from "../../../../../assets/media/arrow.png";
import downArrow from "../../../../../assets/media/down-arrow.png";
import "../../../../../assets/css/ChallengeDetail.css";
import email from "../../../../../assets/media/Icon material-email.svg";
import refresh from "../../../../../assets/media/Icon material-refresh.svg";
import copy from "../../../../../assets/media/Icon material-content-copy.svg";
import info from "../../../../../assets/media/infoIconEmpty.svg";
import qr from "../../../../../assets/media/qr.png";
import timeIcon from "../../../../../assets/media/time.svg";
import tickIcon from "../../../../../assets/media/tick.svg";
import facebookIcon from "../../../../../assets/media/facebook.svg";
import instagramIcon from "../../../../../assets/media/instagram.svg";
import twitterIcon from "../../../../../assets/media/x-twitter.svg";
import youtubeIcon from "../../../../../assets/media/youtube.svg";
import tiktokIcon from "../../../../../assets/media/tiktok.svg";
import ChallengeDetailTotalCard from "./challenge_detail_cards/challengeDetailTotalCards";
import ViewInSotyAppButton from "../../../../button/viewInSotyAppButton";
import EndChallengeButton from "../../../../button/endChallengeButton";
import ChallengeAnalysisButton from "../../../../button/challengeAnalysisButton";
import { getCookie } from "../../../auth/tokenUtils";
import { apiUrl, imagesUrl } from "../../../../../config";
import { isMyTokenExpired } from "../../../auth/tokenUtils";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../../popup/loader";
import NavigationBar from "../../../../utils/navigation_bar/navigationBar";

const ChallengeDetail = () => {
  const { t, i18n } = useTranslation("challengeCreation");
  const location = useLocation();
  const navigate = useNavigate();
  const [theChallenge, setTheChallenge] = useState();
  const [theChallengeAnalytics, setTheChallengeAnalytics] = useState();

  const [dayDifference, setDayDifference] = useState(0);
  const [hourDifference, setHourDifference] = useState(0);
  const [minuteDifference, setMinuteDifference] = useState(0);
  const [secondDifference, setSecondDifference] = useState(0);

  const [isDayAnimating, setIsDayAnimating] = useState(false);
  const [isMinuteAnimating, setIsMinuteAnimating] = useState(false);
  const [isHourAnimating, setIsHourAnimating] = useState(false);
  const [isSecondAnimating, setIsSecondAnimating] = useState(false);
  const [challengeTypeTitleToShow, setChallengeTypeTitleToShow] = useState();
  const [challengeTypeToShow, setChallengeTypeToShow] = useState();
  let returnedChallenge = location.state?.returnedChallenge;
  const [dataSets, setDataSets] = useState();
  const [loaderState, setLoaderState] = useState(false);

  useEffect(() => {
    if (returnedChallenge) fetchData();
  }, []);
  const fetchData = async () => {
    setLoaderState(true);

    const token = getCookie("access_token");

    if (!token) {
      console.error("Access token is missing.");
      setLoaderState(false);

      toast.error(t("error.missingAccessToken"));
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error(t("error.sessionExpired"));
      setLoaderState(false);

      navigate("/sign-in-brand");
    }

    let endPoint;
    if (returnedChallenge.challengeType === "SURVEY") {
      endPoint =
        apiUrl +
        `/api/v1/brand-admin/brand/challenge/survey/${returnedChallenge.id}/detail`;
    } else if (returnedChallenge.challengeType === "SOCIAL_MEDIA") {
      if (returnedChallenge.socialChallengeType === "INTERACTION") {
        endPoint =
          apiUrl +
          `/api/v1/brand-admin/brand/challenge/social/interaction/${returnedChallenge.id}/detail`;
      } else if (returnedChallenge.socialChallengeType === "CONTENT_CREATION") {
        endPoint =
          apiUrl +
          `/api/v1/brand-admin/brand/challenge/social/content/${returnedChallenge.id}/detail`;
      }
    }

    const analyticsEndpoint =
      apiUrl + `/api/v1/analytics/challenge/${returnedChallenge.id}`;
    try {
      const response = await axios.get(endPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // İsteğin content type'ını belirtmek iyi bir uygulama pratigidir
        },
        // Diğer istek yapılandırmalarını buraya ekleyebilirsiniz
      });

      const responseAnalytics = await axios.get(analyticsEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // İsteğin content type'ını belirtmek iyi bir uygulama pratigidir
        },
        // Diğer istek yapılandırmalarını buraya ekleyebilirsiniz
      });

      // response.data ile gelen verilere erişebilirsiniz
      console.log("ANALYTICS = ", responseAnalytics);
      console.log(response.data.responseData);
      setTheChallenge(response.data.responseData);
      setTheChallengeAnalytics(responseAnalytics.data.responseData);
      challengeTypeToShowMethod(response.data.responseData);
      setLoaderState(false);
    } catch (error) {
      // Hata durumunda hata mesajını yakalayabilirsiniz
      setLoaderState(false);
      toast.error(
        t("error.fetchingData") +
          "(" +
          error.response.data.metadata.message +
          ")"
      );
    }
  };

  const mapSurveyTypeToTurkish = (surveyType) => {
    const typeMap = {
      SINGLE_CHOICE: t("multipleChoiceSingleAnswer"),
      MULTIPLE_CHOICE: t("multipleChoiceMultipleAnswer"),
      IMAGE: t("imageResponses"),
      SCORE: t("linearNumericScale"),
      LIKERT_FREQUENCY: t("likertScale") + " (" + t("frequency") + ")",
      LIKERT_AGREEMENT: t("likertScale") + " (" + t("agreement") + ")",
      LIKERT_IMPORTANCE: t("likertScale") + " (" + t("importance") + ")",
      LIKERT_INTEREST: t("likertScale") + " (" + t("interest") + ")",
    };

    return typeMap[surveyType] || surveyType; // If not found, return the original value
  };

  useEffect(() => {
    setDataSets([
      {
        headerTitle: t("total"),
        title: t("sharedSotyCoin"),
        subtitle: t("SSC"),
        data:
          theChallengeAnalytics && theChallengeAnalytics.totalEarnedCoins
            ? theChallengeAnalytics.totalEarnedCoins
            : null,
        subData:
          (theChallengeAnalytics?.totalEarnedCoins / 10 || 0 / 10) + t("TL"),
      },
      {
        headerTitle: t("total"),
        title: t("challengeViewCount"),
        subtitle: t("CVC"),
        data:
          theChallengeAnalytics && theChallengeAnalytics.totalSeen
            ? theChallengeAnalytics.totalSeen
            : null,
      },
      {
        headerTitle: t("total"),
        title: t("challengeJoinCount"),
        subtitle: t("CJC"),
        data:
          theChallengeAnalytics && theChallengeAnalytics.totalJoin
            ? theChallengeAnalytics.totalJoin
            : null,
      },
      {
        headerTitle: t("total"),
        title: t("challengeCompletionCount"),
        subtitle: t("CCC"),
        data:
          theChallengeAnalytics && theChallengeAnalytics.totalCompleted
            ? theChallengeAnalytics.totalCompleted
            : null,
      },
      {
        headerTitle: t("total"),
        title: t("challengeFailCount"),
        subtitle: t("CFC"),
        data:
          theChallengeAnalytics && theChallengeAnalytics.totalFailed
            ? theChallengeAnalytics.totalFailed
            : null,
      },
      {
        headerTitle: t("total"),
        title: t("taskSuccessRate"),
        subtitle: t("CSR"),
        data:
          theChallengeAnalytics && theChallengeAnalytics.successRatio
            ? "%" + (theChallengeAnalytics.successRatio * 100).toFixed(2)
            : null,
      },
    ]);
  }, [theChallengeAnalytics]);

  const formatDate = (dateString) => {
    // UTC tarihini al
    const utcDate = new Date(dateString);

    // UTC tarihine manuel olarak 3 saat ekle
    utcDate.setHours(utcDate.getHours() + 3);

    // Tarih ve saat bilgilerini istediğiniz formatta al
    const formattedDate = utcDate.toLocaleDateString("tr-TR");
    const formattedTime = utcDate.toLocaleTimeString("tr-TR");

    return {
      date: formattedDate,
      time: formattedTime,
    };
  };

  const challengeTypeToShowMethod = (challengeFromEndpoint) => {
    if (challengeFromEndpoint.challengeType === "SURVEY") {
      setChallengeTypeToShow(t("survey"));
    } else if (challengeFromEndpoint.challengeType === "SOCIAL_MEDIA") {
      setChallengeTypeTitleToShow(t("socialMedia"));
      if (challengeFromEndpoint.socialChallengeType === "INTERACTION") {
        setChallengeTypeToShow(t("interaction"));
      } else if (
        challengeFromEndpoint.socialChallengeType === "CONTENT_CREATION"
      ) {
        setChallengeTypeToShow(t("contentCreation"));
      } else {
        setChallengeTypeToShow();
      }
    } else {
      setChallengeTypeToShow(t("otherChallengeTypes"));
    }
  };

  const getSocialIcon = (provider) => {
    switch (provider) {
      case "FACEBOOK":
        return facebookIcon;
      case "INSTAGRAM":
        return instagramIcon;
      case "TWITTER":
        return twitterIcon;
      case "YOUTUBE":
        return youtubeIcon;
      case "TIKTOK":
        return tiktokIcon;
      default:
        return null; // or a default icon if needed
    }
  };

  const formatSocialProvider = (provider) => {
    if (provider) {
      let message = provider.toLowerCase();
      return message.charAt(0).toUpperCase() + message.slice(1);
    } else {
      return ""; // veya başka bir varsayılan değer
    }
  };

  const calculateDateDifference = (utcDate) => {
    const now = new Date(); // Şu anki tarih ve saat
    const targetDate = new Date(utcDate); // Hedef tarih

    // İki tarih arasındaki milisaniye farkı
    const timeDifference = targetDate - now;

    // Farkı gün, saat, dakika ve saniye olarak dönüştür
    const seconds = Math.floor(timeDifference / 1000) % 60;
    const minutes = Math.floor(timeDifference / (1000 * 60)) % 60;
    const hours = Math.floor(timeDifference / (1000 * 60 * 60)) % 24;
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    if (days < 0 && minutes < 0 && seconds < 0 && hours < 0) {
      return;
    } else {
      setDayDifference(days);
      setHourDifference(hours);
      setMinuteDifference(minutes);
      setSecondDifference(seconds);
    }
  };

  const updateDateDifference = (utcDate) => {
    const dateDifference = calculateDateDifference(utcDate);
  };

  useEffect(() => {
    if (theChallenge) {
      setInterval(() => {
        updateDateDifference(theChallenge.endDate);
      }, 1000);
    }
  }, [theChallenge]);

  useEffect(() => {
    setIsSecondAnimating(true);

    // Toggle the animation every half a second
    const intervalId = setInterval(() => {
      setIsSecondAnimating((prevIsAnimating) => !prevIsAnimating);
    }, 900);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
      setIsSecondAnimating(false);
    };
  }, [secondDifference]);

  const handleCopyClick = async () => {
    const qrImage = document.querySelector(".challenge-qr-wrapper img");

    try {
      // Fetch the image as a blob
      const response = await fetch(qrImage.src);
      const blob = await response.blob();

      // Create a new clipboard data item with the blob
      const clipboardItem = new ClipboardItem({ "image/png": blob });

      // Copy the clipboard item to the clipboard
      await navigator.clipboard.write([clipboardItem]);

      // Optionally, you can show a success message or perform other actions
      console.log("QR code image copied to clipboard");
      toast.error(t("success.copyQRCode"));
    } catch (error) {
      // Handle the error, e.g., display an error message
      console.error("Unable to copy QR code image to clipboard", error);
      toast.error(t("error.copyQRCode") + "(" + error.message + ")");
    }
  };

  const locationArray = [
    [t("challenges"), "/brand/challenges"],
    [t("challengeDetails"), "/brand/challenges/challenge-detail"],
  ];

  return (
    <div className="dashboard-container">
      {loaderState && <Loader />}
      <NavigationBar locationArray={locationArray} dividerDisabled={true} />

      <div className="challenge-detail-id-container">
        {theChallenge && (
          <div className="challenge-detail-id">
            {t("challengeID")}: {theChallenge.id}
          </div>
        )}
      </div>
      <div className="challenge-detail-cards-container">
        {dataSets &&
          dataSets.map((data, index) => (
            <ChallengeDetailTotalCard key={index} {...data} />
          ))}
      </div>

      <div className="challenge-detail-buttons-container">
        <EndChallengeButton />
        <ChallengeAnalysisButton />
      </div>
      {theChallenge && (
        <div className="challenge-detail-details-container">
          <div className="challenge-data-container">
            <div className="challenge-data-container-title">
              {t("challengeInfo")}:
            </div>
            <div className="challenge-data-wrapper">
              <div className="challenge-data-wrapper-row">
                <div className="challenge-data">
                  <div className="challenge-data-title">
                    {t("challengeName")}:
                  </div>
                  <div className="challenge-data-input">
                    {theChallenge.name}{" "}
                    <img className="challenge-data-input-tick" src={tickIcon} />{" "}
                  </div>
                </div>
                <div className="challenge-data">
                  <div className="challenge-data-title">
                    {t("challengeDescription")}:
                  </div>
                  <div className="challenge-data-input">
                    {theChallenge.description}{" "}
                    <img className="challenge-data-input-tick" src={tickIcon} />{" "}
                  </div>
                </div>
              </div>
              <div className="challenge-data-wrapper-row">
                <div className="challenge-data">
                  <div className="challenge-data-title">
                    {t("challengeType")}
                  </div>
                  <div className="challenge-data-input">
                    {challengeTypeToShow}{" "}
                    <img className="challenge-data-input-tick" src={tickIcon} />{" "}
                  </div>
                </div>
                {theChallenge.socialProvider && (
                  <div className="challenge-data">
                    <div className="challenge-data-title">
                      {t("socialMediaChannel")}
                    </div>
                    <div className="challenge-data-input">
                      <div className="challenge-data-input-social-proivder-left">
                        {theChallenge.socialProvider && (
                          <img
                            src={getSocialIcon(theChallenge.socialProvider)}
                            alt="img"
                          />
                        )}{" "}
                        {theChallenge.socialProvider}
                      </div>{" "}
                      <img
                        className="challenge-data-input-tick"
                        src={tickIcon}
                      />{" "}
                    </div>
                  </div>
                )}
                {theChallenge.surveyType && (
                  <div className="challenge-data">
                    <div className="challenge-data-title">
                      {t("surveyType")}
                    </div>
                    <div className="challenge-data-input">
                      {mapSurveyTypeToTurkish(theChallenge.surveyType)}{" "}
                      <img
                        className="challenge-data-input-tick"
                        src={tickIcon}
                      />{" "}
                    </div>
                  </div>
                )}
              </div>
              <div className="challenge-data-wrapper-row">
                {theChallenge.socialChallengeType &&
                  theChallenge.socialChallengeType === "CONTENT_CREATION" && (
                    <div className="challenge-data">
                      <div className="challenge-data-title">
                        {t("contentType")}
                      </div>
                      <div className="challenge-data-input">
                        {theChallenge.socialProviderContentCreationType &&
                          theChallenge.socialProviderContentCreationType
                            .contentCreationType}{" "}
                        <img
                          className="challenge-data-input-tick"
                          src={tickIcon}
                        />{" "}
                      </div>
                    </div>
                  )}
                {theChallenge.socialChallengeType &&
                  theChallenge.socialChallengeType === "INTERACTION" && (
                    <div className="challenge-data">
                      <div className="challenge-data-title">{t("url")}</div>
                      <a
                        className="challenge-data-input"
                        href={theChallenge.target}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {theChallenge.target && theChallenge.target}{" "}
                        <img
                          className="challenge-data-input-tick"
                          src={tickIcon}
                        />{" "}
                      </a>
                    </div>
                  )}
                {theChallenge.segment && (
                  <div className="challenge-data">
                    <div className="challenge-data-title">{t("segment")}:</div>
                    <div className="challenge-data-input">
                      {theChallenge.segment}{" "}
                      <img
                        className="challenge-data-input-tick"
                        src={tickIcon}
                      />{" "}
                    </div>
                  </div>
                )}

                <div className="challenge-data">
                  <div className="challenge-data-title">{t("sotyCoin")}</div>
                  <div className="challenge-data-input">
                    {theChallenge.prize +
                      " " +
                      t("sotyCoin") +
                      " " +
                      t("equalsTo") +
                      " " +
                      theChallenge.prize / 10 +
                      " TL"}
                    <img className="challenge-data-input-tick" src={tickIcon} />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {theChallenge && theChallenge.challengeType === "SURVEY" && (
            <div className="challenge-data-container">
              <div className="challenge-data-container-title">
                {t("surveyQuestions")}:
              </div>
              <div className="challenge-data-wrapper">
                {theChallenge.questions &&
                  theChallenge.questions.map((data, index) => (
                    <div className="challenge-data-wrapper-row" key={index}>
                      <div className="challenge-data">
                        <div className="challenge-data-title">
                          {data.order + 1} - {data.text}
                        </div>
                        {data.surveyChoices &&
                          data.surveyChoices.map((answer, index) => (
                            <div className="challenge-data-input" key={index}>
                              {index + 1} - {answer.text}{" "}
                              <img
                                className="challenge-data-input-tick"
                                src={tickIcon}
                              />
                            </div>
                          ))}
                      </div>
                      {data.info && (
                        <div className="challenge-data">
                          <div className="challenge-data-title">
                            {t("description")}:
                          </div>

                          <div className="challenge-data-input">
                            {data.info}{" "}
                            <img
                              className="challenge-data-input-tick"
                              src={tickIcon}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          )}
          {theChallenge &&
            theChallenge.socialChallengeType === "INTERACTION" && (
              <div className="challenge-data-container">
                <div className="challenge-data-container-title">
                  {t("contentTasks")}:
                </div>
                <div className="challenge-data-wrapper">
                  {theChallenge.interactionChallengeRules &&
                    theChallenge.interactionChallengeRules.map(
                      (data, index) => (
                        <div className="challenge-data-wrapper-row" key={index}>
                          <div className="challenge-data">
                            <div className="challenge-data-title">
                              {formatSocialProvider(
                                data.socialProviderCommand
                                  .interactionChallengeAction
                              )}
                              :
                            </div>
                            <div className="challenge-data-input">
                              {data.instruction}{" "}
                              <img
                                className="challenge-data-input-tick"
                                src={tickIcon}
                              />{" "}
                            </div>
                          </div>
                          {data.info && (
                            <div className="challenge-data">
                              <div className="challenge-data-title">
                                {t("description")}:
                              </div>

                              <div className="challenge-data-input">
                                {data.info}{" "}
                                <img
                                  className="challenge-data-input-tick"
                                  src={tickIcon}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    )}
                </div>
              </div>
            )}
          {theChallenge &&
            theChallenge.socialChallengeType === "CONTENT_CREATION" && (
              <div className="challenge-data-container">
                <div className="challenge-data-container-title">
                  {t("contentTasks")}:
                </div>
                <div className="challenge-data-wrapper">
                  {theChallenge.contentCreationActionResponseList &&
                    theChallenge.contentCreationActionResponseList.map(
                      (data, index) => (
                        <div className="challenge-data-wrapper-row" key={index}>
                          <div className="challenge-data">
                            <div className="challenge-data-title">
                              {data.type}:
                            </div>
                            <div className="challenge-data-input">
                              {data.value}{" "}
                              <img
                                className="challenge-data-input-tick"
                                src={tickIcon}
                              />{" "}
                            </div>
                          </div>
                          {data.infoValue && (
                            <div className="challenge-data">
                              <div className="challenge-data-title">
                                {t("description")}:
                              </div>

                              <div className="challenge-data-input">
                                {data.infoValue}{" "}
                                <img
                                  className="challenge-data-input-tick"
                                  src={tickIcon}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    )}
                </div>
              </div>
            )}
          <div className="challenge-data-container-row">
            <div className="challenge-data-container">
              <div className="challenge-data-container">
                <div className="challenge-data-container-title">
                  {t("challengeDate")}:
                </div>
                <div className="challenge-data-wrapper">
                  <div className="challenge-data-wrapper-row">
                    <div className="challenge-data">
                      <div className="challenge-data-title">
                        {t("startDate")}:
                      </div>
                      <div className="challenge-data-input">
                        {formatDate(theChallenge.startDate).date}{" "}
                        {formatDate(theChallenge.startDate).time}{" "}
                        <img
                          className="challenge-data-input-tick"
                          src={tickIcon}
                        />{" "}
                      </div>
                    </div>
                    <div className="challenge-data">
                      <div className="challenge-data-title">
                        {t("endDate")}:
                      </div>
                      <div className="challenge-data-input">
                        {formatDate(theChallenge.endDate).date}{" "}
                        {formatDate(theChallenge.endDate).time}{" "}
                        <img
                          className="challenge-data-input-tick"
                          src={tickIcon}
                        />{" "}
                      </div>
                    </div>
                  </div>
                  <div className="challenge-date-counter-container">
                    <img src={timeIcon} alt="time" />
                    <div className="challenge-date-counter-wrapper">
                      <div className={"challenge-date-counter-data "}>
                        {dayDifference >= 0 ? dayDifference : 0}
                        <div className="challenge-date-counter-title">
                          {t("days")}
                        </div>
                      </div>
                      <div className={"challenge-date-counter-data "}>
                        {hourDifference >= 0 ? hourDifference : 0}
                        <div className="challenge-date-counter-title">
                          {t("hours")}
                        </div>
                      </div>
                      <div className={"challenge-date-counter-data "}>
                        {minuteDifference >= 0 ? minuteDifference : 0}
                        <div className="challenge-date-counter-title">
                          {t("minutes")}
                        </div>
                      </div>
                      <div className={"challenge-date-counter-data "}>
                        {secondDifference >= 0 ? secondDifference : 0}
                        <div className="challenge-date-counter-title">
                          {t("seconds")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="challenge-data-container challenge-data-container-row-child">
              <div className="challenge-data-container-title">{t("qr")}:</div>
              <div className="challenge-data-wrapper">
                <div className="challenge-data-wrapper-row">
                  <div className="challenge-data challenge-qr-container">
                    <img className="challenge-qr-copy hidden" src={copy} />
                    <div className="challenge-qr-wrapper">
                      <img src={qr} alt="img" />
                    </div>
                    <img
                      className="challenge-qr-copy"
                      src={copy}
                      alt="Copy"
                      onClick={() => {
                        handleCopyClick();
                      }}
                    />
                  </div>
                </div>

                <div className="challenge-qr-info">
                  <img src={info} alt="info" />
                  {t("refreshButtonMessage")}
                </div>

                <div className="challenge-qr-button-container">
                  <div className="challenge-qr-button">
                    <img src={email} alt="email" />
                    {t("sendEmail")}
                    <img className="hidden" src={email} alt="email" />
                  </div>
                  <div className="challenge-qr-button">
                    <img src={refresh} alt="" />
                    {t("refresh")}
                    <img className="hidden" src={refresh} alt="refresh" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ChallengeDetail;
